import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  btns: {
    margin: theme.spacing(0, 1)
  },
  bold: {
    fontWeight: 'bold'
  },
  backdrop: {
    zIndex: 10
  },
  content: {
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    width: '100%',
    bottom: 0,
    position: 'absolute',
    borderRadius: '5px 5px 0 0',
    maxWidth: 1200
  },
  link: {
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  },
  light: {
    fontWeight: 'lighter'
  }
}));
