import { makeStyles, Theme } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import teal from '@material-ui/core/colors/teal';

export const useStyles = makeStyles((theme: Theme) => ({
  uppercase: {
    textTransform: 'uppercase'
  },
  bold: {
    fontWeight: 500
  },
  btnsContainer: {
    '& button': {
      margin: theme.spacing(0, 1)
    }
  },
  successBtn: {
    background: 'linear-gradient(270deg, ' + green.A400 + ' , ' + teal[700] + ' )',
    color: 'white'
  },

  successIcon: {
    fontSize: 70,
    color: teal[300]
  }
}));
