import React from 'react';
import { Box, Button, Typography, Backdrop } from '@material-ui/core';
import { useStyles } from './style';
import { useStore } from 'store';

interface IProps {
  note: string;
}

const UpdatedTerms = () => {
  const classes = useStyles();

  const {
    state: {
      ui: { isUpdatedTermsPopOpen }
    },
    dispatch
  } = useStore();

  const onAccepted = () => {
    dispatch({
      type: 'UI_ACTION_UPDATED_TERMS_POPUP',
      payload: false
    });
  };

  const onPrivacyClick = () => {
    dispatch({
      type: 'UI_ACTION_UPDATED_TERMS_POPUP',
      payload: false
    });

    dispatch({
      type: 'UI_ACTION_PRIVACY_POPUP',
      payload: true
    });
  };

  const onAgreementClick = () => {
    dispatch({
      type: 'UI_ACTION_UPDATED_TERMS_POPUP',
      payload: false
    });

    dispatch({
      type: 'UI_ACTION_AGREEMENT_POPUP',
      payload: true
    });
  };

  return (
    <>
      <Backdrop open={isUpdatedTermsPopOpen} className={classes.backdrop}>
        <Box height="max-content" className={classes.content}>
          <Box padding={1}>
            <Box>
              <Typography className={classes.light} variant="body1">
                Welcome to <span className={classes.bold}>Lokobee</span>
              </Typography>
            </Box>

            <Box paddingTop={2}>
              <Typography className={classes.light} variant="body1">
                Lokobee is updating it's terms and privacy policy
              </Typography>
            </Box>
            <Box paddingTop={2}>
              <Typography className={classes.light} variant="body1">
                Upon proceeding, you accept the new terms and privacy policy, which take effect immediately.
              </Typography>
            </Box>

            <Box paddingTop={2} display="flex" justifyContent="space-evenly">
              <Typography className={classes.link} variant="body1" onClick={onPrivacyClick}>
                Privacy Policy
              </Typography>
              <Typography className={classes.link} variant="body1" onClick={onAgreementClick}>
                User Agreement
              </Typography>
            </Box>

            <Box paddingTop={2} textAlign="center">
              <Button onClick={onAccepted} variant="contained" fullWidth={true} color="primary">
                Got it
              </Button>
            </Box>
          </Box>
        </Box>
      </Backdrop>
    </>
  );
};

export default UpdatedTerms;
