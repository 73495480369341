import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    '&:first-child': {
      padding: 0
    }
  },
  dialog: {
    '& .MuiPaper-root': {
      height: '100%'
    }
  },
  boldText: {
    fontWeight: 'bold'
  },
  container: {
    padding: theme.spacing(2)
  },
  yellow: {
    backgroundColor: theme.palette.primary.light
  },
  orange: {
    backgroundColor: theme.palette.warning.dark
  },
  red: {
    backgroundColor: theme.palette.error.main
  },
  green: {
    backgroundColor: theme.palette.success.main
  },
  blue: {
    backgroundColor: theme.palette.secondary.main
  },
  grey: {
    backgroundColor: theme.palette.grey[500]
  },
  img: {
    borderRadius: '5px'
  },
  greenTextColor: {
    color: theme.palette.success.main
  },
  boldTextNonLabel: {
    fontWeight: 'bolder',
    color: theme.palette.text.primary
  }
}));
