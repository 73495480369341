import { gql } from 'apollo-boost';

export const storeItemFragment = gql`
  fragment storeItemFragment on GroceryStoreItem {
    id
    categoryId
    subCategoryId
    title
    price
    description
    upcCode
    status
    size
    upcCode
    images {
      preview {
        id
        url
      }
      lg {
        id
        url
      }
      md {
        id
        url
      }
      xl {
        id
        url
      }
    }
    brand
    taxMode
    isReward
    rewardPoints
  }
`;
