import { Coupon } from 'generated/types';
import { convertPriceTo } from './number';
import Moment from 'moment';
import Big from 'big.js';
import { sortBy } from 'lodash';

export const couponOrderTypeMap: any = {
  TAKE_OUT: 'TAKEOUT',
  DINING: 'DINING',
  DELIVERY: 'DELIVERY'
};

export const isCouponApplicable = (coupon: Coupon, deliveryTime: number, subtotalToDiscount: string, orderType: 'TAKE_OUT' | 'DELIVERY' | 'DINING' | '') => {
  const { orderType: couponOrderType, minSpending, dayOfWeek, startDate, endDate } = coupon;

  let subtotalEligible,
    dayOfWeekEligible,
    isNotExpired,
    orderTypeEligible,
    useEligible = true;

  let isEligible: boolean | undefined = false;

  if (!!minSpending) {
    const minPurchase = convertPriceTo(minSpending, 'DOLLAR');

    subtotalEligible = Big(minPurchase).lte(subtotalToDiscount);
  }

  if (!!dayOfWeek) {
    const dayToday = Moment(deliveryTime).day();

    dayOfWeekEligible = dayOfWeek.includes(dayToday);
  }

  if (startDate && endDate) {
    const deliveryDate = Moment(deliveryTime).format('yyyy-MM-DD');
    isNotExpired = Moment(deliveryDate).isBetween(startDate, endDate, undefined, '[]');
  }

  if (!!couponOrderType && couponOrderType.length) {
    orderTypeEligible = couponOrderType?.includes(couponOrderTypeMap[orderType]);
  }

  isEligible = !['0.00', '0', '.0', ''].includes(subtotalToDiscount) && orderTypeEligible && subtotalEligible && dayOfWeekEligible && isNotExpired && useEligible;

  return isEligible;
};

export const getCheckedCouponFromList = (coupons: Coupon[], deliveryTime: number, subtotalToDiscount: string, orderType: 'TAKE_OUT' | 'DELIVERY' | 'DINING' | '') => {
  let applicableCouponsFiltered: Coupon[] = [];
  let notApplicableCouponsFiltered: Coupon[] = [];
  coupons.forEach((couponItem: Coupon) => {
    if (isCouponApplicable(couponItem, deliveryTime, subtotalToDiscount, orderType)) {
      applicableCouponsFiltered.push(couponItem);
    } else {
      notApplicableCouponsFiltered.push(couponItem);
    }
  });
  return {
    applicableCouponsFiltered,
    notApplicableCouponsFiltered
  };
};

export const getSortedCouponsbyDiscount = (applicableCoupons: Coupon[], subtotalToDiscount: string) => {
  let discountAmounts: any[] = [];
  let sortedCodes: any[] = [];
  applicableCoupons.forEach((couponItem: Coupon) => {
    if (couponItem.discountType === 'DOLLAR') {
      let tempItem = {
        code: couponItem.code,
        value: couponItem.discountValue
      };
      discountAmounts.push(tempItem);
    }
    if (couponItem.discountType === 'PERCENT') {
      let discountValue = (parseInt(convertPriceTo(subtotalToDiscount, 'CENT'), 10) * couponItem.discountValue) / 100;
      let tempItem = {
        code: couponItem.code,
        value: 0
      };
      if (couponItem.maxDiscount && discountValue >= couponItem.maxDiscount) {
        tempItem.value = couponItem.maxDiscount;
      } else {
        tempItem.value = discountValue;
      }
      discountAmounts.push(tempItem);
    }
  });
  discountAmounts = sortBy(discountAmounts, 'value').reverse();
  discountAmounts.forEach((item) => {
    sortedCodes.push(item.code);
  });
  return sortedCodes;
};
