import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  checkIcon: {
    fontSize: '50px',
    color: theme.palette.error.main
  },
  boldText: {
    fontWeight: 'bold'
  }
}));
