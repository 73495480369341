import { Auth } from 'aws-amplify';

export const orderTrackingCognitoConfig = () => {
  Auth.configure({
    Auth: {
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
      region: process.env.REACT_APP_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID
    }
  });
};

export const adChainCognitoConfig = () => {
  Auth.configure({
    Auth: {
      identityPoolId: process.env.REACT_APP_ADCHAIN_COGNITO_IDENTITY_POOL_ID,
      region: process.env.REACT_APP_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_ADCHAIN_COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_ADCHAIN_CONSUMER_CLIENT_ID!
    }
  });
};
