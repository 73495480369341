import React, { useContext } from 'react';
import { Box, Button } from '@material-ui/core';
import { FirebaseContext } from 'fbase';
import Resend from '../../Login/Resend';
import { useStore } from 'store';
import { useFormik, getIn, FormikHelpers } from 'formik';
import validations from './validation';
import Loading from '../../Login/Loading';
import InputField from 'components/Inputs/InputField';
import StayCurrentPortraitIcon from '@material-ui/icons/StayCurrentPortrait';
import { AuthContext } from 'fbase/authContext';
import { useAlert } from 'hooks';

interface IProps {
  verificationId: string | null;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

interface IFormStateType {
  otp: string;
}

const Verification: React.FC<IProps> = ({ verificationId, setStep }) => {
  const { firebase } = useContext(FirebaseContext);

  const { currentUser } = useContext(AuthContext);

  const auth = firebase!.getAuth();

  const { lokoAlert } = useAlert();

  const { dispatch } = useStore();

  const onSubmit = async (values: IFormStateType, actions: FormikHelpers<IFormStateType>) => {
    actions.setSubmitting(true);

    if (verificationId) {
      try {
        const phoneCredential = auth.PhoneAuthProvider.credential(verificationId, values.otp);

        await currentUser?.updatePhoneNumber(phoneCredential);

        dispatch({
          type: 'UI_ACTION_PHONE_VERIFICATION_POPUP',
          payload: false
        });
      } catch (e) {
        let message = '';

        if (e.code) {
          switch (e.code) {
            case 'auth/provider-already-linked':
              message = 'Email already exists choose different email';
              break;
            case 'auth/invalid-credential':
              message = 'Invalid credentials, Please try again';
              break;
            case 'auth/credential-already-in-use':
              message = 'Phone number already in use.';
              break;
            case 'auth/email-already-in-use':
              message = 'Email already exists';
              break;
            case 'auth/invalid-email':
              message = 'Invalid email';
              break;
            case 'auth/wrong-password':
              message = 'Invalid password';
              break;
            case 'auth/invalid-verification-code':
              message = 'Verification code is wrong';
              break;
            case 'auth/invalid-verification-id':
              message = 'Invalid verfication id, Please try again';
              break;
            default:
              message = e.message;
              console.log(e.message);
          }
        }

        lokoAlert(message);
      }
    } else {
      actions.setSubmitting(false);

      setStep(1);
    }
  };

  const formik = useFormik<IFormStateType>({
    initialValues: {
      otp: ''
    },
    validationSchema: validations,
    onSubmit
  });

  return (
    <Box width="100%" display="flex" flexDirection="column" justifyContent="center">
      <form onSubmit={formik.handleSubmit}>
        <Loading open={formik.isSubmitting} />
        <InputField
          value={formik.values.otp}
          name="otp"
          placeholder="Enter your verification code"
          onChange={formik.handleChange}
          error={!!getIn(formik.touched, 'otp') && !!getIn(formik.errors, 'otp')}
          helperText={!!getIn(formik.touched, 'otp') && !!getIn(formik.errors, 'otp') ? getIn(formik.errors, 'otp') : ''}
          onBlur={formik.handleBlur}
          startIcon={<StayCurrentPortraitIcon />}
          variant="outlined"
        />
        <Box textAlign="right" marginTop={1}>
          <Resend onClick={() => setStep(1)} />
          <Box component="span" marginLeft={1}>
            <Button variant="contained" color="primary" type="submit" disableElevation={true}>
              Submit
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default Verification;
