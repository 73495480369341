import * as Sentry from '@sentry/browser';

const Logger = {
  log: (errType: string, err: any) => {
    Sentry.captureMessage(errType, {
      extra: {
        error: JSON.stringify(err)
      }
    });
  }
};

export default Logger;
