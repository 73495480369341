import gql from 'graphql-tag';

export const createImageUploadUrlMutation = gql`
  mutation createImageUploadUrl($input: CreateImageUploadUrlInput!) {
    createImageUploadUrl(input: $input) {
      id
      usage
      url
    }
  }
`;
