import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Dialog, Typography, TextField, ButtonGroup, FormControl } from '@material-ui/core';
import RestaurantModel from 'models/Restaurant';
import DateTimePicker from 'components/DateTimePicker';
import { filter, isEmpty } from 'lodash';
import { Coupon, DeliveryCostEstimateOutput, ServiceType } from 'generated/types';
import DialogHeader from 'components/DialogHeader';
import { useHistory } from 'react-router-dom';
import DiningInput from '../DiningInput';
import { useStyles } from '../style';
import Big from 'big.js';
import AddressField from 'components/AddressModal/InputField';
import { IAddress } from 'components/AddressModal/types';
import Bill from '../Bill';
import { isRestaurantClosed } from '../util';
import { useAlert } from 'hooks';
import { isFutureDate } from 'util/date';
import { useRestaurantPause } from 'hooks/restaurant';
import RestaurantPause from 'components/RestaurantPause';
import { useStore } from 'store';
import { useDoordashDeliveryCostEstimateMutation } from 'graphql/hooks/delivery.hooks';
import ReviewPhoneNumber from 'components/ReviewPhoneNumber';
import { DeliveryMode, DeliveryProvider, DropOffType, IDeliveryEstimate, IDoordashDeliveryEstimate } from 'generated/custom';
import geoDistance from 'util/geoDistance';
import ApartmentIcon from '@material-ui/icons/Apartment';
import NoteIcon from '@material-ui/icons/Note';
import { convertPriceTo } from 'util/number';
import { getTimeData } from 'components/DateTimePicker/utils';
import { useGetCouponsByBizId } from 'graphql/hooks';
import PromotionsNew from 'components/Promotions_New';
interface IProps {
  restaurant: RestaurantModel | null;
  serviceFee: Big | null;
  orderType: 'TAKE_OUT' | 'DELIVERY' | 'DINING' | '';
  setOrderType: (payload: 'TAKE_OUT' | 'DINING' | 'DELIVERY') => void;
  deliveryTime: number;
  setDeliveryTime: (payload: number) => void;
  couponData: Partial<Coupon> | null;
  discount: string | null;
  numberOfPeople: number;
  tableNumber: string;
  subtotal: string;
  subtotalToDiscount: string;
  subtotalWithoutPrediscount: string;
  deliveryAddress: IAddress | null;
  deliveryNote: string;
  deliveryProvider: DeliveryProvider;
  deliveryMode: DeliveryMode;
  setDeliveryAddress: (payload: IAddress | null) => void;
  setDeliveryEstimate: (payload: IDeliveryEstimate) => void;
  setDoordashDeliveryEstimate: (payload: IDoordashDeliveryEstimate) => void;
  setDeliveryNote: (payload: string) => void;
  setDeliveryMiles: (payload: Big) => void;
  isDiscountApplied: boolean;
  setCouponData: (payload: { coupon: Partial<Coupon>; token: string } | null) => void;
  setTipAmount: (payload: Big) => void;
  setServiceFee: (payload: Big) => void;
  deliveryCharge: string;
  doordashCharge: string;
  tax: string | undefined;
  deliveryMiles: string;
  totalPriceAfterTax: string | undefined;
  subtotalAfterDiscount: string;
  usedPoints: number;
  enablePromocode: boolean;
  togglePrediscount: (payload: boolean) => void;
  isCartHavingPrediscountedItem: boolean;
  isPrediscountEnabled: boolean;
  setDropOffType: (payload: DropOffType) => void;
  dropOffType: string;
  apartmentNumber?: string;
  setApartmentNumber: (payload: string) => void;
  boonsQuoteId?: string;
  doordashQuoteId?: string | null;
}

const OrderType: React.FC<IProps> = ({
  orderType,
  restaurant,
  setDeliveryTime,
  tableNumber,
  numberOfPeople,
  subtotal,
  subtotalToDiscount,
  subtotalWithoutPrediscount,
  deliveryAddress,
  setOrderType,
  deliveryTime,
  deliveryNote,
  deliveryProvider,
  deliveryMode,
  setDeliveryNote,
  setDeliveryAddress,
  setDeliveryEstimate,
  setDeliveryMiles,
  isDiscountApplied,
  setCouponData,
  setTipAmount,
  discount,
  couponData,
  tax,
  deliveryCharge,
  deliveryMiles,
  serviceFee,
  setServiceFee,
  totalPriceAfterTax,
  subtotalAfterDiscount,
  usedPoints,
  enablePromocode,
  togglePrediscount,
  isCartHavingPrediscountedItem,
  isPrediscountEnabled,
  dropOffType,
  setDropOffType,
  apartmentNumber,
  setApartmentNumber,
  boonsQuoteId,
  doordashQuoteId,
  doordashCharge,
  setDoordashDeliveryEstimate
}) => {
  const classes = useStyles();

  const history = useHistory();

  const { lokoAlert } = useAlert();

  // For Auto Apply
  // useCoupons();

  const {
    dispatch,
    state: { tip, selectedPickerDate, selectedPickerTime }
  } = useStore();

  const { coupons } = useGetCouponsByBizId(restaurant?.id!!);

  const restaurantCoords = {
    lat: restaurant?.address?.lat || 37.543862,
    lng: restaurant?.address?.lng || -121.986923
  };

  // If address is set from store home page we call estimate api again in this page in useEffect
  // To prevent multiple API calls due to re rendering and useEffect being called again we use this state
  // we block estimate api call if one api is already in progress
  const [blockEstimateAPICall, setBlockEstimateAPICall] = useState(false);

  const deliveryRadius = restaurant?.getDeliverySettings?.deliveryRadius || 0;

  const [restaurantClosed, setRestaurantClosed] = useState(false);

  const [openFutureOrderPopup, setOpenFutureOrderPopup] = useState(false);

  const [showDiscountFutureAlert, setShowDiscountFutureAlert] = useState(true);

  const [open, setOpen] = useState<boolean>(false);

  const bizHours = restaurant?.getBizHours;

  const takeoutWaitMinutes = restaurant?.takeoutWaitMinutes;

  const minOrderValue = restaurant?.getDeliverySettings?.minDeliveryAmount || 0;

  const isTakeoutDisabled = !(restaurant?.takeoutPaymentSetting?.onlinePaymentTypes || restaurant?.takeoutPaymentSetting?.onsitePaymentTypes) || !restaurant.enableTakeoutOrder;

  const isDiningDisabled = !(restaurant?.diningPaymentSetting?.onlinePaymentTypes || restaurant?.diningPaymentSetting?.onsitePaymentTypes) || !restaurant.enableDiningOrder;

  const isDeliveryDisabled = !(restaurant?.deliveryPaymentSetting?.onlinePaymentTypes || restaurant?.deliveryPaymentSetting?.onsitePaymentTypes) || !restaurant.enableDeliveryOrder;

  const deliveryTimeMins = restaurant?.deliverySetting?.waitMinutes || 40;

  const diningBizHours = bizHours ? filter(bizHours, ({ serviceType }) => serviceType === ServiceType.RestaurantDining) : [];

  const takeoutBizHours = bizHours ? filter(bizHours, ({ serviceType }) => serviceType === ServiceType.RestaurantPickUp) : [];

  const deliveryBizHours = bizHours ? filter(bizHours, ({ serviceType }) => serviceType === ServiceType.RestaurantDelivery) : [];

  const { deliveryPause, diningPause, takeOutPause } = useRestaurantPause({ restaurant });

  const [nextSlotStr, setNextSlotStr] = useState('');

  const { getDoordashEstimate } = useDoordashDeliveryCostEstimateMutation();

  const onHandleClose = () => {
    dispatch({
      type: 'UI_ACTION_FROM_REVIEW_PAGE',
      payload: false
    });
    setOpen(false);
  };

  useEffect(() => {
    if (orderType === '') {
      if (!isTakeoutDisabled && isDiningDisabled && isDeliveryDisabled) {
        setOrderType('TAKE_OUT');
      } else if (isTakeoutDisabled && !isDiningDisabled && isDeliveryDisabled) {
        setOrderType('DINING');
      } else if (isTakeoutDisabled && isDiningDisabled && !isDeliveryDisabled) {
        setOrderType('DELIVERY');
      } else {
      }
    }
  }, [isDeliveryDisabled, isDiningDisabled, isTakeoutDisabled, orderType, setOrderType]);

  useEffect(() => {
    if (orderType === 'DINING') {
      const restaurnatClosed = isRestaurantClosed(diningBizHours, orderType, deliveryProvider);
      setRestaurantClosed(restaurnatClosed);
    }
    if (orderType === 'TAKE_OUT') {
      const restaurnatClosed = isRestaurantClosed(takeoutBizHours, orderType, deliveryProvider);
      setRestaurantClosed(restaurnatClosed);
    }
    if (orderType === 'DELIVERY') {
      const restaurnatClosed = isRestaurantClosed(deliveryBizHours, orderType, deliveryProvider);
      setRestaurantClosed(restaurnatClosed);
    }
  }, [deliveryBizHours, deliveryProvider, deliveryTimeMins, diningBizHours, orderType, restaurantClosed, takeoutBizHours, takeoutWaitMinutes]);

  const callDoordashEstimateAPI = useCallback(
    async (selectedAddress: IAddress) => {
      if (!blockEstimateAPICall) {
        setBlockEstimateAPICall(true);

        const { text, country, state, city, zipcode, lat, lng, street } = selectedAddress;

        const sellerBizId = restaurant?.getId;

        const curSubtotal = Big(isDiscountApplied ? subtotalAfterDiscount : subtotal);
        const tipVal = tip;

        const orderVal = curSubtotal.add(tipVal).valueOf();

        const input = {
          doordashPickupAddress: {
            zip_code: restaurant?.getAddress?.zipcode,
            city: restaurant?.getAddress?.city,
            street: restaurant?.getAddress?.street,
            full_address: restaurant?.getAddress?.text
          },
          doordashDropoffAddress: {
            zip_code: selectedAddress.zipcode,
            city: selectedAddress.city,
            street: selectedAddress.street,
            full_address: selectedAddress.text
          },
          deliveryProvider,
          sellerBizId,
          doordashOrderValue: Math.round(parseFloat(convertPriceTo(orderVal, 'CENT')))
        };

        const res = await getDoordashEstimate({
          variables: {
            input
          }
        });

        if (res && !res.errors) {
          const deliveryEstimate: DeliveryCostEstimateOutput = res.data.deliveryCostEstimateRequest;

          const deliveryCharge = deliveryEstimate && deliveryEstimate.doordashFee ? Big(convertPriceTo(deliveryEstimate.doordashFee, 'DOLLAR')) : Big('0');
          const quoteId = deliveryEstimate && deliveryEstimate.quote_id ? deliveryEstimate.quote_id : '';
          const miles = geoDistance(restaurantCoords.lat, restaurantCoords.lng, selectedAddress.lat, selectedAddress.lng, 'M');
          setDeliveryAddress({
            city,
            country,
            lat,
            lng,
            state,
            street,
            text,
            zipcode
          });
          setDoordashDeliveryEstimate({ deliveryMiles: Big(miles), deliveryCharge, doordashQuoteId: quoteId });
        } else {
          setDeliveryAddress(null);
          setDoordashDeliveryEstimate({ deliveryMiles: Big(0), deliveryCharge: Big(0), doordashQuoteId: null });
          lokoAlert('Error getting delivery estimate. Selected address is out of range.');
        }

        setBlockEstimateAPICall(false);
      }
    },
    [
      blockEstimateAPICall,
      deliveryProvider,
      getDoordashEstimate,
      isDiscountApplied,
      lokoAlert,
      restaurant,
      restaurantCoords.lat,
      restaurantCoords.lng,
      setDeliveryAddress,
      setDoordashDeliveryEstimate,
      subtotal,
      subtotalAfterDiscount,
      tip
    ]
  );

  useEffect(() => {
    if (orderType === 'DELIVERY' && deliveryProvider === 'DOORDASH_CLASSIC' && deliveryAddress !== null && !doordashQuoteId) {
      callDoordashEstimateAPI(deliveryAddress);
    }
  }, [callDoordashEstimateAPI, deliveryAddress, deliveryProvider, doordashQuoteId, orderType]);

  useEffect(() => {
    if (orderType === 'DELIVERY' && deliveryProvider !== 'SELF') {
      dispatch({
        type: 'CART_ACTION_SET_DELIVERY_TIME',
        payload: Date.now()
      });
    }
  }, [deliveryProvider, dispatch, orderType]);

  useEffect(() => {
    if (isCartHavingPrediscountedItem) {
      const futureOrder = isFutureDate(deliveryTime);

      const isFutureAllowed = orderType === 'TAKE_OUT' || (orderType === 'DELIVERY' && deliveryProvider === 'SELF');

      if (futureOrder && isFutureAllowed) {
        if (showDiscountFutureAlert) {
          lokoAlert('Discount on Item(s) in the cart is not applicable for FUTURE date orders.');
        }
        setShowDiscountFutureAlert(false);

        togglePrediscount(false);
      } else {
        setShowDiscountFutureAlert(true);

        togglePrediscount(true);
      }
    }
  }, [deliveryProvider, deliveryTime, isCartHavingPrediscountedItem, lokoAlert, orderType, showDiscountFutureAlert, togglePrediscount]);

  const disabled = () => {
    if (orderType === '') {
      return true;
    }
    if (orderType === 'DINING') {
      if (numberOfPeople < 1) {
        return true;
      }

      if (isEmpty(tableNumber)) {
        return true;
      }

      if (!diningBizHours.length) {
        return true;
      }

      if (diningPause) {
        return true;
      }

      if (restaurantClosed) {
        return true;
      }
    }

    if (orderType === 'DELIVERY') {
      const futureOrder = isFutureDate(deliveryTime);

      if (Big(subtotal).lt(Big(minOrderValue))) {
        return true;
      }
      if (!deliveryBizHours.length) {
        return true;
      }

      if (!deliveryAddress) {
        return true;
      }

      if (deliveryPause && !futureOrder) {
        return true;
      }

      if (deliveryProvider === 'DOORDASH_CLASSIC') {
        if (!doordashQuoteId) {
          return true;
        }
        if (restaurantClosed) {
          return true;
        }
      }
    }

    if (orderType === 'TAKE_OUT') {
      const futureOrder = isFutureDate(deliveryTime);
      if (!takeoutBizHours.length) {
        return true;
      }

      if (takeOutPause && !futureOrder) {
        return true;
      }
    }

    return false;
  };

  const onAddressSelect = async (selectedAddress: IAddress) => {
    if (deliveryProvider === 'SELF') {
      const { text, country, state, city, zipcode, lat, lng, street } = selectedAddress;
      // Calculating delivery miles for restaurant self managed delivery
      const miles = geoDistance(restaurantCoords.lat, restaurantCoords.lng, selectedAddress.lat, selectedAddress.lng, 'M');

      setDeliveryAddress({
        city,
        country,
        lat,
        lng,
        state,
        street,
        text,
        zipcode
      });

      setDeliveryMiles(Big(miles));
    } else if (deliveryProvider === 'DOORDASH_CLASSIC') {
      await callDoordashEstimateAPI(selectedAddress);
    }
  };

  const onAddressClear = () => {
    if (orderType === 'DELIVERY' && deliveryProvider === 'DOORDASH_CLASSIC') {
      setDoordashDeliveryEstimate({ deliveryMiles: Big(0), deliveryCharge: Big(0), doordashQuoteId: null });
    }
  };

  const onBack = () => {
    history.replace('/cart');
  };

  const getButtonText = () => {
    if (totalPriceAfterTax && parseInt(totalPriceAfterTax) === 0) {
      return 'Place Order';
    }
    if (orderType === '') {
      return 'Choose a service above';
    }
    return 'Continue to payment';
  };

  const onButtonContinue = () => {
    if (orderType === 'DELIVERY') {
      setOpen(true);
    } else if (totalPriceAfterTax && parseInt(totalPriceAfterTax) === 0) {
      // This is a reward dish so not payment.
      history.push('/payment/onsite');
    } else if (totalPriceAfterTax) {
      history.push('/payment');
    } else {
      lokoAlert('Something Went wrong');
    }
  };

  const getDeliveryEstimateText = () => {
    if (deliveryProvider === 'DOORDASH_CLASSIC') {
      return '(ASAP)';
    } else {
      return `(${deliveryTimeMins} mins)`;
    }
  };

  const [instruction, setInstruction] = useState('10');

  const handleChange = (event: React.ChangeEvent<any>) => {
    setInstruction(event.target.value);
    event.target.value === '10' ? setDropOffType(DropOffType.InHand) : setDropOffType(DropOffType.LeaveAtTheDoor);
  };

  const handleOrderTypeChange = (type: 'TAKE_OUT' | 'DINING' | 'DELIVERY') => {
    setOrderType(type);
  };

  useEffect(() => {
    if (orderType !== '') {
      let service = '';
      let waitMins = 0;

      if (orderType === 'DINING') {
        service = 'RESTAURANT_DINING';
      }

      if (orderType === 'TAKE_OUT') {
        service = 'RESTAURANT_PICK_UP';
        waitMins = takeoutWaitMinutes || 10;
      }

      if (orderType === 'DELIVERY') {
        service = 'RESTAURANT_DELIVERY';
        waitMins = deliveryProvider === 'DOORDASH_CLASSIC' ? 60 : deliveryTimeMins;
      }

      const curHours = filter(bizHours, (hour) => hour.serviceType === service);

      const availableSlots = getTimeData(curHours, waitMins);

      if (availableSlots.length) {
        const firstSlot = availableSlots[0].availableSlots[0];

        if (orderType === 'DELIVERY' && deliveryProvider === 'DOORDASH_CLASSIC') {
          // To create slots we add wait time to start and end time
          // But for doordash we don't need to add to start time so subtracting here
          // We still pass it because we need to subtract from end time

          firstSlot.subtract(60, 'm');
        }

        if (firstSlot) {
          const nextSlot = firstSlot.format('LT');

          setNextSlotStr(nextSlot);
        }
      }
    }
  }, [bizHours, deliveryProvider, deliveryTimeMins, orderType, takeoutWaitMinutes]);

  return (
    <Box maxWidth={1200} margin="auto" display="flex" flexDirection="column" height="100%">
      <DialogHeader text="Choose a service" onBack={onBack} />
      <Box flex={1} display="flex" flexDirection="column" overflow="scroll">
        <Box padding={1}>
          <Box display="flex" alignItems="center">
            <ButtonGroup fullWidth={true} color="secondary">
              {!isTakeoutDisabled && (
                <Button size="small" disableElevation={true} variant={orderType === 'TAKE_OUT' ? 'contained' : 'outlined'} onClick={() => handleOrderTypeChange('TAKE_OUT')}>
                  Takeout ({takeoutWaitMinutes} mins)
                </Button>
              )}
              {!isDiningDisabled && (
                <Button size="small" disableElevation={true} variant={orderType === 'DINING' ? 'contained' : 'outlined'} onClick={() => handleOrderTypeChange('DINING')}>
                  Dining
                </Button>
              )}
              {!isDeliveryDisabled && (
                <Button size="small" disableElevation={true} variant={orderType === 'DELIVERY' ? 'contained' : 'outlined'} onClick={() => handleOrderTypeChange('DELIVERY')}>
                  Delivery {getDeliveryEstimateText()}
                </Button>
              )}
            </ButtonGroup>
          </Box>
        </Box>
        <Box paddingY={1}>
          {orderType === 'TAKE_OUT' && (
            <DateTimePicker
              selectedPickerDate={selectedPickerDate}
              selectedPickerTime={selectedPickerTime}
              orgType="RESTAURANT"
              onChange={() => {}}
              businessHours={bizHours ? bizHours : []}
              waitingTime={takeoutWaitMinutes}
              orderType="TAKEOUT"
              label="Takeout time"
            />
          )}
          {orderType === 'DINING' && restaurant && <DiningInput restaurantId={restaurant.id} />}
          {orderType === 'DELIVERY' && (
            <>
              {deliveryProvider === 'SELF' && (
                <DateTimePicker
                  selectedPickerDate={selectedPickerDate}
                  selectedPickerTime={selectedPickerTime}
                  orgType="RESTAURANT"
                  onChange={() => {}}
                  businessHours={bizHours ? bizHours : []}
                  waitingTime={deliveryTimeMins}
                  orderType="DELIVERY"
                  label="Delivery time"
                />
              )}
              <Box padding={1}>
                <AddressField
                  defaultValue={deliveryAddress ? deliveryAddress.text : ''}
                  onChange={() => setDeliveryAddress(null)}
                  onAddressClear={onAddressClear}
                  onAddressSelect={onAddressSelect}
                  variant="outlined"
                  restaurantLatitude={restaurantCoords.lat}
                  restaurantLongitude={restaurantCoords.lng}
                  checkRange={true}
                  deliveryRadius={deliveryRadius}
                />
              </Box>
              <Box padding={1}>
                <TextField
                  placeholder="Apartment Number"
                  fullWidth={true}
                  value={apartmentNumber}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setApartmentNumber(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: <ApartmentIcon className={classes.icon} />
                  }}
                  variant="outlined"
                />
              </Box>
              <Box padding={1}>
                <TextField
                  placeholder="Enter delivery note"
                  fullWidth={true}
                  value={deliveryNote}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setDeliveryNote(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: <NoteIcon className={classes.icon} />
                  }}
                  variant="outlined"
                />
              </Box>
              <Box padding={1}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    variant="outlined"
                    select
                    label="Drop-off Instruction"
                    value={instruction}
                    onChange={handleChange}
                    fullWidth
                    SelectProps={{
                      native: true
                    }}>
                    <option value={10}>Hand it over in Person</option>
                    <option value={20}>Leave it at the Door</option>
                  </TextField>
                </FormControl>
                {instruction === '10' && (
                  <Typography color="error" variant="caption">
                    For in Person food hand over, Please follow CDC Mask Guidelines
                  </Typography>
                )}
              </Box>
            </>
          )}
        </Box>
        <Box>
          {enablePromocode && !!orderType && coupons && (
            <>
              {/* <CryptoPromo
                orderType={orderType}
                coupons={coupons}
                subtotalToDiscount={isPrediscountEnabled ? subtotalToDiscount : subtotalWithoutPrediscount}
                subtotal={subtotal}
                restaurantId={restaurant?.id || ''}
                appliedCoupon={couponData}
                discount={discount}
                isDiscountApplied={isDiscountApplied}
                setCouponData={setCouponData}
                isCartHavingPrediscountedItem={isCartHavingPrediscountedItem}
                deliveryTime={deliveryTime}
                isPrediscountEnabled={isPrediscountEnabled}
              /> */}
              <PromotionsNew
                orderType={orderType}
                coupons={coupons}
                subtotalToDiscount={isPrediscountEnabled ? subtotalToDiscount : subtotalWithoutPrediscount}
                subtotal={subtotal}
                restaurantId={restaurant?.id || ''}
                appliedCoupon={couponData}
                discount={discount}
                isDiscountApplied={isDiscountApplied}
                setCouponData={setCouponData}
                isCartHavingPrediscountedItem={isCartHavingPrediscountedItem}
                deliveryTime={deliveryTime}
                isPrediscountEnabled={isPrediscountEnabled}
              />
            </>
          )}
          {restaurant && (
            <Bill
              restaurantId={restaurant?.id || ''}
              isServiceFeeEnabled={restaurant.lokobeeFee}
              orderType={orderType}
              deliveryAddress={deliveryAddress}
              subtotal={subtotal}
              discount={discount}
              isDiscountApplied={isDiscountApplied}
              couponData={couponData}
              serviceFee={serviceFee}
              taxRate={restaurant?.getTaxRate!}
              tax={tax}
              deliveryCharge={deliveryCharge}
              deliveryMiles={deliveryMiles}
              setServiceFee={setServiceFee}
              finalPrice={totalPriceAfterTax}
              setTipAmount={setTipAmount}
              subtotalAfterDiscount={subtotalAfterDiscount}
              usedPoints={usedPoints}
              isCartHavingPrediscountedItem={isCartHavingPrediscountedItem}
              isPrediscountEnabled={isPrediscountEnabled}
              subtotalToDiscount={subtotalToDiscount}
              doordashCharge={doordashCharge}
              deliveryProvider={deliveryProvider}
            />
          )}
        </Box>
      </Box>

      <Box padding={1}>
        {restaurantClosed && (orderType === 'TAKE_OUT' || (orderType === 'DELIVERY' && deliveryProvider === 'SELF')) && (
          <Typography variant="body1" color="error">
            Restaurant is closed right now, but you can place future order.
          </Typography>
        )}

        {restaurantClosed && (orderType === 'DINING' || (orderType === 'DELIVERY' && deliveryProvider !== 'SELF')) && (
          <Typography variant="body1" color="error">
            Restaurant closed right now.{nextSlotStr !== '' ? `You can place order at ${nextSlotStr}` : ''}
          </Typography>
        )}

        {orderType === 'DINING' && diningBizHours.length === 0 && (
          <Typography variant="body1" color="error">
            Business hours not available for dining.
          </Typography>
        )}
        {orderType === 'DELIVERY' && deliveryAddress === null && (
          <Typography variant="body1" color="error">
            Please select delivery address.
          </Typography>
        )}

        {orderType === 'DELIVERY' && Big(subtotal).lt(Big(minOrderValue)) && (
          <Typography variant="body1" color="error">
            Minimum order value for delivery order is $ {minOrderValue}.
          </Typography>
        )}
        {restaurant && <RestaurantPause restaurant={restaurant} />}
        <Button
          variant="contained"
          color="primary"
          fullWidth={true}
          disabled={disabled()}
          onClick={() => {
            (orderType === 'TAKE_OUT' || (orderType === 'DELIVERY' && deliveryProvider === 'SELF')) && isFutureDate(deliveryTime) ? setOpenFutureOrderPopup(true) : onButtonContinue();
          }}>
          {getButtonText()}
        </Button>
      </Box>
      <Dialog open={openFutureOrderPopup}>
        <Box padding={2}>
          <Typography variant="body1">You are placing order for a FUTURE date.This order is NOT for TODAY. Do you still want to proceed to payment?</Typography>
          <Box className={classes.btnContainer} display="flex" justifyContent="flex-end">
            <Button variant="contained" color="primary" onClick={onButtonContinue}>
              Yes
            </Button>
            <Button variant="contained" color="primary" onClick={() => setOpenFutureOrderPopup(false)}>
              No
            </Button>
          </Box>
        </Box>
      </Dialog>
      {open && <ReviewPhoneNumber totalPriceAfterTax={totalPriceAfterTax} onHandleClose={onHandleClose} />}
    </Box>
  );
};

export default OrderType;
