import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  dishContainer: {
    padding: theme.spacing(0),
    marginBottom: theme.spacing(1)
  },
  bold: {
    fontWeight: 'bold'
  },
  priceItem: {
    padding: theme.spacing(0.5)
  },
  priceStriked: {
    fontWeight: 400,
    color: '#7e818c',
    fontSize: "12px",
    textDecoration: "line-through"
  },
  priceDiscount: {
    background: theme.palette.primary.main,
    color: theme.palette.grey[800],
    padding: "0 4px",
    borderRadius: "2px",
    fontWeight: 500,
  }
}));
