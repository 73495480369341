import { useMutationWithLoader } from 'hooks/loader';
import { deliveryCostEstimate, doordashDeliveryCostEstimate } from 'graphql/mutations/delivery.mutation';

export const useDeliveryCostEstimateMutation = () => {
  const [getEstimate, { data, loading, error }] = useMutationWithLoader(deliveryCostEstimate, {}, true);

  return {
    getEstimate,
    data,
    loading,
    error
  };
};

export const useDoordashDeliveryCostEstimateMutation = () => {
  const [getDoordashEstimate, { data, loading, error }] = useMutationWithLoader(doordashDeliveryCostEstimate, {}, true);

  return {
    getDoordashEstimate,
    data,
    loading,
    error
  };
};
