import React, { CSSProperties } from 'react';
import { Sticky as ReactSticky } from 'react-sticky';
import { Box } from '@material-ui/core';

const styles: CSSProperties = {
  zIndex: 1000
};

const Sticky: React.FC = ({ children }) => {
  return (
    <ReactSticky topOffset={0} relative={true}>
      {({ style }) => <Box style={{ ...style, ...styles }}>{children}</Box>}
    </ReactSticky>
  );
};

export default Sticky;
