import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import 'react-phone-number-input/style.css';
import Register from './Register';
import Login from './Login';
import ForgotPassword from './ForgotPassword';

/**
 * @type {React.Component} - Login form
 */
const Authentication: React.FC = () => {
  const [step, setStep] = useState(1);

  return (
    <Box display="flex" flex={1} flexDirection="column">
      {step === 1 && <Login setStep={setStep} />}
      {step === 2 && <Register setStep={setStep} />}
      {step === 3 && <ForgotPassword setStep={setStep} />}
    </Box>
  );
};

export default Authentication;
