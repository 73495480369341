import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme) => ({
  locationIcon: {
    color: grey[500],

    marginRight: 10
  },
  clearIcon: {
    cursor: 'pointer'
  },
  suggestionContainer: {
    position: 'absolute',
    zIndex: 20000,
    width: '100%',
    backgroundColor: 'white',
    maxHeight: '200px',
    overflow: 'scroll'
  },
  suggestionBox: {
    cursor: 'pointer'
  }
}));
