import React from 'react';
import { Dialog, DialogContent, useMediaQuery } from '@material-ui/core';
import Transition from 'components/Transition';
import Login from './Login';
import { useStyles } from './style';
import { useStore } from 'store';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import Agreement from 'pages/UserAgreement';

/**
 * @type {React.Component} - SignUp form
 */
const AccountModal = () => {
  const classes = useStyles();

  const {
    state: { ui }
  } = useStore();

  // eslint-disable-next-line
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(ui.isLoginPopOpen || ui.isPrivacyPopOpen || ui.isAgreementPopOpen);
  }, [ui]);

  const fullScreen = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));

  return (
    <>
      <Dialog open={ui.isPrivacyPopOpen} fullWidth={true} TransitionComponent={Transition} fullScreen={fullScreen} maxWidth="sm">
        <DialogContent className={classes.dialogContent}>{ui.isPrivacyPopOpen && <PrivacyPolicy />}</DialogContent>
      </Dialog>
      <Dialog open={ui.isAgreementPopOpen} fullWidth={true} TransitionComponent={Transition} fullScreen={fullScreen} maxWidth="sm">
        <DialogContent className={classes.dialogContent}>{ui.isAgreementPopOpen && <Agreement />}</DialogContent>
      </Dialog>
      <Dialog open={ui.isLoginPopOpen} fullWidth={true} TransitionComponent={Transition} fullScreen={fullScreen} maxWidth="sm">
        <DialogContent className={classes.dialogContent}>{ui.isLoginPopOpen && <Login />}</DialogContent>
      </Dialog>
    </>
  );
};

export default AccountModal;
