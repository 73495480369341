import React, { useState } from 'react';
import { Box, Dialog, Typography, Button } from '@material-ui/core';
import { useStyles } from './style';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';

interface IProps {
  text: string | null | undefined;
}

const Announcement: React.FC<IProps> = ({ text }) => {
  const classes = useStyles();

  const header = document.getElementById('header');

  const top = header ? header.clientHeight : 0;

  const [openDialog, setOpenDialog] = useState(false);

  if (text) {
    return (
      <>
        <Box onClick={() => setOpenDialog(true)} display="flex" alignItems="center" className={classes.root} top={top}>
          <Box mr={1} display="flex" justifyItems="center" className={classes.volumeIcon}>
            <VolumeUpIcon />
          </Box>

          <Typography variant="caption" noWrap={true}>
            {text}
          </Typography>
        </Box>

        <Dialog open={openDialog}>
          <Box padding={1} display="flex" alignItems="center" flexDirection="column">
            <Typography variant="body1" align="justify">
              {text}
            </Typography>
            <Box padding={1}>
              <Button variant="contained" size="small" color="primary" disableElevation={true} onClick={() => setOpenDialog(false)}>
                OK
              </Button>
            </Box>
          </Box>
        </Dialog>
      </>
    );
  }

  return null;
};

export default Announcement;
