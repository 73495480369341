import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    height: '100%',
    padding: 0,
    '&:first-child': {
      padding: 0
    }
  },

  infoHoursBtn: {
    textTransform: 'capitalize',
    marginRight: theme.spacing(1)
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    alignItems: 'center'
  },
  modalTitle: {},
  modalIcon: {},
  catergoryHeading: {
    alignItems: 'center',
    padding: theme.spacing(2),
    '& > *': {
      borderColor: theme.palette.secondary.dark
    }
  },
  selectedCategory: {
    backgroundColor: theme.palette.secondary.dark,
    color: 'white',
    '&:hover': {
      background: theme.palette.secondary.dark
    }
  },
  notSelectedCategory: {
    backgroundColor: 'white',
    color: 'black',
    '&:hover': {
      background: 'transparent'
    }
  },
  backdrop: {
    zIndex: 1300
  },
  customTipContainer: {
    padding: theme.spacing(1, 2),
    backgroundColor: 'white',
    height: '70%',
    width: '95%',
    bottom: 0,
    position: 'absolute'
  },
  clickableCoupons: {
    cursor: 'pointer'
  }
}));
