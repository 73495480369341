import { makeStyles, Theme } from '@material-ui/core';
import { green, grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  banner: {
    backgroundColor: grey[100]
  },
  bannerWhite: {
    backgroundColor: 'white',
    paddingTop: theme.spacing(1)
  },
  catergoryHeading: {
    alignItems: 'center',
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 40)
    },
    '& > *': {
      borderColor: theme.palette.secondary.dark
    }
  },
  selectedCategory: {
    backgroundColor: theme.palette.secondary.dark,
    color: 'white',
    '&:hover': {
      background: theme.palette.secondary.dark
    }
  },
  notSelectedCategory: {
    backgroundColor: 'white',
    color: 'black',
    '&:hover': {
      background: 'transparent'
    }
  },
  backdrop: {
    zIndex: 1450
  },
  image: {
    borderRadius: theme.spacing(1),
    width: '100%',
    transform: 'translateX(1em)'
  },
  DesktopImage: {
    borderRadius: theme.spacing(1),
    width: '70%',
    transform: 'translateX(1em)'
  },
  imageBox: {
    transform: 'translate(1.5em, 1em)'
  },
  restaurantName: {
    paddingLeft: theme.spacing(2)
  },
  restaurantAbout: {
    padding: theme.spacing(1)
  },
  aboutDesktop: {
    paddingLeft: theme.spacing(2)
  },
  link: {
    textDecoration: 'none',
    color: 'black'
  },
  phoneIcon: {
    backgroundColor: green[800],
    borderRadius: '50%',
    padding: theme.spacing(0.5),
    height: theme.spacing(1.5),
    width: 'auto',
    color: 'white'
  },
  phoneContainer: {
    transform: 'translateY(-0.5em)'
  },
  locationIcon: {
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: '50%',
    padding: theme.spacing(0.5),
    color: 'white',
    height: theme.spacing(1.5),
    width: 'auto'
  },

  scrollComponent: {
    backgroundColor: grey[100],
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'white'
    }
  }
}));
