import gql from 'graphql-tag';

export const createOrderMutation = gql`
  mutation createOrder($input: CreateOrderInput!) {
    createOrder(input: $input) {
      orderId
      requestId
      redirectUrl
    }
  }
`;

export const createStripeSetupIntentMutation = gql`
  mutation createStripeSetupIntent($input: CreateStripeSetupIntentInput!) {
    createStripeSetupIntent(input: $input) {
      clientSecret
    }
  }
`;

export const createTableServiceOrderMutation = gql`
  mutation createTableServiceOrder($input: CreateTableServiceOrderInput!) {
    createTableServiceOrder(input: $input) {
      id
    }
  }
`;
