import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  swiper: {
    '& .swiper-container': {
      paddingBottom: theme.spacing(1)
    },
    '& .swiper-pagination-bullet-active': {
      background: theme.palette.secondary.main
    },
    '& .swiper-scrollbar': {
      height: '0.4rem',
      borderRadius: '0'
    },
    '& .swiper-scrollbar-drag': {
      background: '#000',
      borderRadius: '0'
    }
  }
}));