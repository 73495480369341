import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '16px 32px'
  },
  checkIcon: {
    fontSize: '50px',
    color: theme.palette.success.main
  },
  boldText: {
    fontWeight: 'bold'
  },
  surface: {
    borderRadius: '8px'
  },
  surfaceText: {
    lineHeight: '1.2'
  },
  reviewButton: {
    textTransform: 'none',
    display: 'flex',
    color: 'black',
    border: '1px solid #e4e4e7',
    marginBottom: 8,
    fontWeight: 200,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}));
