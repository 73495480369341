import React from 'react';
import { Box, Typography, TextField, Grid } from '@material-ui/core';
import { useStore } from 'store';
import SelectTables from './SelectTables';

interface IProps {
  restaurantId: string;
}

const DiningInput = ({ restaurantId }: IProps) => {
  const {
    state: { orderType, numberOfPeople },
    dispatch
  } = useStore();

  const onDiningNumberOfPeopleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'CART_ACTION_SET_DINING_NUMBER_OF_PEOPLE',
      payload: (event.target as HTMLInputElement).value
    });
  };

  if (orderType === 'DINING') {
    return (
      <Box padding={2}>
        <Box marginBottom={1}>
          <Grid container={true}>
            <Grid item={true} sm={8} xs={8}>
              <Typography variant="body1">Number of people you are ordering for</Typography>
            </Grid>
            <Grid item={true} sm={4} xs={4}>
              <TextField type="number" value={numberOfPeople} onChange={onDiningNumberOfPeopleChange} variant="outlined" size="small" />
            </Grid>
          </Grid>
        </Box>
        <Grid container={true}>
          <Grid item={true} sm={8} xs={8}>
            <Typography variant="body1">Table number</Typography>
          </Grid>
          <Grid item={true} sm={4} xs={4}>
            <SelectTables restaurantId={restaurantId} />
          </Grid>
        </Grid>
      </Box>
    );
  }

  return null;
};

export default DiningInput;
