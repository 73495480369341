import React from 'react';
import { Box } from '@material-ui/core';
import InfoAndHoursSwipeCard from 'pages/Restaurant/RestaurantHome/RestaurantInfo/InfoAndHours/InfoAndHoursSwipeCard';
import { MultiSizeImage } from 'generated/types';
import { useStyles } from './style';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { grey } from '@material-ui/core/colors';

// install Swiper components
SwiperCore.use([Pagination, Autoplay]);

interface IProps {
  images: MultiSizeImage[] | null;
}

const InfoAndHourSwiper: React.FC<IProps> = ({ images }) => {
  const classes = useStyles();
  return (
    <Box bgcolor={grey[300]} className={classes.swiperBox}>
      <Box className={classes.swiper}>
        <Swiper watchOverflow={true} autoHeight={true} spaceBetween={10} pagination={{ clickable: true }} slidesPerView={1}>
          {images?.map((galleryimage, index) => {
            const {
              md: { url }
            } = galleryimage;
            return (
              <SwiperSlide key={index}>
                <Box height="100%">
                  <InfoAndHoursSwipeCard imageSrc={url} />
                </Box>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
    </Box>
  );
};

export default InfoAndHourSwiper;
