import shortid from 'shortid';

export const createIdempotencyKey = () => {
  if (window && window.localStorage) {
    window.localStorage.setItem('key', shortid.generate());
  }
};

export const getIdempotencyKey = () => {
  if (window && window.localStorage) {
    return window.localStorage.getItem('key');
  }
};
