import React, { useContext } from 'react';
import { Box, Button, Typography } from '@material-ui/core';

import { FirebaseContext } from 'fbase';
import { useStore } from 'store';
import { useStyles } from './style';
import GoogleIcon from 'assets/img/google_icon.svg';
import { useAlert } from 'hooks';
interface IProps {
  lokobeeWalletLogin?: boolean;
}
const GoogleSignin: React.FC<IProps> = ({ lokobeeWalletLogin = false }) => {
  const classes = useStyles();

  const { firebase } = useContext(FirebaseContext);

  const auth = firebase!.getAuth();

  const { dispatch } = useStore();

  const { lokoAlert } = useAlert();
  const onClick = () => {
    const provider = new auth.GoogleAuthProvider();

    auth()
      .signInWithPopup(provider)
      .then(() => {
        if (lokobeeWalletLogin) {
          dispatch({
            type: 'UI_ACTION_LOKOBEE_WALLET_LOGIN',
            payload: true
          });
        }

        dispatch({
          type: 'UI_ACTION_LOGIN_POPUP',
          payload: false
        });
        dispatch({
          type: 'UI_ACTION_UPDATED_TERMS_POPUP',
          payload: true
        });
      })
      .catch((e) => {
        lokoAlert(e.message);
      });
  };

  return (
    <>
      <Box className={classes.btnContainer}>
        <Button className={classes.root} size="medium" variant="contained" color="secondary" fullWidth onClick={onClick}>
          <Box display={'flex'}>
            <img alt="" width={'36.5px'} src={GoogleIcon} />
          </Box>
          <Box display={'flex'} justifyContent="center" flex={1}>
            <Typography align="center" variant="body1">
              Sign in with Google
            </Typography>
          </Box>
        </Button>
      </Box>
    </>
  );
};

export default GoogleSignin;
