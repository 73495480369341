import gql from 'graphql-tag';

export const groceryDoordashDeliveryCostEstimate = gql`
  mutation groceryDeliveryEstimateRequest($input: GroceryDeliveryEstimateInput!) {
    groceryDeliveryEstimateRequest(input: $input) {
      quote_id
      doordashFee
      doordashDeliveryTime
    }
  }
`;
