import React from 'react';
import { Box, TextField, InputAdornment, Typography, TextFieldProps } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStyles } from './style';

type IProps = TextFieldProps & {
  className?: string;
  loading?: boolean;
  startIcon: React.ReactElement;
  endIcon?: React.ReactElement;
};

const InputField: React.FC<IProps> = ({ name, value, onChange, loading = false, startIcon, className, endIcon, error, helperText, ...rest }) => {
  const classes = useStyles();

  const endAdornment = loading ? (
    <InputAdornment position="start">
      <CircularProgress color="secondary" size={20} />
    </InputAdornment>
  ) : (
    endIcon
  );

  return (
    <Box className={className} display="flex" flexDirection="column">
      <TextField
        className={classes.root}
        name={name}
        value={value}
        fullWidth={true}
        onChange={onChange}
        InputProps={{
          autoComplete: 'off',
          disableUnderline: true,
          startAdornment: startIcon ? <InputAdornment position="start">{startIcon}</InputAdornment> : undefined,
          endAdornment
        }}
        {...rest}
      />
      {error && (
        <Box paddingY={0.5}>
          <Typography variant="body1" color="error">
            <Box fontWeight="500">{helperText ? helperText : ''}</Box>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default InputField;
