import * as _ from 'lodash';

export const sortBy = (arr: [any]) => {
  for (let i = 0; i < arr.length - 1; i += 1) {
    for (let j = i + 1; j < arr.length; j += 1) {
      const temp = arr[i];

      // If next service starts with pickup
      const isNextTrue = _.chain(arr[j].service)
        .lowerCase()
        .replace(' ', '')
        .startsWith('pickup')
        .value();

      // If current servicee starts with pickup
      const isCurrentTrue = _.chain(arr[i].service)
        .lowerCase()
        .replace(' ', '')
        .startsWith('pickup')
        .value();

      if (isNextTrue && !isCurrentTrue) {
        arr[i] = arr[j];
        arr[j] = temp;
      }
    }
  }

  return arr;
};

export const convertBusinessHour = (unSortedBusinessHours: any) => {
  // If business hours are not defined return null.
  if (!unSortedBusinessHours) {
    return null;
  }

  const businessHours = sortBy(unSortedBusinessHours);

  const dayConverter: any = {
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thurs',
    5: 'Fri',
    6: 'Sat',
    7: 'Sun'
  };

  const result: any = {};
  const services = [];

  for (const businessHour of businessHours) {
    if (_.has(businessHour, 'service')) {
      const { service } = businessHour;
      services.push(service);
    }
  }

  for (const service of _.uniq(services)) {
    for (const businessHour of businessHours) {
      if (_.get(businessHour, 'service') === service) {
        if (!result[service]) result[service] = [];

        const daysOfWeek = businessHour.daysOfWeek.map((day: any) => dayConverter[day]);
        result[service].push({
          closeHour: businessHour.closeHour,
          closeMinute: businessHour.closeMinute,
          daysOfWeek,
          openHour: businessHour.openHour,
          openMinute: businessHour.openMinute
        });
      }
    }
  }

  return result;
};

export const getCurrentDayTimeInterval: any = (businessHours: any, currentDay: any) => {
  const _currentDay = currentDay.day() === 0 ? 7 : currentDay.day();
  const timings = [];
  if (businessHours) {
    for (const businessHour of businessHours) {
      const isPickUpTime = _.chain(businessHour.service)
        .lowerCase()
        .replace(' ', '')
        .startsWith('pickup')
        .value();

      if (isPickUpTime) {
        if (_.includes(businessHour.daysOfWeek, _currentDay)) {
          const { openHour, openMinute, closeHour, closeMinute } = businessHour;
          timings.push({
            openHour,
            openMinute,
            closeHour,
            closeMinute
          });
        }
      }
    }
  }

  return timings;
};

export const combineDaysOfWeek = (businessHours: any) => {
  let arr: any = [];
  let result: any = [];

  // remove rows with hours data not set
  arr = _.filter(_.sortBy(businessHours, 'openDay'), (obj) => {
    return obj.openHour !== undefined && obj.openMinute !== undefined && obj.closeHour !== undefined && obj.closeMinute !== undefined;
  });

  // group rows with same hours
  arr = _.groupBy(arr, (value) => {
    return value.openHour.toString() + value.openMinute.toString() + value.closeHour.toString() + value.closeMinute.toString();
  });

  for (const el in arr) {
    let count = 0;
    const daysofweek: any = [];
    arr[el].forEach((d: any) => {
      if (daysofweek[count] === undefined) daysofweek.push([d.openDay]);
      else {
        // maintains squence of days
        if (daysofweek[count][daysofweek[count].length - 1] === d.openDay - 1) daysofweek[count].push(d.openDay);
        else {
          // create new sequence if in between days different timing is there
          daysofweek.push([d.openDay]);
          count++;
        }
      }
    });

    // eslint-disable-next-line no-loop-func
    daysofweek.forEach((d: any) => {
      result.push({
        ...arr[el][0],
        openDay: d,
        closeDay: d
      });
    });

    result = _.sortBy(result, 'openDay[0]');
  }
  return result;
};
