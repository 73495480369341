import { useCallback } from 'react';
import { useStore } from 'store';

const useAlert = () => {
  const { dispatch } = useStore();

  const lokoAlert = useCallback((message: string) => {
    dispatch({ 
      type: 'SET_ALERT',
      payload: {
        isOpen: true,
        message
      }
    })
  }, [dispatch]);

  return {
    lokoAlert
  }
}

export default useAlert;
