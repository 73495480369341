import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0
  },
  boldText: {
    fontWeight: 'bolder'
  },
  lightText: {
    fontWeight: 400
  },
  priceItem: {
    padding: theme.spacing(0.5)
  },
  redBoldTextColor: {
    color: theme.palette.error.main,
    fontWeight: 'bolder'
  },
  priceStriked: {
    fontWeight: 'bolder',
    color: '#7e818c',
    textDecoration: "line-through"
  },
  priceDiscount: {
    backgroundColor: theme.palette.success.main,
    color: "white",
    padding: "0 4px",
    borderRadius: "2px",
    fontWeight: 500,
  }
}));
