import React from 'react';
import { Redirect } from 'react-router-dom';
import { useStore } from 'store';

const SecureCheckoutRoute: React.FC = ({ children }) => {
  const {
    state: { user }
  } = useStore();

  if (user && user.orderPhoneNumber !== '' && user.email !== '') {
    return <>{children}</>;
  }

  return <Redirect to="/" />;
};

export default SecureCheckoutRoute;
