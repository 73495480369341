import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    animation: {},
    sticky: {
      position: 'fixed',
      width: '100%',
      zIndex: 1
    },
    tabs: {
      '& .MuiTab-textColorInherit.Mui-selected .MuiTab-wrapper': {
        background: theme.palette.primary.main,
        borderRadius: '8px',
        fontSize: '0.9rem',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
      },
      '& .MuiTouchRipple-root': {
        display: 'none'
      }
    }
  })
);
