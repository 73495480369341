import { isValidPhoneNumber } from 'react-phone-number-input';
import * as Yup from 'yup';

const validate = (values: any) => {
  const errors: any = {};

  if (!values.phonenumber) {
    errors.phonenumber = 'Required';
  } else if (!isValidPhoneNumber(values.phonenumber)) {
    errors.phonenumber = 'Enter valid phone number';
  }
  const validateEmail = Yup.string()
    .email('Invalid email')
    .min(2, 'Too short!')
    .max(100, 'Too long!');

  const emailCheck = validateEmail.isValidSync(values.email);

  if (!emailCheck) errors.email = 'Invalid Email';

  if (!values.email || values.email.trim() === '') errors.email = 'Required';

  return errors;
};

export default validate;
