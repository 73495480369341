import React, { useState, useEffect } from 'react';
import { IPickerData } from './types';
import { filter, map } from 'lodash';
import moment, { Moment } from 'moment';
import { MenuItem, Box, InputLabel, TextField } from '@material-ui/core';
import { useStyles } from './style';
import { useStore } from 'store';

interface IProps {
  pickerData: IPickerData[];
  setPickupInfo: (pickupInfo: number) => void;
  label: string;
  size?: 'small' | 'medium';
  orgType: 'RESTAURANT' | 'GROCERY';
  selectedPickerDate?: Moment | null;
  selectedPickerTime?: Moment | null;
}

const Picker = ({ pickerData, setPickupInfo, label, size, orgType, selectedPickerTime, selectedPickerDate }: IProps) => {
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = useState(selectedPickerDate ? selectedPickerDate : pickerData[0].day);

  const selectedDaySlot = selectedPickerDate ? filter(pickerData, ({ day }) => day.toString() === selectedPickerDate?.toString())[0] : null;

  const [timeSlots, setTimeSlots] = useState(selectedDaySlot ? selectedDaySlot.availableSlots : pickerData[0].availableSlots);

  const [selectedTime, setSelectedTime] = useState(selectedPickerTime ? selectedPickerTime : timeSlots[0]);

  const { dispatch } = useStore();

  const setPickerDateInStore = (day: Moment) => {
    if (orgType === 'RESTAURANT') {
      dispatch({
        type: 'CART_ACTION_SET_PICKER_DATE',
        payload: day
      });
    }

    if (orgType === 'GROCERY') {
      dispatch({
        type: 'GROCERY_CART_ACTION_SET_PICKER_DATE',
        payload: day
      });
    }
  };

  const setPickerTimeInStore = (time: Moment) => {
    if (orgType === 'RESTAURANT') {
      dispatch({
        type: 'CART_ACTION_SET_PICKER_TIME',
        payload: time
      });
    }

    if (orgType === 'GROCERY') {
      dispatch({
        type: 'GROCERY_CART_ACTION_SET_PICKER_TIME',
        payload: time
      });
    }
  };

  const onDateChange = (e: any) => {
    const { value } = e.target;

    const selectedOption: IPickerData = filter(pickerData, ({ day }) => day.toString() === value)[0];
    if (selectedOption) {
      const { day, availableSlots } = selectedOption;
      setTimeSlots(availableSlots);
      setSelectedDate(day);
      setSelectedTime(availableSlots[0]);
      setPickupInfo(availableSlots[0].valueOf());

      setPickerDateInStore(day);
      setPickerTimeInStore(availableSlots[0]);
    }
  };

  const onTimeChange = (e: any) => {
    setSelectedTime(moment(e.target.value));
    setPickerTimeInStore(moment(e.target.value));
  };

  useEffect(() => {
    // setSelectedDate(pickerData[0].day);
    // setTimeSlots(pickerData[0].availableSlots);
    // setSelectedTime(pickerData[0].availableSlots[0]);
  }, [pickerData]);

  useEffect(() => {
    // setPickupInfo({
    //   day: selectedDate.valueOf(),
    //   time: selectedTime.valueOf()
    // });

    if (orgType === 'RESTAURANT') {
      dispatch({
        type: 'CART_ACTION_SET_DELIVERY_TIME',
        payload: selectedTime.valueOf()
      });
    }

    if (orgType === 'GROCERY') {
      dispatch({
        type: 'GROCERY_CART_ACTION_SET_DELIVERY_TIME',
        payload: selectedTime.valueOf()
      });
    }
  }, [dispatch, orgType, selectedTime]);

  return (
    <Box>
      <Box paddingX={1}>
        <InputLabel>{label}</InputLabel>
      </Box>
      <Box display="flex">
        <TextField size={size} select={true} variant="outlined" value={selectedDate} onChange={onDateChange} className={classes.dropDown}>
          {map(pickerData, ({ day }, index) => {
            return (
              <MenuItem key={index} value={day.toString()} className={classes.menuItem}>
                {day.format('DD MMM dddd')}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField size={size} select={true} variant="outlined" value={selectedTime} onChange={onTimeChange} className={classes.dropDown}>
          {map(timeSlots, (slot, index) => {
            return (
              <MenuItem key={index} value={slot.toString()} className={classes.menuItem}>
                {slot.format('LT')}
              </MenuItem>
            );
          })}
        </TextField>
      </Box>
    </Box>
  );
};

export default Picker;
