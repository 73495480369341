import { IDiscount } from 'generated/custom';
import { Dish, Restaurant, DishCategory, DishType } from 'generated/types';
import { convertPrice, applyDiscount, convertPriceTo } from 'util/number';
import { map, max } from 'lodash';

interface IMenuPrice {
  price: string;
  strikedPrice?: string;
  maxDiscountPercent?: string;
  discountPercent?: string;
}

class DishC implements Dish {
  __typename?: 'Dish' | undefined;

  restaurant!: Restaurant;

  category!: DishCategory;

  type?: import('../generated/types').DishType | null | undefined;

  pizzaBasePrice?: Array<import('../generated/types').PizzaBasePrice> | null | undefined;

  pizzaCrust?: Array<import('../generated/types').PizzaCrust> | null | undefined;

  pizzaCheese?: import('../generated/types').PizzaToppingGroup | null | undefined;

  pizzaSauce?: import('../generated/types').PizzaToppingGroup | null | undefined;

  pizzaToppingGroups?: Array<import('../generated/types').PizzaToppingGroup> | null | undefined;
  /**
   * Represents Dish Unique id.
   */
  id!: string;

  /**
   * Date at which dish has been created.
   */
  createdAt: any;

  /**
   * Last updated time.
   */
  updatedAt: any;

  /**
   * Restaurant Object of dish.
   */
  // restaurant?: import('../generated/types').Restaurant;

  /**
   * Category of dish.
   */

  title: string | null | undefined;

  description?: string | null | undefined;

  price: Array<import('../generated/types').DishPrice> | null | undefined;

  extraGroups?: Array<import('../generated/types').DishExtraGroup> | null | undefined;

  images?: Array<import('../generated/types').MultiSizeImage> | null | undefined;

  tags?: Array<import('../generated/types').Maybe<import('../generated/types').DishTag>> | null | undefined;

  // category?: import('../generated/types').DishCategory | undefined;

  private readonly _status?: import('../generated/types').DishStatus | null | undefined;

  isReward: any;

  rewardPoints: any;

  isAlcohol: boolean = false;

  isDiscounted: boolean = false;

  rateScore: number = 0;

  rateCount: number = 0;

  public get status(): import('../generated/types').DishStatus | null | undefined {
    return this._status;
  }

  get getTitle() {
    return this.title || '';
  }

  get getCategory() {
    return (this.category && this.category.title && this.category.title) || '';
  }

  get getCategoryId() {
    const id = (this.category && this.category.id) || '';

    return id;
  }

  get getDescription() {
    return this.description || '';
  }

  get getPrice() {
    if (this.type === DishType.Pizza) {
      const { intValue, shift } = (this.pizzaBasePrice && this.pizzaBasePrice[0].price) || { intValue: 0, shift: 0 };

      return convertPrice(intValue, shift);
    }

    if (this.price && this.price[0]) {
      const { intValue, shift } = this.price[0].price;

      return convertPrice(intValue, shift);
    }

    return '';
  }

  get getMenuPrice() {
    let priceString,
      discountAmount,
      maxDiscount = null;

    let discounts: number[] = [];

    if (this.type === DishType.Pizza) {
      if (this.pizzaBasePrice && this.pizzaBasePrice[0].price) {
        const { intValue, shift } = this.pizzaBasePrice && this.pizzaBasePrice[0].price;

        priceString = convertPrice(intValue, shift);

        if (this.isDiscounted && this.pizzaBasePrice[0].discountAmount) {
          discountAmount = convertPriceTo(this.pizzaBasePrice[0].discountAmount, 'DOLLAR');
        }
      }
    }

    if (this.type === DishType.Regular) {
      if (this.price && this.price[0]) {
        const { intValue, shift } = this.price[0].price;

        priceString = convertPrice(intValue, shift);

        if (this.isDiscounted && this.price[0].discountAmount) {
          discountAmount = convertPriceTo(this.price[0].discountAmount, 'DOLLAR');
        }
      }
    }

    const menuPrice: any = {
      price: priceString
    };

    if (this.isDiscounted) {
      discounts = this.getDiscounts;

      maxDiscount = max(discounts);

      menuPrice.maxDiscountPercent = maxDiscount;
    }

    if (discountAmount && priceString) {
      const discount: IDiscount = applyDiscount(priceString, discountAmount);

      menuPrice.price = discount.price;

      menuPrice.strikedPrice = priceString;

      menuPrice.maxDiscountPercent = maxDiscount;

      menuPrice.discountPercent = discounts[0];
    }

    return menuPrice;
  }

  get getDiscounts() {
    let prices: any = this.type === DishType.Pizza ? this.pizzaBasePrice : this.price;

    const discounts = map(prices, ({ price, discountAmount }) => {
      const { intValue, shift } = price;

      const priceString = convertPrice(intValue, shift);

      if (discountAmount) {
        const discount: IDiscount = applyDiscount(priceString, convertPriceTo(discountAmount, 'DOLLAR'));

        return Number(discount.percentage);
      }

      return 0;
    });

    return discounts;
  }

  get getDiscount() {
    if (this.type === DishType.Pizza) {
      const { intValue, shift } = (this.pizzaBasePrice && this.pizzaBasePrice[0].price) || { intValue: 0, shift: 0 };

      return convertPrice(intValue, shift);
    }

    if (this.price && this.price[0]) {
      const { intValue, shift } = this.price[0].price;

      const price = convertPrice(intValue, shift);

      if (this.isDiscounted) {
        const discountAmount = `${this.price[0].discountAmount}`;

        if (discountAmount) {
          const discount: IDiscount = applyDiscount(price, convertPriceTo(discountAmount, 'DOLLAR'));

          return discount;
        }

        return null;
      }

      return price;
    }

    return '';
  }

  get getTags() {
    if (this.tags && this.tags.length) {
      return this.tags;
    }

    return [];
  }
}

export default DishC;
