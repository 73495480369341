import React, { useContext, useCallback } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { AuthContext } from 'fbase/authContext';
import { useRestaurantAuthentication, useReward } from 'hooks/restaurant';
import { useStyles } from './style';
import { useGetRestaurantById, useGetRewardDishes } from 'graphql/hooks';
import Menu from './Menu';
import { useStore } from 'store';
import NotFound from 'components/NotFound';
import { isNull } from 'lodash';

const Rewards: React.FC = () => {
  const classes = useStyles();

  const history = useHistory();

  const { restaurantId } = useParams<any>();

  const { state } = useLocation<{ from: string | undefined }>();

  const { dispatch } = useStore();

  const { currentUser, isAuthStateChanged } = useContext(AuthContext);

  useRestaurantAuthentication();

  const { rewardPointLoading, pointsCutoff, totalPoints, redeemablePoints, usedPoints, isRedeemAllowed, errorMessage, isMinimumPointsSatisfied } = useReward();

  const { rewardItems, loading } = useGetRewardDishes(restaurantId);

  const { restaurant, loading: configLoading } = useGetRestaurantById(restaurantId, 'REWARD_SETTING');

  const renderError = (message: string) => {
    return (
      <Box padding={1} textAlign="center">
        <Typography color="error" variant="body1">
          {message}
        </Typography>
      </Box>
    );
  };

  const getPointsRow = (label: string, point: number) => {
    return (
      <Box display="flex" justifyContent="space-between">
        <Typography variant="subtitle1">{label}</Typography>
        <Typography className={classes.bold} variant="subtitle1">
          {point} PTS
        </Typography>
      </Box>
    );
  };

  const handleLoginClick = () => {
    dispatch({
      type: 'UI_ACTION_LOGIN_POPUP',
      payload: true
    });
  };

  const onDoneHandler = useCallback(() => {
    if (history.length > 1) {
      if (state && state.from) {
        history.replace(state.from);
      } else {
        history.replace('/');
      }
    } else {
      history.push('/');
    }
  }, [history, state]);

  if (rewardPointLoading) {
    return null;
  }

  const render = (): React.ReactNode => {
    if (!isAuthStateChanged) {
      return '';
    }

    // In case user is not logged in
    if (!currentUser || currentUser.isAnonymous) {
      return (
        <Box textAlign="center" p={1}>
          <Typography color="secondary" variant="body1">
            <u className={classes.loginBtn} onClick={handleLoginClick}>
              Login
            </u>
            &nbsp;to start redeeming points
          </Typography>
        </Box>
      );
    }

    return (
      <Box className={classes.pointsBanner}>
        {getPointsRow('Total Points', totalPoints)}
        {isMinimumPointsSatisfied && redeemablePoints > 0 && getPointsRow('Redeemable Points', redeemablePoints)}
        {usedPoints > 0 && totalPoints > 0 && getPointsRow('Used Points', usedPoints)}
        {pointsCutoff && redeemablePoints > 0 && (
          <Box paddingTop={1} fontStyle="italic">
            <Typography className={classes.bold} variant="caption">
              Max {redeemablePoints} PTS can be redeemed in this purchase
            </Typography>
          </Box>
        )}
        {isRedeemAllowed && (
          <Box paddingTop={1} fontStyle="italic">
            <Typography className={classes.bold} variant="caption">
              Please choose items from the list below.
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  const notAllowed = !restaurant?.issueRewardPoint || isNull(restaurant?.rewardSetting) || !rewardItems?.length;

  if (!loading && !configLoading && notAllowed) {
    return <NotFound />;
  }

  return (
    <Box maxWidth={1200} margin="auto" display="flex" flexDirection="column" bgcolor="#fff" height="100%">
      {render()}
      {!isRedeemAllowed && renderError(errorMessage)}
      <Box style={{ overflow: 'scroll', flex: 1 }}>
        <Menu dishes={rewardItems} loading={loading} />
      </Box>
      <Box margin={2}>
        <Button variant="contained" disableElevation={true} color="primary" fullWidth={true} onClick={onDoneHandler}>
          Done
        </Button>
      </Box>
    </Box>
  );
};

export default Rewards;
