import { getRestaurantTables, getTableServicesQuery } from 'graphql/query';
import { useState, useEffect } from 'react';
import { useQueryWithLoader } from 'hooks/loader';
import { useQuery } from '@apollo/react-hooks';

export const useTablesQuery = ({ restaurantId }: { restaurantId: string | undefined }) => {
  const [tables, setTables] = useState<any>(null);

  const { loading, data, error } = useQueryWithLoader(getRestaurantTables, {
    variables: {
      input: {
        id: restaurantId
      }
    },
    skip: !restaurantId
  });

  useEffect(() => {
    if (data) {
      const { tables: tableData } = data.getRestaurant;

      setTables(tableData);
    }
  }, [data, error, loading]);

  return {
    loading,
    tables,
    error
  };
};

export const useTablesServicesQuery = ({ restaurantId }: { restaurantId: string | undefined }) => {
  const [tableServices, setTableServices] = useState<any>(null);

  const { loading, data, error } = useQuery<any, any>(getTableServicesQuery, {
    skip: !restaurantId,
    variables: {
      input: {
        bizId: restaurantId
      }
    }
  });

  useEffect(() => {
    if (data && data.getTableServices) {
      setTableServices(data.getTableServices.dishes);
    }
  }, [data, error, loading]);

  return {
    loading,
    tableServices,
    error
  };
};
