import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  main: {
    fontSize: '10px'
  },
  dishImg: {
    transition: 'margin 0.3s ease-in-out'
  },
  dishTitle: {
    color: '#fff'
  },
  dishTitleWhite: {
    overflow: 'hidden',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: '0px',
    margin: 0
  },
  dishDescription: {
    color: '#fff',
    marginBottom: '0px'
  },
  dishHeader: {
    height: '200px',
    display: 'flex',
    flexDirection: 'column',
    backgroundSize: 'cover !important',
    justifyContent: 'flex-end',
    boxShadow: '0 -62px 66px -6px black inset',
    padding: theme.spacing(2),
    transition: 'all 0.8s ease',
    overflow: 'hidden'
  },
  dishContainer: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    overflow: 'auto',
    backgroundColor: '#fff'
  },
  dishHeaderShrinked: {
    padding: theme.spacing(1, 2),
    transition: 'all 0.8s ease',
    overflow: 'hidden'
  },
  dishSmallIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px',
    width: '60px',
    backgroundSize: 'cover',
    borderRadius: '5px',
    backgroundColor: '#fff'
  },
  smallIcon: {
    width: '24px'
  },
  alignTitle: {
    display: 'flex',
    // paddingLeft:theme.spacing(2),
    alignItems: 'center'
  },
  ratings: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '0.5rem',
    fontWeight: 400
  },
  rateScore: {
    fontWeight: 700,
    color: '#fff'
  },
  paddingL: {
    paddingLeft: theme.spacing(2)
  },
  colorBlack: {
    color: '#000'
  }
}));
