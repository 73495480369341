import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dishListWrapper: {
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 0.5, 0, 0.5)
    }
  }
}));
