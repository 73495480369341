import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    cursor: 'pointer',
    borderRadius: theme.spacing(2)
  },
  image: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    overflow:'hidden',
    top: '0',
    left: '0',
    borderRadius: theme.spacing(1)
  }
  
}));
