import gql from 'graphql-tag';

export const Profile = gql`
  fragment Profile on Restaurant {
    name
    customDomainUrl
    subName
    logo {
      id
      url
    }
    virtualPhone
    phone
    address {
      text
      street
      city
      state
      country
      zipcode
      lat
      lng
    }
    description
    taxRate {
      shift
      intValue
    }
    i18n {
      timezone
    }
    yelpWriteReviewLink
    googleWriteReviewLink
  }
`;

export const BusinessHours = gql`
  fragment BusinessHours on Restaurant {
    bizHours {
      serviceType
      serviceName
      hours {
        openDay
        openHour
        openMinute
        closeHour
        closeMinute
        closeDay
      }
    }
  }
`;

export const Table = gql`
  fragment Table on Restaurant {
    tables {
      tableName
      seats
    }
  }
`;

export const Announcement = gql`
  fragment Announcement on Restaurant {
    announcement
  }
`;

export const RewardSetting = gql`
  fragment RewardSetting on Restaurant {
    issueRewardPoint
    rewardSetting {
      minStartPoints
      maxRedeemPointsPerUsage
      minRedeemPurchase
      pricePointRatio
    }
  }
`;

export const Gallery = gql`
  fragment Gallery on Restaurant {
    images {
      md {
        id
        url
      }
    }
  }
`;

// export const Coupon = gql`
//   fragment Coupon on Restaurant {
//     coupons {
//       code
//       name
//       subtotalThreshold
//       orderType
//       dayOfWeek
//       discountType
//       discountValue
//       maxUse
//       useCnt
//       startDate
//       isSingleUse
//       endDate
//       description
//       maxDiscount
//     }
//   }
// `;

export const BizPlan = gql`
  fragment BizPlan on Restaurant {
    bizPlan {
      planId
      bizPlanDetails {
        DINING {
          custPctVal
          custPctValLower
          custAbsVal
          bizPctVal
          bizPctValLower
          bizAbsVal
        }
        TAKEOUT {
          custPctVal
          custPctValLower
          custAbsVal
          bizPctVal
          bizPctValLower
          bizAbsVal
        }
        DELIVERY {
          SELF {
            custPctVal
            custPctValLower
            custAbsVal
            bizPctVal
            bizPctValLower
            bizAbsVal
          }
          DOORDASH_CLASSIC {
            custPctVal
            custPctValLower
            custAbsVal
            bizPctVal
            bizPctValLower
            bizAbsVal
          }
        }
      }
      planName
    }
  }
`;
