import { useContext } from 'react';
import { FirebaseContext } from 'fbase';
import { useStore } from 'store';
import { Auth } from 'aws-amplify';

const useUserLogout = () => {
  const { dispatch } = useStore();

  const { firebase } = useContext(FirebaseContext);

  const logOut = async () => {
    await firebase?.signOut();
    await Auth.signOut();
    dispatch({
      type: 'USER_ACTION_CLEAR_CONTACT_DETAILS'
    });
  };

  return {
    logOut
  };
};

export default useUserLogout;
