import * as Yup from 'yup';

export default Yup.object().shape({
  restaurantRating: Yup.number()
    .max(5, 'Invalid rating')
    .min(0, 'Invalid rating'),
  restaurantReview: Yup.string().test({
    name: 'restaurantRating test',
    message: 'Your valuable feedback is required, so that we can improve, thanks in advance!',
    test: function(review: string) {
      if (this.parent.restaurantRating <= 3 && this.parent.restaurantRating !== 0 && !review) {
        return false;
      }

      return true;
    }
  }),
  items: Yup.array().of(
    Yup.object().shape({
      itemId: Yup.string(),
      itemName: Yup.string(),
      rating: Yup.number()
        .max(5, 'Invalid rating')
        .min(0, 'Invalid rating')
    })
  )
});
