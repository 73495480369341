import React, { useContext, useState, useRef, useCallback, ChangeEvent, useEffect } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useStyles } from './style';
import { FirebaseContext } from 'fbase';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { useFormik, getIn } from 'formik';
import Verification from './Verification';
import { AuthContext } from 'fbase/authContext';
import Loading from '../Login/Loading';
import validationSchema from './validations';
import { useAlert } from 'hooks';

const PhoneVerification: React.FC = () => {
  const classes = useStyles();

  const { lokoAlert } = useAlert();

  const { firebase } = useContext(FirebaseContext);

  const { currentUser } = useContext(AuthContext);

  const auth = firebase!.getAuth();

  const recapchaEl = useRef(null);

  const [recaptchVerifier, setRepactchVerifier] = useState<any>(null);

  const [isRecaptchaResolved, setRecaptchaResolved] = useState<boolean>(false);

  const [verificationId, setVerificationId] = useState('');

  const [{ loading, done }, setProgress] = useState({ loading: false, done: false });

  const [screen, setScreen] = useState<number>(1);

  const createRecaptcha = useCallback(() => {
    setRecaptchaResolved(false);

    if (recapchaEl && recapchaEl.current) {
      try {
        const verifier = new auth.RecaptchaVerifier(recapchaEl.current, {
          size: 'normal',
          callback: (response: any) => {
            if (response) {
              setRecaptchaResolved(true);
            }
          },
          'expired-callback': () => {
            setRecaptchaResolved(false);
          }
        });

        verifier.render().catch((e) => console.log('RECAPTCHA ERROR'));

        setRepactchVerifier(verifier);
      } catch (e) {
        console.log(e);
      }
    }
  }, [auth.RecaptchaVerifier]);

  const formik = useFormik({
    initialValues: {
      phone: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      setProgress((prev) => ({ ...prev, loading: true }));

      const { phone } = values;

      try {
        /**
         * Checks if user is anonymous. Will always be true anway.
         *
         */
        if (currentUser) {
          /**
           * If email already exists link it with credentials and update user profile.
           */
          if (recaptchVerifier) {
            const phoneProvider = new auth.PhoneAuthProvider();

            const result = await phoneProvider.verifyPhoneNumber(phone, recaptchVerifier);

            setVerificationId(result);

            setScreen(2);
          } else {
            console.log('RECATCH REFERENCE NOT AVAILABLE');
            throw new Error();
          }
        }

        setProgress((prev) => ({ ...prev, loading: false, done: true }));
      } catch (e) {
        setProgress((prev) => ({ ...prev, loading: false }));

        recaptchVerifier?.clear();

        createRecaptcha();

        let message = '';

        if (e.code) {
          switch (e.code) {
            case 'auth/captcha-check-failed':
              message = 'Recapcha check failed. Please try again';
              break;
            case 'auth/invalid-phone-number':
              message = 'Phone number not valid';
              break;
            case 'auth/missing-phone-number':
              message = 'Phone number is mssing';
              break;
            case 'auth/user-disabled':
              message = 'User has been disabled';
              break;
            case 'auth/maximum-second-factor-count-exceeded':
              message = 'Maximum second factor exceeded';
              break;
            case 'auth/second-factor-already-in-use':
              message = 'Second factor already in use';
              break;
            case 'auth/unverified-email':
              message = 'Email is not verified';
              break;
            default:
              message = e.message;
              console.log(message);
          }
        }

        lokoAlert(e.message);
      }
    }
  });

  const recapchaElCurrent = recapchaEl.current;

  useEffect(() => {
    createRecaptcha();
  }, [createRecaptcha, recapchaElCurrent]);

  useEffect(() => {
    return () => {
      recaptchVerifier?.clear();
    };
  }, [recaptchVerifier]);

  if (screen === 1) {
    const touched = !!getIn(formik.touched, 'phone');

    const invalidPhoneNumber = touched && !isValidPhoneNumber(formik.values.phone);

    const error = touched && !!getIn(formik.errors, 'phone');

    const errorMsg = invalidPhoneNumber ? 'Invalid Phone number' : getIn(formik.errors, 'phone');

    const isSubmitDisabled = !isRecaptchaResolved || error || invalidPhoneNumber;

    return (
      <form className={classes.root} onSubmit={formik.handleSubmit}>
        <Loading open={loading} done={done} />
        <Box width="100%">
          <Typography variant="body1" paragraph={true}>
            Please enter your Cell Phone & Validate it using OTP sent
          </Typography>
          <Typography variant="body2" paragraph={true}>
            This is required for online order status update using text
          </Typography>
          <PhoneInput
            className={classes.phoneInputField}
            name="phone"
            country="US"
            value={formik.values.phone}
            onBlur={formik.handleBlur('phone')}
            onChange={(val: string) => formik.handleChange({ target: { value: val, name: 'phone' } } as ChangeEvent<HTMLInputElement>)}
          />
          {(error || invalidPhoneNumber) && (
            <Box paddingX={1} marginBottom={1}>
              <Typography variant="body1" color="error">
                <Box fontWeight="500">{errorMsg}</Box>
              </Typography>
            </Box>
          )}
          <Box marginBottom={1}>
            <div ref={recapchaEl} />
          </Box>
          <Box>
            <Button size="large" type="submit" variant="contained" color="primary" fullWidth={true} disabled={isSubmitDisabled} disableElevation={true}>
              Submit
            </Button>
          </Box>
        </Box>
      </form>
    );
  }

  if (screen === 2) {
    return <Verification verificationId={verificationId} setStep={setScreen} />;
  }

  return null;
};

export default PhoneVerification;
