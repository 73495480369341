import * as Yup from 'yup';

export default Yup.object().shape({
  currentPassword: Yup.string()
    .min(6, 'Password should contains at least 6 characters')
    .max(100, 'Too long!')
    .required('Please enter password'),
  newPassword: Yup.string()
    .min(6, 'Password should contains at least 6 characters')
    .max(100, 'Too long!')
    .required('Please enter password'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Password do not match')
    .required('Please enter password')
});
