import React from 'react';
import { useStore } from 'store';
import { Redirect } from 'react-router-dom';
import PaymentRoutes from './routes';

const PaymentPage: React.FC = () => {
  const { state } = useStore();

  if (state.cartItems.length === 0 && state.cartRewardItems.length === 0) {
    return <Redirect to="/" />;
  }

  return <PaymentRoutes />;
};

export default PaymentPage;
