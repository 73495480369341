import React, { useEffect, useState } from 'react';
import { IBizHour, IPickerData } from './types';
import { filter } from 'lodash';
import Picker from './Picker';
import { getTimeData } from './utils';
import { Typography, Box } from '@material-ui/core';
import { useStyles } from './style';
import { Moment } from 'moment';

type ORDER_TYPE = 'TAKEOUT' | 'DELIVERY';

interface IProps {
  businessHours: IBizHour[];
  onChange: (pickupInfo: number) => void;
  waitingTime?: number | null | undefined;
  label?: string;
  orderType: ORDER_TYPE;
  size?: 'small' | 'medium';
  orgType: 'RESTAURANT' | 'GROCERY';
  selectedPickerDate?: Moment | null;
  selectedPickerTime?: Moment | null;
}

const DateTimePicker = ({ businessHours, onChange, waitingTime, label = 'Time picker', orderType, size, orgType, selectedPickerTime, selectedPickerDate }: IProps) => {
  const classes = useStyles();

  const service = orderType === 'TAKEOUT' ? (orgType === 'RESTAURANT' ? 'RESTAURANT_PICK_UP' : 'GROCERY_TAKEOUT') : orgType === 'RESTAURANT' ? 'RESTAURANT_DELIVERY' : 'GROCERY_DELIVERY';

  const _businessHours = filter(businessHours, (hour) => hour.serviceType === service);

  const [pickerData, setPickerData] = useState<IPickerData[]>(getTimeData(_businessHours, waitingTime));

  useEffect(() => {
    const interval = setInterval(() => {
      setPickerData(getTimeData(_businessHours, waitingTime));
    }, 300000);

    return () => {
      clearInterval(interval);
    };
  }, [_businessHours, waitingTime]);

  if (_businessHours.length === 0) {
    return (
      <Box padding={1}>
        <Typography variant="body1" className={classes.errorText}>
          Business hours not available for {orderType === 'TAKEOUT' ? 'takeout' : 'delivery'}
        </Typography>
      </Box>
    );
  }

  if (pickerData.length > 0) {
    return <Picker orgType={orgType} setPickupInfo={onChange} pickerData={pickerData} label={label} size={size} selectedPickerTime={selectedPickerTime} selectedPickerDate={selectedPickerDate} />;
  }

  return null;
};

export default DateTimePicker;
