import React from 'react';
import { Route, Switch } from 'react-router-dom';
import useCart from 'hooks/useCart';
import { useReward } from 'hooks/restaurant';
import MainScreen from './MainScreen';
import OrderType from './OrderType';

const MyCart = () => {
  const {
    cartItems,
    cartRewardItems,
    handleClose,
    subtotal,
    subtotalToDiscount,
    subtotalWithoutPrediscount,
    orderType,
    setOrderType,
    restaurant,
    deliveryTime,
    setDeliveryTime,
    deliveryAddress,
    tableNumber,
    numberOfPeople,
    deliveryNote,
    deliveryProvider,
    deliveryMode,
    setDeliveryNote,
    setDeliveryAddress,
    setDeliveryMiles,
    setDeliveryEstimate,
    orderNote,
    setOrderNote,
    setCouponData,
    isDiscountApplied,
    setTipAmount,
    deliveryCharge,
    couponData,
    discount,
    tax,
    totalPriceAfterTax,
    serviceFee,
    setServiceFee,
    deliveryMiles,
    subtotalAfterDiscount,
    togglePrediscount,
    isCartHavingPrediscountedItem,
    isPrediscountEnabled,
    dropOffType,
    apartmentNumber,
    setApartmentNumber,
    setDropOffType,
    boonsQuoteId,
    setDoordashDeliveryEstimate,
    doordashQuoteId,
    doordashCharge
  } = useCart();

  const { usedPoints } = useReward();

  return (
    <Switch>
      <Route exact={true} path="/cart">
        <MainScreen
          cartItems={cartItems}
          cartRewardItems={cartRewardItems}
          handleClose={handleClose}
          subtotal={subtotal}
          orderNote={orderNote}
          setOrderNote={setOrderNote}
          togglePrediscount={togglePrediscount}
          setDeliveryTime={setDeliveryTime}
        />
      </Route>
      <Route path="/cart/select-type">
        <OrderType
          serviceFee={serviceFee}
          deliveryMiles={deliveryMiles?.toFixed(2)}
          tax={tax}
          couponData={couponData}
          setServiceFee={setServiceFee}
          discount={discount}
          deliveryCharge={deliveryCharge?.toFixed(2)}
          doordashCharge={doordashCharge?.toFixed(2)}
          setTipAmount={setTipAmount}
          setOrderType={setOrderType}
          restaurant={restaurant}
          orderType={orderType}
          setDeliveryTime={setDeliveryTime}
          numberOfPeople={numberOfPeople}
          tableNumber={tableNumber}
          subtotal={subtotal}
          subtotalToDiscount={subtotalToDiscount}
          subtotalWithoutPrediscount={subtotalWithoutPrediscount}
          deliveryAddress={deliveryAddress}
          deliveryTime={deliveryTime}
          deliveryNote={deliveryNote}
          deliveryProvider={deliveryProvider}
          deliveryMode={deliveryMode}
          setDeliveryNote={setDeliveryNote}
          setDeliveryAddress={setDeliveryAddress}
          setDeliveryMiles={setDeliveryMiles}
          setDeliveryEstimate={setDeliveryEstimate}
          setCouponData={setCouponData}
          isDiscountApplied={isDiscountApplied}
          totalPriceAfterTax={totalPriceAfterTax}
          subtotalAfterDiscount={subtotalAfterDiscount}
          usedPoints={usedPoints}
          enablePromocode={!!cartItems.length}
          togglePrediscount={togglePrediscount}
          isCartHavingPrediscountedItem={isCartHavingPrediscountedItem}
          isPrediscountEnabled={isPrediscountEnabled}
          setDropOffType={setDropOffType}
          dropOffType={dropOffType}
          apartmentNumber={apartmentNumber}
          setApartmentNumber={setApartmentNumber}
          boonsQuoteId={boonsQuoteId}
          setDoordashDeliveryEstimate={setDoordashDeliveryEstimate}
          doordashQuoteId={doordashQuoteId}
        />
      </Route>
    </Switch>
  );
};

export default MyCart;
