import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PayOnline from './PayByCardUI';
import { useStore } from 'store';
import { Redirect } from 'react-router-dom';
import { Typography, Box } from '@material-ui/core';
import ErrorBoundary from 'components/ErrorBoundary';

interface IProps {
  isSubmitted: boolean;
}

const PaymentPage: React.FC<IProps> = ({ isSubmitted }) => {
  const { state } = useStore();

  const [stripeObject, setStripeObject] = useState<any>(null);

  const stripeAccount = state.cartItems[0]?.restaurant?.stripeAccount;

  // This function will re-run if the accountId prop changes.
  useEffect(() => {
    const fetchStripeObject = async () => {
      // If there is no accountId, do not run the loadStripe function.
      if (stripeAccount) {
        const res = await loadStripe(process.env.REACT_APP_STRIPE_KEY!, {
          stripeAccount: stripeAccount
        });
        // When we have got the Stripe object, pass it into our useState.
        setStripeObject(res);
      }
    };
    fetchStripeObject();
  }, [stripeAccount]);

  if (state.cartItems.length === 0) {
    return <Redirect to="/" />;
  }

  if (stripeAccount) {
    return (
      <ErrorBoundary>
        <Elements stripe={stripeObject} key={stripeAccount}>
          <PayOnline isSubmitted={isSubmitted} />
        </Elements>
      </ErrorBoundary>
    );
  }

  return (
    <Box textAlign="center">
      <Typography variant="body1" color="error">
        Online Payment Not Available
      </Typography>
    </Box>
  );
};

export default PaymentPage;
