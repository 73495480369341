import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Typography, Box, useMediaQuery, Button } from '@material-ui/core';
import InfoAndHours from './InfoAndHours';
import { AuthContext } from 'fbase/authContext';
import { Restaurant } from 'models';
import TableService from '../TableService';
import { useStyles } from './style';
import { useGetUserRewardsByRestaurant } from 'graphql/hooks/user.hooks';
import { isNull } from 'lodash';
import { useGetRewardDishes } from 'graphql/hooks';
import Loader from 'assets/img/dot-loader.svg';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Deals from './Deals';

interface IProps {
  restaurant: Restaurant;
}

const RestaurantInfo: React.FC<IProps> = ({ restaurant }: IProps) => {
  const classes = useStyles();

  const history = useHistory();

  const { pathname } = useLocation();

  const { currentUser } = useContext(AuthContext);

  const restaurantId = restaurant.getId;

  const location = restaurant.getLatLng;

  const phone = restaurant.getPhone;

  const virtualPhone = restaurant.virtualPhone;

  const { data, loading: rewardPointsLoading } = useGetUserRewardsByRestaurant(restaurantId);

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  const isRewardAvailable = restaurant.issueRewardPoint;

  const [rewardPoints, setRewardPoints] = useState<null | number>(null);

  const { count: rewardItemCount } = useGetRewardDishes(restaurantId);

  useEffect(() => {
    if (data && data.getRewardPointsForConsumer) {
      const activePoints = data.getRewardPointsForConsumer[0] ? data.getRewardPointsForConsumer[0].activePoints : 0;
      setRewardPoints(activePoints);
    }
  }, [data]);

  const gotoRewards = () => {
    // If restaurant has reward dishes then redirect to rewards page else return
    if (rewardItemCount === 0 || isNull(restaurant.rewardSetting)) {
      return;
    }

    history.push(`${restaurant?.id}/rewards`, {
      from: pathname
    });
  };

  const getRewardPointsText = () => {
    if (currentUser && !currentUser.isAnonymous) {
      if (rewardPointsLoading || isNull(rewardPoints)) {
        return <img src={Loader} alt="" />;
      } else {
        return ` ${rewardPoints} PTS`;
      }
    }

    return '';
  };
  return isDesktop ? (
    <Box display="flex" alignItems="center" justifyContent="space-evenly">
      {isRewardAvailable && (
        <Button size="small" className={classes.rewardBtn} onClick={gotoRewards} variant="contained" fullWidth={false} color="primary" disableElevation>
          <Box display="flex" flex-direction="column" className={classes.rewardsText}>
            <Typography variant="caption">Rewards&nbsp;</Typography>
            <Typography variant="caption">{getRewardPointsText()}</Typography>
          </Box>
        </Button>
      )}
      <InfoAndHours restaurant={restaurant} />
      <Deals restaurant={restaurant} />

      {location && (
        <Box marginX={1}>
          <a className={classes.link} target="_blank" rel="noopener noreferrer" href={'https://maps.google.com?q=' + location?.lat + ',' + location?.lng + ''}>
            <LocationOnIcon fontSize="small" className={classes.locationIcon} />
          </a>
        </Box>
      )}
      {virtualPhone ? (
        <Box marginX={1}>
          <a href={`tel:${virtualPhone}`} className={classes.link}>
            <PhoneIcon fontSize="small" className={classes.phoneIcon} />
          </a>
        </Box>
      ) : (
        phone && (
          <Box marginX={1}>
            <a href={`tel:${phone}`} className={classes.link}>
              <PhoneIcon fontSize="small" className={classes.phoneIcon} />
            </a>
          </Box>
        )
      )}
      <TableService restaurantId={restaurant.id} />
    </Box>
  ) : (
    <Box bgcolor="#fff" width="100%" display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
      <Box display="flex" justifyContent="start" flexWrap="wrap">
        <Deals restaurant={restaurant} />
        {isRewardAvailable && (
          <Button size="small" className={classes.rewardBtn} onClick={gotoRewards} variant="contained" fullWidth={false} color="primary" disableElevation>
            <Box display="flex" flex-direction="column" className={classes.rewardsText}>
              <Typography variant="caption">Rewards&nbsp;</Typography>
              {/* <Typography variant="caption">{getRewardPointsText()}</Typography> */}
            </Box>
          </Button>
        )}
        <InfoAndHours restaurant={restaurant} />
      </Box>
      <Box display="flex" width="auto" alignItems="center" justifyContent="start">
        {location && (
          <Box mr={0.5}>
            <a className={classes.link} target="_blank" rel="noopener noreferrer" href={'https://maps.google.com?q=' + location?.lat + ',' + location?.lng + ''}>
              <LocationOnIcon fontSize="small" className={classes.locationIcon} />
            </a>
          </Box>
        )}

        {virtualPhone ? (
          <Box component="span">
            <a href={`tel:${virtualPhone}`} className={classes.link}>
              <PhoneIcon fontSize="small" className={classes.phoneIcon} />
            </a>
          </Box>
        ) : (
          phone && (
            <Box component="span">
              <a href={`tel:${phone}`} className={classes.link}>
                <PhoneIcon fontSize="small" className={classes.phoneIcon} />
              </a>
            </Box>
          )
        )}
      </Box>
      <TableService restaurantId={restaurant.id} />
    </Box>
  );
};

export default RestaurantInfo;
