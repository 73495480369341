import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import client from 'apollo';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './global.css';
import { createIdempotencyKey } from 'util/idempotency';
import ErrorBoundary from 'components/ErrorBoundary';
import { initialise as sentryInitialise } from './sentryConfig';
import { Bugfender } from '@bugfender/sdk';

createIdempotencyKey();

sentryInitialise();

Bugfender.init({
  appKey: process.env.REACT_APP_BUGFENDER_KEY ?? '',
  overrideConsoleMethods: false,
  printToConsole: false
});

ReactDOM.render(
  <ErrorBoundary>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
