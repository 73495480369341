import { makeStyles } from '@material-ui/core';
import { green, grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      minWidth: 1200,
      margin: 'auto',
      left: theme.spacing(-2)
    },
    color: '#fff'
  },
  successBg: {
    backgroundColor: green[800]
  },
  errorBg: {
    backgroundColor: theme.palette.error.main
  },
  backdrop: {
    zIndex: 10
  },
  content: {
    width: '100%',
    bottom: 0,
    position: 'absolute',
    borderRadius: '5px 5px 0 0',
    maxWidth: 1200,
    backgroundColor: '#fff'
  },
  icon: {
    color: grey[500],
    marginRight: 10
  },
  orderType: {
    fontWeight: 'bolder',
    lineHeight: 1
  },
  divider: {
    backgroundColor: grey[300],
    height: '2px'
  },
  btns: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiButton-label': {
      flexDirection: 'inherit'
    }
  }
}));
