import React, { useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import DialogHeader from 'components/DialogHeader';
import { useHistory } from 'react-router-dom';
import { useStore } from 'store';
import { AuthContext } from 'fbase/authContext';

const UserAgreement = () => {
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);

  const isAnonymousUser = currentUser && currentUser.isAnonymous ? currentUser.isAnonymous : null;
  const {
    state: {
      ui: { isAgreementPopOpen }
    },
    dispatch
  } = useStore();

  const onBack = () => {
    if (isAgreementPopOpen) {
      dispatch({
        type: 'UI_ACTION_AGREEMENT_POPUP',
        payload: false
      });

      if (!isAnonymousUser) {
        dispatch({
          type: 'UI_ACTION_UPDATED_TERMS_POPUP',
          payload: true
        });
      }
    } else {
      history.goBack();
    }
  };

  return (
    <>
      <DialogHeader text="User Agreement" onBack={onBack} closeIconId="closeUserAgreement" />
      <Box padding={2}>
        <Typography variant="h6">Lokobee Consumer END USER LICENSE AGREEMENT</Typography>
        <Typography variant="body2" align="justify">
          This copy of Lokobee Consumer App ("the Software Product") and accompanying documentation is licensed and not sold. This Software Product is protected by copyright laws and treaties, as well
          as laws and treaties related to other forms of intellectual property. Biztrology Inc. or its subsidiaries, affiliates, and suppliers (collectively "Biztrology Inc.") own intellectual
          property rights in the Software Product. The Licensee's ("you" or "your") license to download, use, copy, or change the Software Product is subject to these rights and to all the terms and
          conditions of this End User License Agreement ("Agreement").
        </Typography>
        <Typography variant="h6">Acceptance</Typography>
        <Typography variant="body2" align="justify">
          YOU ACCEPT AND AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT BY SELECTING THE "ACCEPT" OPTION AND DOWNLOADING THE SOFTWARE PRODUCT OR BY INSTALLING, USING, OR COPYING THE SOFTWARE
          PRODUCT. YOU MUST AGREE TO ALL OF THE TERMS OF THIS AGREEMENT BEFORE YOU WILL BE ALLOWED TO DOWNLOAD THE SOFTWARE PRODUCT. IF YOU DO NOT AGREE TO ALL OF THE TERMS OF THIS AGREEMENT, YOU MUST
          SELECT "DECLINE" AND YOU MUST NOT INSTALL, USE, OR COPY THE SOFTWARE PRODUCT.
        </Typography>
        <Typography variant="h6">License Grant</Typography>
        <Typography variant="body2" align="justify">
          This Agreement entitles you to install and use one copy of the Software Product. In addition, you may make one archival copy of the Software Product. The archival copy must be on a storage
          medium other than a hard drive, and may only be used for the reinstallation of the Software Product. This Agreement does not permit the installation or use of multiple copies of the Software
          Product, or the installation of the Software Product on more than one computer at any given time, on a system that allows shared used of applications, on a multi-user network, or on any
          configuration or system of computers that allows multiple users. Multiple copy use or installation is only allowed if you obtain an appropriate licensing agreement for each user and each
          copy of the Software Product.
        </Typography>
        <Typography variant="h6">Restrictions on Transfer</Typography>
        <Typography variant="body2" align="justify">
          Without first obtaining the express written consent of Biztrology Inc., you may not assign your rights and obligations under this Agreement, or redistribute, encumber, sell, rent, lease,
          sublicense, or otherwise transfer your rights to the Software Product.
        </Typography>
        <Typography variant="h6">Restrictions on Use</Typography>
        <Typography variant="body2" align="justify">
          You may not use, copy, or install the Software Product on any system with more than one computer, or permit the use, copying, or installation of the Software Product by more than one user or
          on more than one computer. If you hold multiple, validly licensed copies, you may not use, copy, or install the Software Product on any system with more than the number of computers
          permitted by license, or permit the use, copying, or installation by more users, or on more computers than the number permitted by license.
        </Typography>
        <Typography variant="body2" align="justify">
          You may not decompile, "reverse-engineer", disassemble, or otherwise attempt to derive the source code for the Software Product.
        </Typography>
        <Typography variant="body2" align="justify">
          You may not use the database portion of the Software Product in connection with any software other than the Software Product.
        </Typography>
        <Typography variant="h6">Restrictions on Alteration</Typography>
        <Typography variant="body2" align="justify">
          You may not modify the Software Product or create any derivative work of the Software Product or its accompanying documentation. Derivative works include but are not limited to translations.
          You may not alter any files or libraries in any portion of the Software Product. You may not reproduce the database portion or create any tables or reports relating to the database portion.
        </Typography>
        <Typography variant="h6">Restrictions on Copying</Typography>
        <Typography variant="body2" align="justify">
          You may not copy any part of the Software Product except to the extent that licensed use inherently demands the creation of a temporary copy stored in computer memory and not permanently
          affixed on storage medium. You may make one archival copy which must be stored on a medium other than a computer hard drive.
        </Typography>
        <Typography variant="h6">Disclaimer of Warranties and Limitation of Liability</Typography>
        <Typography variant="body2" align="justify">
          UNLESS OTHERWISE EXPLICITLY AGREED TO IN WRITING BY BIZTROLOGY INC., BIZTROLOGY INC. MAKES NO OTHER WARRANTIES, EXPRESS OR IMPLIED, IN FACT OR IN LAW, INCLUDING, BUT NOT LIMITED TO, ANY
          IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OTHER THAN AS SET FORTH IN THIS AGREEMENT OR IN THE LIMITED W ARRANTY DOCUMENTS PROVIDED WITH THE SOFTWARE PRODUCT.
        </Typography>
        <Typography variant="body2" align="justify">
          Biztrology Inc. makes no warranty that the Software Product will meet your requirements or operate under your specific conditions of use. Biztrology Inc. makes no warranty that operation of
          the Software Product will be secure, error free, or free from interruption. YOU MUST DETERMINE WHETHER THE SOFTWARE PRODUCT SUFFICIENTLY MEETS YOUR REQUIREMENTS FOR SECURITY AND
          UNINTERRUPTABILITY. YOU BEAR SOLE RESPONSIBILITY AND ALL LIABILITY FOR ANY LOSS INCURRED DUE TO FAILURE OF THE SOFTWARE PRODUCT TO MEET YOUR REQUIREMENTS. BIZTROLOGY INC. WILL NOT, UNDER ANY
          CIRCUMSTANCES, BE RESPONSIBLE OR LIABLE FOR THE LOSS OF DATA ON ANY COMPUTER OR INFORMATION STORAGE DEVICE.
        </Typography>
        <Typography variant="body2" align="justify">
          UNDER NO CIRCUMSTANCES SHALL BIZTROLOGY INC., ITS DIRECTORS, OFFICERS, EMPLOYEES OR AGENTS BE LIABLE TO YOU OR ANY OTHER PARTY FOR INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL, PUNITIVE, OR
          EXEMPLARY DAMAGES OF ANY KIND (INCLUDING LOST REVENUES OR PROFITS OR LOSS OF BUSINESS) RESULTING FROM THIS AGREEMENT, OR FROM THE FURNISHING, PERFORMANCE, INSTALLATION, OR USE OF THE
          SOFTWARE PRODUCT, WHETHER DUE TO A BREACH OF CONTRACT, BREACH OF WARRANTY, OR THE NEGLIGENCE OF BIZTROLOGY INC. OR ANY OTHER PARTY, EVEN IF BIZTROLOGY INC. IS ADVISED BEFOREHAND OF THE
          POSSIBILITY OF SUCH DAMAGES. TO THE EXTENT THAT THE APPLICABLE JURISDICTION LIMITS BIZTROLOGY INC.'S ABILITY TO DISCLAIM ANY IMPLIED WARRANTIES, THIS DISCLAIMER SHALL BE EFFECTIVE TO THE
          MAXIMUM EXTENT PERMITTED.
        </Typography>
        <Typography variant="h6">Limitation of Remedies and Damages</Typography>
        <Typography variant="body2" align="justify">
          Your remedy for a breach of this Agreement or of any warranty included in this Agreement is the correction or replacement of the Software Product. Selection of whether to correct or replace
          shall be solely at the discretion of Biztrology Inc.. Biztrology Inc. reserves the right to substitute a functionally equivalent copy of the Software Product as a replacement. If Biztrology
          Inc. is unable to provide a replacement or substitute Software Product or corrections to the Software Product, your sole alternate remedy shall be a refund of the purchase price for the
          Software Product exclusive of any costs for shipping and handling.
        </Typography>
        <Typography variant="body2" align="justify">
          Any claim must be made within the applicable warranty period. All warranties cover only defects arising under normal use and do not include malfunctions or failure resulting from misuse,
          abuse, neglect, alteration, problems with electrical power, acts of nature, unusual temperatures or humidity, improper installation, or damage determined by Biztrology Inc. to have been
          caused by you. All limited warranties on the Software Product are granted only to you and are non-transferable. You agree to indemnify and hold Biztrology Inc. harmless from all claims,
          judgments, liabilities, expenses, or costs arising from your breach of this Agreement and/or acts or omissions.
        </Typography>
        <Typography variant="h6">Governing Law, Jurisdiction and Costs</Typography>
        <Typography variant="body2" align="justify">
          This Agreement is governed by the laws of Delaware, without regard to Delaware's conflict or choice of law provisions.
        </Typography>
        <Typography variant="h6">Severability</Typography>
        <Typography variant="body2" align="justify">
          If any provision of this Agreement shall be held to be invalid or unenforceable, the remainder of this Agreement shall remain in full force and effect. To the extent any express or implied
          restrictions are not permitted by applicable laws, these express or implied restrictions shall remain in force and effect to the maximum extent permitted by such applicable laws.
        </Typography>
        <Typography variant="h6">Profanity policy</Typography>
        <Typography variant="body2" align="justify">
          We want all our members to have a positive experience when using Lokobee apps and website. We don’t allow members to use language that we consider to be defamatory, offensive, or that could
          be seen as abuse or harassment. Biztrology, doesn't allow the use of hateful, racist, offensive, profane, or vulgar language in any areas of the website. Areas include listings, products,
          profiles, feedback, discussion boards, and more. Defamatory language (language or comments that could be considered harmful to the reputation of another Biztrology member) is also strictly
          prohibited. How you let us know about the inappropriate language depends on where you see it:
        </Typography>
        <ul>
          <li>If someone left you abusive or profane feedback contact us to report the feedback at reportabuse@biztrology.com</li>
          <li>If you've come across a listing that you believe is offensive, please report it to us at reportabuse@biztrology.com</li>
          <li>
            If another member has made comments about you that you consider defamatory, or if you've received offensive or abusive messages from another member, you can report it to us at
            reportabuse@biztrology.com
          </li>
        </ul>
        <Typography variant="body2" align="justify">
          If you've received messages which you consider threatening and are worried about your safety, we recommend contacting your local law enforcement agency.
        </Typography>
        <Typography variant="h6">Look & feel, content of Lokobee app and website</Typography>
        <Typography variant="body2" align="justify">
          Biztrology shall have the right to make changes to the design, layout and other aspects of “look & feel and contents” of Lokobee app’s and website provided that such changes are consistent
          with the basic use model.
        </Typography>
        <Typography variant="h6"> GNU LESSER GENERAL PUBLIC LICENSE</Typography>
        <Typography variant="h6"> Version 3, 29 June 2007</Typography>
        <Typography variant="body2" align="justify">
          Copyright © 2007 Free Software Foundation, Inc. <a href=" https://fsf.org">https://fsf.org/</a>
        </Typography>
        <Typography variant="body2" align="justify">
          Everyone is permitted to copy and distribute verbatim copies of this license document, but changing it is not allowed.
        </Typography>
        <Typography variant="body2" align="justify">
          This version of the GNU Lesser General Public License incorporates the terms and conditions of version 3 of the GNU General Public License, supplemented by the additional permissions listed
          below.
        </Typography>
        <Typography variant="h6">0. Additional Definitions.</Typography>
        <Typography variant="body2" align="justify">
          As used herein, “this License” refers to version 3 of the GNU Lesser General Public License, and the “GNU GPL” refers to version 3 of the GNU General Public License.
        </Typography>
        <Typography variant="body2" align="justify">
          “The Library” refers to a covered work governed by this License, other than an Application or a Combined Work as defined below.
        </Typography>
        <Typography variant="body2" align="justify">
          An “Application” is any work that makes use of an interface provided by the Library, but which is not otherwise based on the Library. Defining a subclass of a class defined by the Library is
          deemed a mode of using an interface provided by the Library.
        </Typography>
        <Typography variant="body2" align="justify">
          A “Combined Work” is a work produced by combining or linking an Application with the Library. The particular version of the Library with which the Combined Work was made is also called the
          “Linked Version”.
        </Typography>
        <Typography variant="body2" align="justify">
          The “Minimal Corresponding Source” for a Combined Work means the Corresponding Source for the Combined Work, excluding any source code for portions of the Combined Work that, considered in
          isolation, are based on the Application, and not on the Linked Version.
        </Typography>
        <Typography variant="body2" align="justify">
          The “Corresponding Application Code” for a Combined Work means the object code and/or source code for the Application, including any data and utility programs needed for reproducing the
          Combined Work from the Application, but excluding the System Libraries of the Combined Work.
        </Typography>

        <Typography variant="h6">1. Exception to Section 3 of the GNU GPL.</Typography>
        <Typography variant="body2" align="justify">
          You may convey a covered work under sections 3 and 4 of this License without being bound by section 3 of the GNU GPL.
        </Typography>
        <Typography variant="h6">2. Conveying Modified Versions.</Typography>
        <Typography variant="body2" align="justify">
          If you modify a copy of the Library, and, in your modifications, a facility refers to a function or data to be supplied by an Application that uses the facility (other than as an argument
          passed when the facility is invoked), then you may convey a copy of the modified version:
          <ul>
            <li>
              a) under this License, provided that you make a good faith effort to ensure that, in the event an Application does not supply the function or data, the facility still operates, and
              performs whatever part of its purpose remains meaningful, or
            </li>
            <li>b) under the GNU GPL, with none of the additional permissions of this License applicable to that copy.</li>
          </ul>
        </Typography>
        <Typography variant="h6">3. Object Code Incorporating Material from Library Header Files.</Typography>
        <Typography variant="body2" align="justify">
          The object code form of an Application may incorporate material from a header file that is part of the Library. You may convey such object code under terms of your choice, provided that, if
          the incorporated material is not limited to numerical parameters, data structure layouts and accessors, or small macros, inline functions and templates (ten or fewer lines in length), you do
          both of the following:
          <ul>
            <li>a) Give prominent notice with each copy of the object code that the Library is used in it and that the Library and its use are covered by this License.</li>
            <li>b) Accompany the object code with a copy of the GNU GPL and this license document.</li>
          </ul>
        </Typography>
        <Typography variant="h6">4. Combined Works.</Typography>
        <Typography variant="body2" align="justify">
          You may convey a Combined Work under terms of your choice that, taken together, effectively do not restrict modification of the portions of the Library contained in the Combined Work and
          reverse engineering for debugging such modifications, if you also do each of the following:
          <ul>
            <li>a) Give prominent notice with each copy of the Combined Work that the Library is used in it and that the Library and its use are covered by this License.</li>
            <li>b) Accompany the Combined Work with a copy of the GNU GPL and this license document.</li>
            <li>
              c) For a Combined Work that displays copyright notices during execution, include the copyright notice for the Library among these notices, as well as a reference directing the user to
              the copies of the GNU GPL and this license document.
            </li>
            <li>
              {' '}
              d) Do one of the following:
              <ul>
                <li>
                  0) Convey the Minimal Corresponding Source under the terms of this License, and the Corresponding Application Code in a form suitable for, and under terms that permit, the user to
                  recombine or relink the Application with a modified version of the Linked Version to produce a modified Combined Work, in the manner specified by section 6 of the GNU GPL for
                  conveying Corresponding Source.
                </li>
                <li>
                  1) Use a suitable shared library mechanism for linking with the Library. A suitable mechanism is one that (a) uses at run time a copy of the Library already present on the user's
                  computer system, and (b) will operate properly with a modified version of the Library that is interface-compatible with the Linked Version.
                </li>
              </ul>
            </li>
          </ul>
        </Typography>
        <Typography variant="h6">5. Combined Libraries.</Typography>
        <Typography variant="body2" align="justify">
          You may place library facilities that are a work based on the Library side by side in a single library together with other library facilities that are not Applications and are not covered by
          this License, and convey such a combined library under terms of your choice, if you do both of the following:
          <ul>
            <li>a) Accompany the combined library with a copy of the same work based on the Library, uncombined with any other library facilities, conveyed under the terms of this License.</li>
            <li>b) Give prominent notice with the combined library that part of it is a work based on the Library, and explaining where to find the accompanying uncombined form of the same work.</li>
          </ul>
        </Typography>

        <Typography variant="h6">6. Revised Versions of the GNU Lesser General Public License.</Typography>
        <Typography variant="body2" align="justify">
          The Free Software Foundation may publish revised and/or new versions of the GNU Lesser General Public License from time to time. Such new versions will be similar in spirit to the present
          version, but may differ in detail to address new problems or concerns.
        </Typography>
        <Typography variant="body2" align="justify">
          Each version is given a distinguishing version number. If the Library as you received it specifies that a certain numbered version of the GNU Lesser General Public License “or any later
          version” applies to it, you have the option of following the terms and conditions either of that published version or of any later version published by the Free Software Foundation. If the
          Library as you received it does not specify a version number of the GNU Lesser General Public License, you may choose any version of the GNU Lesser General Public License ever published by
          the Free Software Foundation.
        </Typography>
        <Typography variant="body2" align="justify">
          If the Library as you received it specifies that a proxy can decide whether future versions of the GNU Lesser General Public License shall apply, that proxy's public statement of acceptance
          of any version is permanent authorization for you to choose that version for the Library.
        </Typography>
      </Box>
    </>
  );
};

export default UserAgreement;
