import React, { useContext } from 'react';
import { Box, Button } from '@material-ui/core';
import { useFormik, getIn } from 'formik';
import { AuthContext } from 'fbase/authContext';
import { useSnackbar } from 'notistack';
import validationSchema from './validations';
import { useHistory } from 'react-router-dom';
import { FirebaseContext } from 'fbase';
import FormDialog from 'components/FormDialog';
import InputField from 'components/Inputs/InputField';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { useAlert } from 'hooks';

const ChangePassword: React.FC = () => {
  const { firebase } = useContext(FirebaseContext);

  const auth = firebase!.getAuth();

  const { currentUser } = useContext(AuthContext);

  const history = useHistory();

  const snackbar = useSnackbar();

  const { lokoAlert } = useAlert();

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
      currentPassword: ''
    },
    initialErrors: {
      newPassword: 'Required',
      confirmPassword: 'Required',
      currentPassword: ''
    },
    validationSchema,
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);

      try {
        if (currentUser && currentUser.email) {
          const credential = auth.EmailAuthProvider.credential(currentUser.email, values.currentPassword);

          await currentUser?.reauthenticateWithCredential(credential);

          await currentUser?.updatePassword(values.newPassword);

          snackbar.enqueueSnackbar('Password updated', { variant: 'success' });

          actions.setSubmitting(false);

          history.goBack();
        }
      } catch (e) {
        let message = '';

        if (e.code) {
          switch (e.code) {
            case 'auth/weak-password':
              message = 'Password is weak :(';
              break;
            case 'auth/requires-recent-login':
              message = 'Please login again';
              break;
            default:
              message = e.message;
              console.log(e.message);
          }
        }

        lokoAlert(message);

        actions.setSubmitting(false);
      }
    },
    enableReinitialize: true
  });

  const isButtonDisabled = !formik.isValid;

  return (
    <Box display="flex" height="100%">
      <FormDialog title="Update password" onBack={() => history.goBack()}>
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <Box marginBottom={2}>
              <InputField
                type="password"
                placeholder="Current Password"
                name="currentPassword"
                value={formik.values.currentPassword}
                onChange={formik.handleChange}
                fullWidth={true}
                onBlur={formik.handleBlur}
                autoComplete="off"
                autoCapitalize="off"
                error={!!getIn(formik.touched, 'currentPassword') && !!getIn(formik.errors, 'currentPassword')}
                helperText={!!getIn(formik.touched, 'currentPassword') && !!getIn(formik.errors, 'currentPassword') ? getIn(formik.errors, 'currentPassword') : ''}
                startIcon={<VpnKeyIcon />}
                variant="outlined"
              />
            </Box>
            <Box marginBottom={2}>
              <InputField
                type="password"
                placeholder="New password"
                name="newPassword"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                fullWidth={true}
                onBlur={formik.handleBlur}
                autoComplete="off"
                error={!!getIn(formik.touched, 'newPassword') && !!getIn(formik.errors, 'newPassword')}
                helperText={!!getIn(formik.touched, 'newPassword') && !!getIn(formik.errors, 'newPassword') ? getIn(formik.errors, 'newPassword') : ''}
                startIcon={<VpnKeyIcon />}
                variant="outlined"
              />
            </Box>
            <Box marginBottom={2}>
              <InputField
                type="password"
                placeholder="Confrim password"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                fullWidth={true}
                onBlur={formik.handleBlur}
                autoComplete="off"
                error={!!getIn(formik.touched, 'confirmPassword') && !!getIn(formik.errors, 'confirmPassword')}
                helperText={!!getIn(formik.touched, 'confirmPassword') && !!getIn(formik.errors, 'confirmPassword') ? getIn(formik.errors, 'confirmPassword') : ''}
                startIcon={<VpnKeyIcon />}
                variant="outlined"
              />
            </Box>

            <Button type="submit" size="large" variant="contained" color="primary" disabled={isButtonDisabled} fullWidth={true} disableElevation={true}>
              Save
            </Button>
          </Box>
        </form>
      </FormDialog>
    </Box>
  );
};

export default ChangePassword;
