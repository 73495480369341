import { Firebase } from 'fbase';
import Logger from 'util/logger';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { resolvers, typeDefs } from './schema';
import { ApolloLink, Observable } from 'apollo-link';

const cache = new InMemoryCache({
  dataIdFromObject: (object: any) => {
    /**
     * All Image object key has been used as default Identifier.
     * Instead of id this will map Image object with Key.
     */
    switch (object.__typename) {
      case 'Image':
        return object.key;
      default:
        return defaultDataIdFromObject(object); // fall back to default handling
    }
  }
});

// /** Middleware before request is made */
export const request = async (operation: any) => {
  // Loads token from local storage
  const auth = new Firebase().getAuth();

  const token = await auth().currentUser?.getIdToken();

  // In case token is present bind it with all graphql request
  if (token) {
    operation.setContext({
      headers: {
        Authorization: token
      }
    });
  }
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle: any = null;
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError, operation, response }) => {
      if (graphQLErrors) {
        Logger.log('[GRAPHQL ERROR]', graphQLErrors);
      }
      if (networkError) {
        console.log('[NETWORK ERROR]');
        Logger.log('[NETWORK ERROR]', {
          error: networkError.message,
          operation: operation
        });
      }
    }),
    requestLink,
    new HttpLink({
      uri: process.env.REACT_APP_BACKEND_GRAPH_URI
      // uri: 'http://localhost:4000/staging/graphql'
    })
  ]),
  cache,
  resolvers,
  typeDefs,
  connectToDevTools: true
});

client.writeData({
  data: {
    cartItems: []
  }
});

/**
 * Resetting apollo client.
 * Removes token from local storage.
 * Reset cache to its default value.
 */
client.onResetStore(async () => {
  // Remove token from localStorage.
  // Write default values in the cache memory
  client.writeData({ data: {} });
});

export default client;
