import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    bottom: '70px',
    margin: 'auto',
    width: 150,
    display: 'flex',
    right: '5%',
    zIndex: 1,
    borderRadius: '20px',
    padding: theme.spacing(1)
  },
  backdrop: {
    zIndex: 1450
  },
  customTipContainer: {
    padding: theme.spacing(1, 2),
    backgroundColor: 'white',
    height: '70%',
    width: '95%',
    bottom: 0,
    position: 'absolute'
  }
}));
