import React from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Box, useMediaQuery, Grid } from '@material-ui/core';
import Menu from './Menu';
import PopularDishes from 'components/PopularDishes';
import RestaurantInfo from './RestaurantInfo';
import { useGetRestaurantById, useGetAllActiveDishesQuery } from 'graphql/hooks';
import { useActiveRestaurant } from 'hooks/restaurant';
import Announcement from 'components/Announcement';
import DishSearch from 'components/DishSearch';

interface IProps {
  id: string;
}

const RestaurantHome: React.FC<RouteComponentProps<IProps>> = () => {
  const { restaurantId } = useActiveRestaurant();

  const { restaurant } = useGetRestaurantById(restaurantId, 'ALL');

  const { dishes, dishCategories, loading } = useGetAllActiveDishesQuery();

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  if (restaurant) {
    const id = restaurant.getId;

    const announcement = restaurant.getAnnouncement;

    return (
      <>
        <Box maxWidth={1200} marginX="auto">
          {dishes && !isDesktop && (
            <Box padding={1}>
              <DishSearch dishes={dishes} />
            </Box>
          )}
          <Box padding={1}>
            {isDesktop ? (
              <Grid container={true} alignItems="center">
                <Grid item={true} sm={6}>
                  {dishes && (
                    <Box padding={1}>
                      <DishSearch dishes={dishes} />
                    </Box>
                  )}
                </Grid>
                <Grid item={true} sm={6}>
                  <RestaurantInfo restaurant={restaurant} />
                </Grid>
              </Grid>
            ) : (
              <RestaurantInfo restaurant={restaurant} />
            )}
          </Box>
          <Box>
            {announcement && (
              <Box padding={1}>
                <Announcement text={announcement} />
              </Box>
            )}

            <PopularDishes restaurantId={id} dishes={dishes} loading={loading} />
            <Menu restaurantId={id} dishes={dishes} dishCategories={dishCategories} loading={loading} />
          </Box>
        </Box>
      </>
    );
  }

  return null;
};

export default withRouter(RestaurantHome);
