import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  btns: {
    margin: theme.spacing(0, 1)
  },
  bold: {
    fontWeight: 'bold'
  },
  backdrop: {
    zIndex: 10
  },
  content: {
    padding: theme.spacing(3),
    backgroundColor: '#fff',
    width: '100%',
    bottom: 0,
    position: 'absolute',
    borderRadius: '5px 5px 0 0',
    maxWidth: 1200,
    color: '#424242'
  },
  link: {
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  },
  closeBtn: {
    position: 'absolute',
    right: theme.spacing(5),
  },
  heading: {
    fontSize: '1rem',
    fontWeight: 'bold'
  },
  subheading: {
    fontSize: '0.9rem',
    fontWeight: 'bold'
  }
}));
