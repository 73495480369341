import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    zIndex: 100000,
    left: 0,
    top: 0
  }
}));
