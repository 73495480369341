import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useStyle } from './style';
import Moment from 'moment';
import { Coupon } from 'generated/types';
import Maybe from 'graphql/tsutils/Maybe';

interface IProps {
  appliedCode?: string;
  couponData: Coupon;
  isApplicable?: boolean;
  isSelectionCard?: boolean;
}

const CouponCard: React.FC<IProps> = ({ couponData, isApplicable, appliedCode = '', isSelectionCard = false }) => {
  const classes = useStyle();
  const name = couponData?.name;
  const code = couponData?.code;
  const orderType = couponData?.orderType ? [...couponData?.orderType] : [];

  const description = couponData?.description;
  const endDate = couponData?.endDate;

  return (
    <>
      <Box className={isSelectionCard ? `${isApplicable ? classes.CouponCard : classes.disabledCard}` : classes.CouponCard} display="flex" flexDirection={'column'} alignItems="flex-start">
        <Box display={'flex'}>
          <Typography variant="h5" style={{ textDecoration: appliedCode === code ? 'underline' : 'none' }}>
            <strong>{code}</strong>
          </Typography>
        </Box>
        <Box display={'flex'} marginBottom={1}>
          <Typography variant="h6">{name}</Typography>
        </Box>
        <Box display={'flex'} marginBottom={1}>
          <Typography variant="button">{description}</Typography>
        </Box>
        <Box display={'flex'} flexWrap="wrap">
          {orderType.map((type: Maybe<string>, index: number) => {
            return (
              <Box marginBottom={1} key={index}>
                <Typography variant="caption" className={classes.borderedText}>
                  {type}
                </Typography>
              </Box>
            );
          })}
        </Box>
        <Box display={'flex'} marginBottom={1}>
          {endDate && endDate !== '' && (
            <Typography variant="caption" className={classes.borderedText}>
              Valid till: {Moment(couponData.endDate).format('MMM/DD/YYYY')}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CouponCard;
