import React, { CSSProperties, useContext } from 'react';
import { Box } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import Header from 'components/Header';
import { Route, Switch } from 'react-router-dom';
import HomePage from './HomePage';
import BottomNavigation from 'components/BottomNavigation';
import RestaurantRoutes from './Restaurant/routes';
import { StickyContainer } from 'react-sticky';
import MyAccount from 'pages/MyAccount';
import Feedback from 'components/Feedback';
import Orders from 'pages/Orders';
import OrderDetails from 'components/OrderDetails';
import Tracking from './Orders/Tracking';
import { AuthContext } from 'fbase/authContext';
// import GroceryStoreRoutes from './GroceryStore/routes';
// import GroceryHeader from 'components/Header/GroceryHeader';
// import GroceryOrderDetails from 'components/GroceryOrderDetails';
// import GroceryOrderTracking from './Orders/GroceryOrders/GroceryOrderTracking';
import useCheckLocalStorageForCartData from 'hooks/restaurant/useCheckLocalStorageForCartData';

const StickyContainerStyles: CSSProperties = {
  flex: 1,
  overflowY: 'scroll'
};

const Routes: React.FC = () => {
  const { pathname } = useLocation();
  const { token } = useContext(AuthContext);

  useCheckLocalStorageForCartData();

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box>{(['/', '/feedback', '/tracking', '/restaurants'].includes(pathname) || pathname.includes('/restaurants')) && <Header />}</Box>
      {/* <Box>
        {(['/grocery'].includes(pathname) || pathname.includes('/grocery')) &&
          !pathname.includes('cart') &&
          !pathname.includes('orders') &&
          !pathname.includes('payment') &&
          !pathname.includes('success') &&
          !pathname.includes('deals') && <GroceryHeader />}
      </Box> */}
      <Box display="flex" flex={1} overflow="scroll" flexDirection="column">
        <StickyContainer id="stickycontainer" style={StickyContainerStyles}>
          <Switch>
            <Route exact={true} path="/" component={HomePage} />
            <Route path="/restaurants" component={RestaurantRoutes} />
            {/* <Route path="/grocery/:groceryStoreId" component={GroceryStoreRoutes} /> */}
            <Route path="/myaccount" component={MyAccount} />
            {/* <Route path="/wallet" component={Wallet} /> */}
            <Route path="/feedback" component={Feedback} />
            {token && <Route path="/orders/:id/tracking" component={Tracking} />}
            {/* {token && <Route path="/orders/grocery/:id/tracking" component={GroceryOrderTracking} />} */}
            {/* <Route path="/orders/grocery/:id" component={GroceryOrderDetails} /> */}
            <Route path="/orders/:id" component={OrderDetails} />
            <Route path="/orders" component={Orders} />
          </Switch>
        </StickyContainer>
      </Box>
      <BottomNavigation />
    </Box>
  );
};

export default Routes;
