import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  locationTextWrapper: {
    '& > p': {
      marginBottom: -6
    }
  },
  dialogContent: {
    padding: 0,
    dispplay: 'flex',
    flexDirection: 'column',
    '&:first-child': {
      padding: 0
    }
  },
  inputField: {
    flex: 1
  }
}));
