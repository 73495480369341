import React from 'react';
import { useStyles } from '../style';
import { Box, Typography, Button } from '@material-ui/core';
import RewardsLayout from 'components/RewardsLayout';
import DoneIcon from '@material-ui/icons/Done';
import teal from '@material-ui/core/colors/teal';
import grey from '@material-ui/core/colors/grey';

interface IProps {
  title: string;
  currentPoints: number;
  pendingPoints: number;
  claimPoints: number;
}

const RedeemSkipped = ({ title, currentPoints, pendingPoints, claimPoints }: IProps) => {
  const classes = useStyles();

  const section1 = (
    <Box display="flex" flexDirection="column" color="white" height="95%" justifyContent="space-around">
      <Box textAlign="center">
        <Typography variant="h4" className={classes.uppercase}>
          {title}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-around">
        <Box display="flex" flexDirection="column" textAlign="center">
          <Typography variant="body2">Current Balance</Typography>
          <Typography variant="h3" className={classes.bold}>
            {currentPoints}
          </Typography>
          <Typography variant="body2">points</Typography>
        </Box>
        <Box display="flex" flexDirection="column" textAlign="center">
          <Typography variant="body2">Current Balance</Typography>
          <Typography variant="h3" className={classes.bold}>
            {pendingPoints}
          </Typography>
          <Typography variant="body2">points</Typography>
        </Box>
      </Box>
    </Box>
  );

  const section2 = (
    <Box display="flex" flexDirection="column" width="70%" height="100%" justifyContent="space-evenly" alignItems="center" marginX="auto">
      <Box padding={4} borderRadius="50%" bgcolor={grey[50]} boxShadow={5}>
        <Box borderColor={teal[300]} borderRadius="50%" border={5} padding={1}>
          <DoneIcon className={classes.successIcon} />
        </Box>
      </Box>
      <Typography variant="body1" align="center">
        <span className={classes.bold}>Thank you ! </span>
      </Typography>
      <Typography variant="body1" align="center">
        <span className={classes.bold}> {claimPoints} </span>
        added to your account.
      </Typography>
      <Button variant="contained" color="primary" disableElevation={true} className={classes.successBtn}>
        Reward Central
      </Button>
    </Box>
  );

  return <RewardsLayout section1={section1} section2={section2} gradientColor="success" />;
};

export default RedeemSkipped;
