import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Typography, Backdrop, Divider, Grid, useMediaQuery } from '@material-ui/core';
import { useGetAllActiveDishesQuery } from 'graphql/hooks';
import { useStyles } from './style';
import Close from '@material-ui/icons/Close';
import FlashDeals from 'components/RestaurantDeals/FlashDeals';
import { Restaurant } from 'models';
// import { AuthContext } from 'fbase/authContext';
import { Coupon } from 'generated/types';
import { useGetCouponsByBizId } from 'graphql/hooks/coupon.hooks';
import CouponCard from 'components/CouponCard';

interface IProps {
  restaurant: Restaurant;
}
const keepSmGrid = true;
function Deals({ restaurant }: IProps) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  // const [selectedCategory, setSelectedCategory] = useState(false);
  const [restaurantMemo, setrestaurantMemo] = useState<any>([]);
  useEffect(() => {
    setrestaurantMemo(restaurant);
  }, [restaurant]);
  const { coupons: couponsData } = useGetCouponsByBizId(restaurant?.id!!);
  const { dishes } = useGetAllActiveDishesQuery();
  const getDishes = useMemo(() => dishes, [dishes]);

  const discountedDishesData = getDishes?.filter((getDishes) => getDishes.isDiscounted === true && getDishes.getMenuPrice.maxDiscountPercent > 0);
  const availableitems = useCallback(
    (restaurant) => {
      let availableCategory: any = [];
      if (restaurant && couponsData && couponsData.length) {
        availableCategory.push('coupons');
      } else {
        availableCategory.push('nocoupons');
      }

      if (discountedDishesData && discountedDishesData.length) {
        availableCategory.push('dishes');
      } else {
        availableCategory.push('nodeals');
      }
      return availableCategory;
    },
    [couponsData, discountedDishesData]
  );
  const availableCategory: (string | null)[] = useMemo(() => availableitems(restaurantMemo), [availableitems, restaurantMemo]);
  // const couponsCheck: boolean = selectedCategory || (availableCategory.includes('nodeals') && availableCategory.includes('coupons'));
  const handleClose = () => {
    setOpen(false);
  };

  if (availableCategory.includes('nocoupons') && availableCategory.includes('nodeals')) {
    return null;
  } else {
    return (
      <>
        <Box paddingLeft={couponsData && couponsData.length > 0 ? 2 : 0}>
          <Button className={classes.infoHoursBtn} variant="contained" color="primary" size="small" onClick={() => setOpen(true)} disableElevation={true}>
            {couponsData && couponsData.length > 0 ? 'Promotions' : 'Deals'}
          </Button>
        </Box>

        <Backdrop open={open} className={classes.backdrop}>
          <Box height="min-content" className={classes.customTipContainer} maxWidth={700}>
            <Box display="flex" flexDirection="column" height="100%">
              <Box marginBottom={1} className={classes.modalHeader}>
                <Typography variant="h6" className={classes.modalTitle}>
                  {couponsData && couponsData.length > 0 ? 'Promotions' : 'Deals'}
                </Typography>
                <Close className={classes.modalIcon} onClick={handleClose} />
              </Box>
              <Divider />

              <Box overflow="scroll" flex={1} paddingTop={1}>
                <Grid container={true} spacing={1} alignItems="stretch">
                  {couponsData &&
                    couponsData?.map((coupon: Coupon, index: number) => {
                      return (
                        <Grid item={true} sm={6} md={keepSmGrid ? 6 : 4} xs={12} zeroMinWidth={true} key={index}>
                          <Box className={`${!keepSmGrid ? classes.clickableCoupons : undefined}`} minHeight={isDesktop ? 135 : 0} padding={1}>
                            <CouponCard couponData={coupon} />
                          </Box>
                        </Grid>
                      );
                    })}
                </Grid>
                {/* )} */}
                {availableCategory.includes('coupons') && (
                  <Box paddingY={1}>
                    <Divider />
                  </Box>
                )}
                {/* {!selectedCategory && */}
                <FlashDeals onhandleClose={handleClose} getAllDishes={discountedDishesData} keepSmGrid={true} />
                {/* } */}
              </Box>
            </Box>
          </Box>
        </Backdrop>
      </>
    );
  }
}

export default Deals;
