import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { blue, green } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& a': {
        textDecoration: 'none',
        color: 'inherit'
      },
      background: '#fff',
      borderRadius: 5,
      margin: theme.spacing(1, 0)
    },
    successIcon: {
      color: '#fff',
      margin: theme.spacing(0, 0.5)
    },
    iconsWrapper: {
      position: 'absolute',
      bottom: 10,
      left: 5
    },
    couponCodeWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: green[900],
      borderRadius: '0.4rem',
      padding: theme.spacing(0.5),
      margin: theme.spacing(0, 1),
      background: green[200]
    },
    rewardsIconWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'black',
      borderRadius: '0.4rem',
      padding: theme.spacing(0.5),
      margin: theme.spacing(0, 1),
      background: theme.palette.primary.main
    },
    miles: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: blue[900],
      borderRadius: '0.4rem',
      padding: theme.spacing(0.5),
      margin: theme.spacing(0, 2),
      background: blue[100]
    },
    ratings: {
      background: theme.palette.success.dark,
      padding: theme.spacing(0, 0.5),
      borderRadius: '2px',
      fontWeight: 500
    },
    ratingsIcon: {
      fontSize: '20px'
    },
    restaurantLogoBox: {
      height: '20%',
      width: '15%',
      borderRadius: '3px',
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(1)
    },
    restaurantDetail: {
      fontWeight: 600
    },
    restaurantDetailDot: {
      margin: theme.spacing(0, 0.5),
      fontSize: '6px'
    },
    restaurantPause: {
      fontWeight: 300
    },
    cryptoCoupon: {
      width: '50px'
    }
  })
);
