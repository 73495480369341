import { makeStyles } from '@material-ui/core';
import { PizzaShapeType } from 'generated/custom';
import { blue, grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: 25,
    height: 25,
    borderRadius: '50%',
    border: '4px solid',
    borderColor: ({ disabled, preset }: any) => {
      if (!disabled) {
        if (!preset) {
          return blue[300];
        }
        return theme.palette.primary.main;
      }
      return grey[300];
    },
    overflow: 'hidden',
    backgroundColor: ({ disabled, preset }: any) => {
      if (!disabled) {
        if (!preset) {
          return blue[300]
        } else {
          return theme.palette.primary.main
        }
      } else {
        return grey[300]
      }
    }
  },
  firstHalf: ({ type, disabled, preset }: any) => {
    let bgColor = '#fff';

    if (type === PizzaShapeType.FIRST_HALF || type === PizzaShapeType.FULL) {
      bgColor = theme.palette.primary.main;
      if (!preset) {
        bgColor = blue[300];
      }
    }

    if (type === PizzaShapeType.FIRST_HALF && disabled) {
      bgColor = grey[300];
    }

    if (type === PizzaShapeType.FULL && disabled) {
      bgColor = grey[300];
    }

    return {
      display: 'inline-block',
      backgroundColor: bgColor,
      height: 25,
      width: 13
    };
  },
  secondHalf: ({ type, disabled, preset }: any) => {
    let bgColor = '#fff';

    if (type === PizzaShapeType.SECOND_HALF || type === PizzaShapeType.FULL) {
      bgColor = theme.palette.primary.main;
      if (!preset) {
        bgColor = blue[300];
      }
    }
    if (type === PizzaShapeType.SECOND_HALF && disabled) {
      bgColor = grey[300];
    }

    if (type === PizzaShapeType.FULL && disabled) {
      bgColor = grey[300];
    }

    return {
      display: 'inline-block',
      backgroundColor: bgColor,
      height: 25,
      width: 12
    };
  }
}));
