import React from 'react';
import Firebase from './firebase';

interface IFirebaseContextProps {
  firebase: Firebase;
}

const FirebaseContext = React.createContext<Partial<IFirebaseContextProps>>({});

export default FirebaseContext;
