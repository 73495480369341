import { Box, Button, Link, makeStyles, StepConnector, StepLabel, Stepper, Typography, withStyles } from '@material-ui/core';
import DialogHeader from 'components/DialogHeader';
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import clsx from 'clsx';
import { PubSub } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import { useGetOrderQuery } from 'graphql/hooks';
import { AuthContext } from 'fbase/authContext';
import moment from 'moment-timezone';
import { IDeliveryEvent, IEventState, MapStatusToNumber, TBoonsEventStatus, TDoordashClassicEventStatus } from './types';
import {
  DeliveryOrderBoonsTimeLine,
  DeliveryOrderDoordashClassicTimeLine,
  DeliveryProviders,
  DeliveryStatusBoons,
  DeliveryStatusDoordashClassic,
  Maybe,
  OrderStatus,
  OrderTimeLine
} from 'generated/types';
import { orderTrackingCognitoConfig } from 'util/configureAmplifyCognito';

const errorMessageTitle: { [key: string]: string } = {
  cancelled: 'Delivery has been cancelled',
  auto_cancel: 'Delivery has been auto cancelled',
  delayed: 'Delivery has been delayed',
  returned: 'Delivery has been returned by the customer'
};

PubSub.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: process.env.REACT_APP_AWS_PUBSUB_REGION,
    aws_pubsub_endpoint: process.env.REACT_APP_AWS_PUBSUB_ENDPOINT
  })
);

const CustomConnectorStyle = withStyles({
  alternativeLabel: {},
  active: {
    '& $line': {
      borderLeft: '2px solid #388E3C'
    }
  },
  completed: {
    '& $line': {
      borderLeft: '2px solid #388E3C'
    }
  },
  line: {
    height: 1,
    border: 0,
    borderLeft: '2px dotted #ccc'
  },
  vertical: {
    transform: 'translate(-1%, 0)',
    padding: 0
  },
  lineVertical: {
    transform: 'scale(1, 2)'
  }
})(StepConnector);

const useCustomStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#D6D6D6',
    zIndex: 1,
    color: '#fff',
    width: 20,
    height: 20,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  active: {
    backgroundColor: '#388E3C'
  },
  completed: {
    backgroundColor: '#388E3C'
  }
});

function CustomStepIcon(props: { icon: String; active: boolean; completed: boolean }) {
  const classes = useCustomStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}>
      <FiberManualRecordIcon fontSize="inherit" />
    </div>
  );
}

function Tracking() {
  // const [onBack, setonBack] = useState<Boolean>(true)

  const history = useHistory();

  const { id } = useParams<any>();

  const { currentUser } = useContext(AuthContext);
  const buyerId = currentUser?.uid;

  const { loading, order, error, refetch } = useGetOrderQuery(id, buyerId);

  const [timeLineStatus, setTimeLineStatus] = useState({
    tripCreated: '0',
    inProgress: '0',
    completed: '0',
    assigned: '0',
    picked_up: '0',
    delivered: '0'
  });

  const [timeLineStatusOPS, setTimeLineStatusOPS] = useState<string>();

  const [sellerTimezone, setSellerTimezone] = useState<string>('us/pacific');

  const [dasherDetail, setDasherDetail] = useState({
    dasherName: '',
    dasherNumber: '',
    trackingUrl: ''
  });

  //setting seller timezone
  useEffect(() => {
    if (order) {
      const { i18n } = order.seller;
      const timezone = i18n ? i18n.timezone || 'us/pacific' : 'us/pacific';
      setSellerTimezone(timezone);
    }
  }, [order]);

  const reCallGetOrderByIdQuery = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (order && order.deliveryRequest && order.deliveryRequest.doordashClassicPayload) {
      const trackingUrl = order.deliveryRequest.doordashClassicPayload.delivery_tracking_url ?? '';

      setDasherDetail((prev) => ({ ...prev, trackingUrl: trackingUrl }));

      if (order && order.deliveryRequest && order.deliveryRequest.doordashClassicPayload.dasher) {
        const dasherName = order.deliveryRequest.doordashClassicPayload.dasher.first_name ?? '';
        const lastName = order.deliveryRequest.doordashClassicPayload.dasher.last_name ?? '';

        const dasherPhoneNumber = order.deliveryRequest.doordashClassicPayload.dasher?.dasher_phone_number_for_customer ?? '';

        setDasherDetail((prev) => ({ ...prev, dasherName: dasherName + lastName }));

        setDasherDetail((prev) => ({ ...prev, dasherNumber: dasherPhoneNumber }));
      }
    }
  }, [order, timeLineStatus.picked_up]);

  const getTimestamp = (deliveryStatus: DeliveryStatusBoons | DeliveryStatusDoordashClassic) => {
    let deliveryTimeline: any;
    let timeline = [];

    if (order && order?.deliveryProvider === DeliveryProviders.Boons) {
      deliveryTimeline = order.deliveryTimelineBoons;
    } else if (order && order?.deliveryProvider === DeliveryProviders.DoordashClassic) {
      deliveryTimeline = order.deliveryTimelineDoordashClassic;
    }

    if (!loading && !error && order && deliveryTimeline) {
      if (order.deliveryProvider === DeliveryProviders.Boons) {
        timeline = deliveryTimeline.filter((line: DeliveryOrderBoonsTimeLine) => {
          if (line && line.status === deliveryStatus) {
            return true;
          }
          return false;
        });
      } else if (order.deliveryProvider === DeliveryProviders.DoordashClassic) {
        timeline = deliveryTimeline.filter((line: DeliveryOrderDoordashClassicTimeLine) => {
          if (line.status && line.status === deliveryStatus) {
            return true;
          }
          return false;
        });
      }

      if (timeline[0] && sellerTimezone) {
        let createdAtTime = timeline[0].createdAt;
        let convertedTime = moment.tz(createdAtTime, sellerTimezone).format('LT');
        return convertedTime;
      } else {
        return null;
      }
    } else if (!loading && !error && order && order.deliveryTimelineDoordashClassic) {
      const timeline = order.deliveryTimelineDoordashClassic.filter((order) => {
        const orderTime: Maybe<any> = order;
        if (orderTime && orderTime.status === deliveryStatus) {
          return true;
        }
        return false;
      });

      if (timeline[0] && sellerTimezone) {
        let createdAtTime = timeline[0].createdAt;
        let convertedTime = moment.tz(createdAtTime, sellerTimezone).format('LT');
        return convertedTime;
      } else {
        return null;
      }
    }
    return null;
  };

  const getTimeStampOPS = (orderStatus: OrderStatus) => {
    if (!loading && !error && order && order.timeLines) {
      const timeline = order.timeLines.filter((order) => {
        const orderTime: Maybe<OrderTimeLine> = order;
        if (orderTime && orderTime.status === orderStatus) {
          return true;
        }
        return false;
      });

      if (timeline[0] && sellerTimezone) {
        let createdAtTime = timeline[0].createdAt;
        let convertedTime = moment.tz(createdAtTime, sellerTimezone).format('LT');
        return convertedTime;
      } else {
        return null;
      }
    }
    return null;
  };

  const giveCurrentTimeStamp = (deliveryStatus: DeliveryStatusBoons | DeliveryStatusDoordashClassic, timelineStatus: string, sellerTimezoneprop: string) => {
    let deliveryFromDb = getTimestamp(deliveryStatus);
    if (deliveryFromDb) {
      return deliveryFromDb;
    } else if (timelineStatus !== '0') {
      let momentTime = moment.tz(timelineStatus, sellerTimezoneprop).format('LT');
      return momentTime;
    } else {
      return null;
    }
  };

  const [orderStatus, setOrderStatus] = useState(0);

  const [eventState, setEventState] = useState<IEventState>({
    type: 'initiated',
    isTrue: false,
    reason: '',
    eventTime: ''
  });

  const [orderFromQuery, setOrderFromQuery] = useState({
    orderNumber: 'loading...',
    seller: {
      name: 'loading...',
      phone: 'loading...'
    },
    expectTime: 'loading...'
  });

  const onBack = () => {
    history.replace('/orders', {
      from: `/orders/${id}/tracking`
    });
  };

  const onReceiptView = () => {
    history.replace(`/orders/${id}`, {
      from: `/orders/${id}/tracking`
    });
  };

  useEffect(() => {
    orderTrackingCognitoConfig();
  }, []);

  useEffect(() => {
    //Subscribe to topic (orderId)

    const sub = PubSub.subscribe(`delivery/${id}`).subscribe({
      next: (data: any) => {
        //console.log('MQTT Data->', data);
        const event: IDeliveryEvent = { ...data.value.deliveryEvent };

        const deliveryStatusBoons: TBoonsEventStatus = event.deliveryStatusBoons;
        const deliveryStatusDoordashClassic: TDoordashClassicEventStatus = event.deliveryStatusDoordashClassic;

        const deliveryStatus: TBoonsEventStatus | TDoordashClassicEventStatus = event.deliveryProvider === DeliveryProviders.Boons ? deliveryStatusBoons : deliveryStatusDoordashClassic;

        // timezone related
        let accecptedTime = event.created_on;
        let sellerTimeZn = sellerTimezone ? sellerTimezone : 'us/pacific';
        const sellConvertedTime = moment.tz(accecptedTime, sellerTimeZn).format('LT');

        if (deliveryStatus === 'cancelled') {
          setEventState({ isTrue: true, type: 'cancelled', reason: event.cancelledReason ? event.cancelledReason : null, eventTime: sellConvertedTime });
          // setting timeLine to null
          setTimeLineStatus({
            tripCreated: '0',
            inProgress: '0',
            completed: '0',
            assigned: '0',
            picked_up: '0',
            delivered: '0'
          });
        } else if (deliveryStatus === 'delayed') {
          setEventState({ isTrue: false, type: 'delayed', reason: event.delayReason ? event.delayReason : null, eventTime: sellConvertedTime });
        } else if (deliveryStatus === 'auto_cancel') {
          setEventState({ isTrue: true, type: 'auto_cancel', reason: 'Driver unable to accept the delivery request.', eventTime: sellConvertedTime });
        } else if (deliveryStatus === 'returned') {
          setEventState({ isTrue: true, type: 'returned', reason: '', eventTime: sellConvertedTime });
        } else {
          setEventState({ isTrue: false, type: 'initiated', reason: '', eventTime: sellConvertedTime });
        }

        // used for drawing active line
        setOrderStatus(MapStatusToNumber[deliveryStatus]);

        // mqtt events
        let deliveryEvent = data.value.deliveryEvent;

        if (deliveryStatus === 'accepted') {
          const accecptedTime = deliveryEvent.created_on;
          const accConvertedTime = moment.tz(accecptedTime, sellerTimeZn).format('LT');
          setTimeLineStatusOPS(accConvertedTime);
        } else if (deliveryStatus === 'trip_created') {
          setTimeLineStatus((prevState) => ({
            ...prevState,
            tripCreated: deliveryEvent.created_on
          }));
        } else if (deliveryStatus === 'in_progress') {
          setTimeLineStatus((prevState) => ({
            ...prevState,
            inProgress: deliveryEvent.created_on
          }));
        } else if (deliveryStatus === 'completed') {
          setTimeLineStatus((prevState) => ({
            ...prevState,
            completed: deliveryEvent.created_on
          }));
        } else if (deliveryStatus === 'assigned') {
          reCallGetOrderByIdQuery();
          setTimeLineStatus((prevState) => ({
            ...prevState,
            assigned: deliveryEvent.created_on
          }));
        } else if (deliveryStatus === 'picked_up') {
          setTimeLineStatus((prevState) => ({
            ...prevState,
            picked_up: deliveryEvent.created_on
          }));
        } else if (deliveryStatus === 'delivered') {
          setTimeLineStatus((prevState) => ({
            ...prevState,
            delivered: deliveryEvent.created_on
          }));
        }
      },
      error: (error: unknown) => console.error(error),
      complete: () => console.log('--')
    });

    return function cleanUp() {
      sub.unsubscribe();
    };
  }, [id, reCallGetOrderByIdQuery, sellerTimezone]);

  useEffect(() => {
    if (order && order.items && !error) {
      const { orderNumber, seller, expectTime } = order;
      const { i18n } = order.seller;
      const timezone = i18n ? i18n.timezone || 'us/pacific' : 'us/pacific';
      const expectTimeMoment = moment.tz(expectTime, timezone);
      const expectTimeString = `${expectTimeMoment.format('hh:mm A')}`;
      setOrderFromQuery({
        orderNumber: orderNumber,
        seller: {
          name: seller.name || 'error',
          phone: seller.phone ? seller.phone : 'error'
        },
        expectTime: expectTimeString
      });
    }
  }, [error, order]);

  const TextField = (headingName: string, value: string, url?: string) => (
    <Box display="flex" flexDirection="row" justifyContent="flex-start">
      <Typography style={{ fontWeight: 'bolder' }} variant="caption">
        {headingName}
      </Typography>
      <Box padding={0.5} />
      {value !== '' && <Typography variant="body2">{value}</Typography>}
      {url && (
        <Link href={url} variant="body2" underline="hover" style={{ color: 'blue' }}>
          Click Me
        </Link>
      )}
    </Box>
  );

  const getErrorBox = () => {
    if (eventState.isTrue || eventState.type === 'delayed') {
      return (
        <Box borderRadius={5} paddingY={2} width="100%" bgcolor="#EA344F" marginTop={2}>
          {/* heading */}
          <Box paddingX={2} color="#FFF" display="flex" flexDirection="column" alignItems="flex-start" justifyContent="space-between">
            <Box marginBottom={1}>{orderFromQuery && <Typography style={{ fontWeight: 'bolder' }}>{errorMessageTitle[eventState.type]}</Typography>}</Box>
            {eventState.reason && TextField(eventState.reason, '')}
          </Box>
        </Box>
      );
    }
  };

  return (
    <>
      <DialogHeader text="Order Tracking" onBack={onBack} />
      {/* top grey box */}
      <Box bgcolor="#F4F4F6" display="flex" flexDirection="column" padding={2}>
        {/* inside white box */}
        <Box borderRadius={5} paddingY={2} width="100%" bgcolor="#FFFFFF">
          {/* heading */}
          <Box paddingX={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            {orderFromQuery && <Typography>{orderFromQuery.seller.name}</Typography>}

            {TextField('Arriving at:', orderFromQuery.expectTime)}
          </Box>
          {/* parent body */}
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Box paddingX={2}>
              <Box padding={1} />
              {orderFromQuery && TextField('Order:', orderFromQuery.orderNumber)}

              {orderFromQuery && TextField('Phone:', orderFromQuery.seller.phone)}
            </Box>
            <Box paddingX={2}>
              <Button onClick={onReceiptView} color="primary" variant="contained" size="small" disableElevation={true}>
                Receipt View
              </Button>
            </Box>
          </Box>
        </Box>
        {getErrorBox()}
      </Box>
      {/* <Box>
            <Typography variant="body1">On time</Typography>
          </Box> */}
      {/* bottom order deliveryStatus */}
      <Box paddingX={1}>
        {/* doordash dasher detail */}
        {(dasherDetail.trackingUrl || dasherDetail.dasherName) && !eventState.isTrue && (
          <Box paddingX={1} paddingTop={2}>
            <Box paddingY={1}>
              <Typography variant="h5">Delivery Detail</Typography>
            </Box>
            {dasherDetail.dasherName !== '' && TextField('Dasher Name:', `${dasherDetail.dasherName}`)}
            {dasherDetail.dasherNumber !== '' && TextField('Dasher Phone number:', `${dasherDetail.dasherNumber}`)}
            {TextField('Tacking Url:', '', `${dasherDetail.trackingUrl}`)}
          </Box>
        )}
        {/*heading of order deliveryStatus*/}
        <Box paddingX={1} paddingTop={2}>
          <Typography variant="h5">Order Status</Typography>
        </Box>
        <Box style={{ background: 'white' }}>
          <Stepper style={{ maxWidth: 'fit-content' }} activeStep={orderStatus} orientation="vertical" connector={<CustomConnectorStyle />}>
            {/* Order Initiated 0 */}
            <StepLabel StepIconComponent={CustomStepIcon}>
              <Box paddingLeft={2}>
                <Typography variant="caption">Order placed successfully</Typography>
                <br />
                <Typography variant="body2">{getTimeStampOPS(OrderStatus.Placed)}</Typography>
              </Box>
            </StepLabel>

            <StepLabel StepIconComponent={CustomStepIcon}>
              <Box paddingLeft={2}>
                <Typography variant="caption">Order confirmed</Typography>
                <br />
                <Typography variant="body2">
                  {getTimeStampOPS(OrderStatus.Accepted) !== ('' || null) && timeLineStatusOPS !== '' ? getTimeStampOPS(OrderStatus.Accepted) : timeLineStatusOPS}
                  {getTimeStampOPS(OrderStatus.Accepted) !== ('' || null) && timeLineStatusOPS === '' && getTimeStampOPS(OrderStatus.Accepted)}
                </Typography>
              </Box>
            </StepLabel>

            {!eventState.isTrue && (
              <StepLabel StepIconComponent={CustomStepIcon}>
                <Box paddingLeft={2}>
                  <Typography variant="caption">Your order is assigned to a dasher</Typography>
                  <br />
                  <Typography variant="body2">
                    {sellerTimezone && giveCurrentTimeStamp(DeliveryStatusBoons.TripCreated, timeLineStatus.tripCreated, sellerTimezone)}
                    {sellerTimezone && giveCurrentTimeStamp(DeliveryStatusDoordashClassic.Assigned, timeLineStatus.assigned, sellerTimezone)}
                  </Typography>
                </Box>
              </StepLabel>
            )}

            {!eventState.isTrue && (
              <StepLabel StepIconComponent={CustomStepIcon}>
                <Box paddingLeft={2}>
                  <Typography variant="caption">The dasher is enroute for delivery</Typography>
                  <br />
                  <Typography variant="body2">
                    {
                      (sellerTimezone && giveCurrentTimeStamp(DeliveryStatusBoons.InProgress, timeLineStatus.inProgress, sellerTimezone),
                      sellerTimezone && giveCurrentTimeStamp(DeliveryStatusDoordashClassic.PickedUp, timeLineStatus.picked_up, sellerTimezone))
                    }
                  </Typography>
                </Box>
              </StepLabel>
            )}

            {!eventState.isTrue && (
              <StepLabel StepIconComponent={CustomStepIcon}>
                <Box paddingLeft={2}>
                  <Typography variant="caption">Order delivered successfully</Typography>
                  <br />
                  <Typography variant="body2">
                    {sellerTimezone && giveCurrentTimeStamp(DeliveryStatusBoons.Completed, timeLineStatus.completed, sellerTimezone)}
                    {sellerTimezone && giveCurrentTimeStamp(DeliveryStatusDoordashClassic.Delivered, timeLineStatus.delivered, sellerTimezone)}
                  </Typography>
                </Box>
              </StepLabel>
            )}

            {eventState.isTrue && (
              <StepLabel error={true} StepIconComponent={CustomStepIcon}>
                <Box paddingLeft={2}>
                  <Typography variant="caption">Sorry Delivery was cancelled</Typography>
                  <br />
                  <Typography variant="body2">{eventState.eventTime}</Typography>
                </Box>
              </StepLabel>
            )}
          </Stepper>
        </Box>
      </Box>
    </>
  );
}

export default Tracking;
