import React, { ChangeEvent, useState } from 'react';
import { useStyles } from './style';
import { getIn, useFormik } from 'formik';
import { useUpdateUserMutation } from 'graphql/hooks';
import { getLokobeeUserQuery } from 'graphql/query';
import { Country } from 'react-phone-number-input';
import { useAlert } from 'hooks';
import DialogHeader from 'components/DialogHeader';
import { useStore } from 'store';
import { Dialog, Box, Button, Typography, TextField } from '@material-ui/core';
import { isEmpty } from 'lodash';
import PhoneInput from 'react-phone-number-input';
import validate from './validations';
interface IProps {
  open: boolean;
  onHandleClose: (submitted: boolean) => void;
}

const ContactDetailsFormForLokobeeUser: React.FC<IProps> = ({ open, onHandleClose }: IProps) => {
  const classes = useStyles();

  const [currentCountry, setCurrentCountry] = useState<string>('US');

  const { updateUser } = useUpdateUserMutation();

  const { lokoAlert } = useAlert();

  const { dispatch } = useStore();

  const { state } = useStore();

  const orderPhoneNumber = state && state.user ? state.user.orderPhoneNumber : '';

  const email = state && state.user ? state.user.email : '';

  const setUserInStore = (phoneNumber: string, email: string) => {
    const input = {
      phoneNumber,
      email
    };

    dispatch({
      type: 'USER_ACTION_SET_CONTACT_DETAILS',
      payload: input
    });
  };

  const formik = useFormik({
    initialValues: {
      phonenumber: orderPhoneNumber || '',
      rephonenumber: orderPhoneNumber || '',
      email: email || ''
    },
    enableReinitialize: true,
    validate,
    onSubmit: async (values) => {
      const { phonenumber, email: updatedEmail } = values;

      formik.setSubmitting(true);

      try {
        const response = await updateUser({
          variables: {
            input: {
              orderPhoneNumber: phonenumber,
              orderEmail: updatedEmail !== email ? updatedEmail : undefined
            }
          },
          update: (cache) => {
            const { getLokobeeUser: oldData }: any = cache.readQuery({
              query: getLokobeeUserQuery
            });
            cache.writeQuery({
              query: getLokobeeUserQuery,
              data: { getLokobeeUser: { ...oldData, orderPhoneNumber: phonenumber, orderEmail: updatedEmail } }
            });
          }
        });
        if (!response.errors) {
          setUserInStore(phonenumber, updatedEmail);
          onHandleClose(true);
          // history.push('/cart/select-type');
        } else {
          lokoAlert('Something went wrong.');
        }
      } catch (e) {
        console.log(e);
        formik.setSubmitting(false);
        lokoAlert('Something went wrong.');
      }
    }
  });

  return (
    <Dialog open={open} fullScreen>
      <DialogHeader text="Add your phone number" onBack={() => onHandleClose(false)} />
      <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
        <form>
          <Box>
            <Box display="flex" alignItems="center" justifyContent="center" paddingTop={6}>
              <Typography variant="body1">Contact Details</Typography>
            </Box>
            <Box display="flex" alignItems="center" paddingX={6} paddingY={2}>
              <Typography variant="caption" align="center">
                Please enter your contact details for the communication purposes and online order status update.
              </Typography>
            </Box>
            <Box paddingX={3}>
              <Box marginBottom={1}>
                <TextField
                  error={!!getIn(formik.touched, 'email') && !!getIn(formik.errors, 'email')}
                  helperText={!!getIn(formik.touched, 'email') && getIn(formik.errors, 'email')}
                  label="Email Address"
                  type={'email'}
                  name="email"
                  variant="outlined"
                  value={formik.values.email}
                  fullWidth
                  required={true}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  autoComplete="off"
                />
              </Box>

              <PhoneInput
                className={classes.phoneInputField}
                name="phonenumber"
                placeholder="Enter Your Phone number"
                defaultCountry={currentCountry as Country}
                value={formik.values.phonenumber}
                autoComplete="off"
                onCountryChange={(country) => {
                  country && setCurrentCountry(country);
                }}
                onBlur={formik.handleBlur('phonenumber')}
                onChange={(val: string) => formik.handleChange({ target: { value: val, name: 'phonenumber' } } as ChangeEvent<HTMLInputElement>)}
              />
              <PhoneInput
                className={classes.phoneInputField}
                name="rephonenumber"
                placeholder="Confirm Your Phone number"
                defaultCountry={currentCountry as Country}
                value={formik.values.rephonenumber}
                countrySelectProps={{ disabled: true }}
                autoComplete="off"
                onBlur={formik.handleBlur('rephonenumber')}
                onPasteCapture={(e: React.ClipboardEvent<string>) => {
                  e.preventDefault();
                  return false;
                }}
                onChange={(val: string) => formik.handleChange({ target: { value: val, name: 'rephonenumber' } } as ChangeEvent<HTMLInputElement>)}
              />
              {!!getIn(formik.touched, 'phonenumber') && !!getIn(formik.errors, 'phonenumber') && (
                <Box paddingX={1} marginBottom={1}>
                  <Typography variant="body1" color="error">
                    {getIn(formik.errors, 'phonenumber')}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>

          <Box paddingX={3} paddingBottom={3}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={!isEmpty(formik.errors) || formik.isSubmitting || isEmpty(formik.values)}
              onClick={() => formik.handleSubmit()}>
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
};

export default ContactDetailsFormForLokobeeUser;
