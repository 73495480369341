import { gql } from 'apollo-boost';
import { Profile, BusinessHours, Table, Announcement, Gallery, RewardSetting, BizPlan } from 'graphql/fragments/restaurant.fragments';

export const getRestaurantsByLocation = gql`
  query getRestaurantsByLocation($input: GetRestaurantsNearbyInput!) {
    getRestaurantsByLocation(input: $input) {
      edges {
        node {
          id
          rateScore
          rateCount
          issueRewardPoint
          enableTakeoutOrder
          enableDiningOrder
          enableDeliveryOrder
          enableDiningOrder
          name
          subName
          description
          logo {
            id
            url
          }
          images {
            id
            md {
              id
              url
            }
            preview {
              id
              url
            }
          }
          address {
            lat
            lng
          }
          i18n {
            timezone
          }
          coupons {
            code
            discountType
            discountValue
            startDate
            endDate
            maxDiscount
          }

          cryptoCouponEnabled
          cryptoCouponExists
        }
        cursor
      }
    }
  }
`;

export const getRestaurantsByLocationWithUserRewards = gql`
  query getRestaurantsByLocationWithUserRewards($input: GetRestaurantsNearbyInput!) {
    getRestaurantsByLocation: getRestaurantsByLocation(input: $input) {
      edges {
        node {
          id
          name
          description
          logo {
            id
            url
          }
          address {
            lat
            lng
          }
          coupons {
            code
            discountType
            discountValue
            startDate
            endDate
            maxDiscount
          }
          issueRewardPoint
        }
        cursor
      }
    }
    getRewardPointsForConsumer: getRewardPointsForConsumer(input: {}) {
      id
      issuer
      activePoints
    }
  }
`;

export const getRestaurantId = gql`
  query getRestaurantId($input: GetRestaurantInput!) {
    getRestaurant(input: $input) {
      id
    }
  }
`;

export const getRestaurantProfile = gql`
  query getRestaurantProfile($input: GetRestaurantInput!) {
    getRestaurant(input: $input) {
      id
      ...Profile
    }
  }
  ${Profile}
`;

export const getRestaurantBusinsessHours = gql`
  query getRestaurantBusinsessHours($input: GetRestaurantInput!) {
    getRestaurant(input: $input) {
      id
      ...BusinessHours
    }
  }
  ${BusinessHours}
`;

export const getRestaurantTables = gql`
  query getRestaurantTables($input: GetRestaurantInput!) {
    getRestaurant(input: $input) {
      id
      ...Table
    }
  }
  ${Table}
`;

export const getRestaurantGallery = gql`
  query getRestaurantGallery($input: GetRestaurantInput!) {
    getRestaurant(input: $input) {
      id
      ...Gallery
    }
  }
  ${Gallery}
`;

export const getRestaurantAnnouncement = gql`
  query getRestaurantAnnouncement($input: GetRestaurantInput!) {
    getRestaurant(input: $input) {
      id
      ...Announcement
    }
  }
  ${Announcement}
`;

export const getRestaurantRewardSetting = gql`
  query getRestaurantRewardSetting($input: GetRestaurantInput!) {
    getRestaurant(input: $input) {
      id
      issueRewardPoint
      ...RewardSetting
    }
  }
  ${RewardSetting}
`;

export const getRestaurant = gql`
  query getRestaurant($input: GetRestaurantInput!) {
    getRestaurant(input: $input) {
      id
      isLokobeePartner
      lokobeeFeePercent
      lokobeeFeeLimit
      issueRewardPoint
      enableDishNote
      takeoutWaitMinutes
      enableTakeoutOrder
      enableDiningOrder
      enableDiningOrder
      enableDeliveryOrder
      hasStripeAccount
      lokobeeFee
      deliveryMethod
      deliveryProviders
      takeoutPaymentSetting {
        tipPercentOptions
        tipDefaultPercent
        onlinePaymentTypes
        onsitePaymentTypes
      }
      diningPaymentSetting {
        tipPercentOptions
        tipDefaultPercent
        onlinePaymentTypes
        onsitePaymentTypes
      }
      deliveryPaymentSetting {
        tipPercentOptions
        tipDefaultPercent
        onlinePaymentTypes
        onsitePaymentTypes
      }
      deliverySetting {
        waitMinutes
        deliveryRadius
        freeDeliveryAmount {
          intValue
          shift
        }
        minDeliveryAmount {
          intValue
          shift
        }
        fees {
          mile
          fee {
            intValue
            shift
          }
        }
      }
      ...Announcement
      ...Profile
      ...BusinessHours
      ...Gallery
      ...RewardSetting
      ...BizPlan
    }
  }
  ${Profile}
  ${BusinessHours}
  ${Gallery}
  ${Announcement}
  ${RewardSetting}
  ${BizPlan}
`;

export const getRestaurantProfileAndBizHours = gql`
  query getRestaurantProfileAndBizHours($input: GetRestaurantInput!) {
    getRestaurant(input: $input) {
      id
      ...Profile
      ...BusinessHours
    }
  }
  ${Profile}
  ${BusinessHours}
`;

export const getRestaurantTakeOutPaymentSetting = gql`
  query getRestaurantTakeOutPaymentSetting($input: GetRestaurantInput!) {
    getRestaurant(input: $input) {
      id
      takeoutPaymentSetting {
        tipPercentOptions
        tipDefaultPercent
        onlinePaymentTypes
        onsitePaymentTypes
      }
      diningPaymentSetting {
        tipPercentOptions
        tipDefaultPercent
        onlinePaymentTypes
        onsitePaymentTypes
      }
      deliveryPaymentSetting {
        tipPercentOptions
        tipDefaultPercent
        onlinePaymentTypes
        onsitePaymentTypes
      }
    }
  }
`;
