import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { ReactComponent as Logo } from './loading.svg';
import { useStyles } from './style';

const OrderProcessingScreen: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root} display="flex" flex={1} alignItems="center" flexDirection="column" justifyContent="center">
      <Box marginBottom={2}>
        <Logo width="100" height="100" />
      </Box>
      <Typography variant="body1">Please wait while we process your order...</Typography>
    </Box>
  );
};

export default OrderProcessingScreen;
