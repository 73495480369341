import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import { config } from './config';

class Firebase {
  constructor() {
    try {
      if (!firebase.apps.length) {
        firebase.initializeApp(config);
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Asynchronously signs in as an anonymous user.
   *
   * @returns {Promise<firebase.auth.UserCredential>}
   */
  signInAnonymously = () => {
    const { auth } = firebase;

    return auth().signInAnonymously();
  };

  getAuth = () => {
    return firebase.auth;
  };

  getFireStore = () => {
    return firebase.firestore();
  };

  getRealtimeDB = () => {
    return firebase.database();
  };

  confirmOtp = async (code: string) => {
    try {
      if ((window as any).confirmationResult) {
        const result = await (window as any).confirmationResult.confirm(code);
        const { user } = result;

        return user;
      } else {
        throw Error();
      }
    } catch (e) {
      throw Error('Confirmation Error');
    }
  };

  /**
   * Render recaptch
   */
  initializeRecatpcha = () => {
    const { auth } = firebase;

    if (auth) {
      (window as any).recaptchaVerifier = new auth.RecaptchaVerifier('recaptcha', {
        size: 'normal',
        callback: () => {},
        'expired-callback': () => {}
      });

      if (window) {
        (window as any).recaptchaVerifier.render().then((widgetId: any) => {
          (window as any).recaptchaWidgetId = widgetId;
        });
      }
    }
  };

  signInWithEmailAndPassword = (email: string, password: string) => {
    const { auth } = firebase;

    return auth().signInWithEmailAndPassword(email, password);
  };

  /**
   * This method signs in user in firebase using phone number.
   *
   * @param phone - Phone number
   */
  signInWithFirebase = (phone: string) => {
    const { auth } = firebase;

    return auth()
      .signInWithPhoneNumber(phone, (window as any).recaptchaVerifier)
      .then((confirmationResult: any) => {
        (window as any).confirmationResult = confirmationResult;
      });
  };

  linkAnonymousUserWithEmailAndPassword = async ({ email, password, phone }: { email: string; password: string; phone: string }) => {
    const { auth } = firebase;

    try {
      const credential = auth.EmailAuthProvider.credential(email, password);

      const currentUser = auth().currentUser;

      if (currentUser) {
        const userCred = await currentUser.linkWithCredential(credential);

        userCred.user?.updateProfile({});
      }
    } catch (e) {
      throw e;
    }
  };

  /**
   * Sign out current user
   */
  signOut = () => {
    const { auth } = firebase;

    return auth().signOut();
  };
}

export default Firebase;
