import React, { useState, useEffect } from 'react';
import { Button, ButtonProps } from '@material-ui/core';

interface IProps extends ButtonProps {
  onClick: () => void;
}

const Resend: React.FC<IProps> = ({ onClick, ...rest }) => {
  const [counter, setCounter] = useState(120);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return (
    <Button variant="text" disabled={counter !== 0} onClick={onClick} {...rest} disableElevation={true}>
      Resend {counter > 0 ? counter : null}
    </Button>
  );
};

export default Resend;
