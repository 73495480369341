import React, { CSSProperties } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { find, compact, map, groupBy } from 'lodash';
import { ICheckoutSummary } from './types';
import { useStyles } from './style';
import { grey } from '@material-ui/core/colors';
import { Stepper } from '@lokobee/lokobee-ui';
import { useStore } from 'store';
import { DishType, PizzaToppingDensity } from 'generated/types';
import PizzaShape from 'components/PizzaShape';
import { PizzaShapeType } from 'generated/custom';
import { useReward } from 'hooks/restaurant';
import Big from 'big.js';
import { useAlert } from 'hooks';
import useCart from 'hooks/useCart';
import LiquorIcon from 'assets/img/liquor.svg';

interface IProps {
  summary: ICheckoutSummary;
}

const applyStyle = (flag: boolean | null | undefined, lineThrough: boolean): CSSProperties => {
  return flag ? { color: 'red', textDecoration: lineThrough ? 'line-through' : 'none' } : {};
};

const CheckoutSummary = ({ summary }: IProps) => {
  const classes = useStyles();

  const { dispatch } = useStore();

  const { lokoAlert } = useAlert();

  const { minimumPurchaseRequired, incUsedPoints, decUsedPoints, isIncPossible } = useReward();

  const { subtotal, cartRewardItems } = useCart();

  const { dishes, currency } = summary;

  const onQuantityChange = ({
    dishId,
    quantity,
    prevQuantity,
    rewardPoints,
    dishPrice,
    dishTitle
  }: {
    dishId: string;
    quantity: number;
    prevQuantity: number;
    rewardPoints: number;
    dishPrice: string | number;
    dishTitle: string;
  }) => {
    /**
     * If qauntity gets zero remove item from cart. Else set qauntity of dish.
     */
    if (currency === 'POINT') {
      //Incrementing/Decrementing reward points
      const pointsToAdd = Big(rewardPoints)
        .div(prevQuantity)
        .toString();

      if (quantity > prevQuantity) {
        //On quantity incremented
        const isIncPossibleRes = isIncPossible(pointsToAdd, dishTitle);

        if (!isIncPossibleRes.isPossible) {
          lokoAlert(isIncPossibleRes.message);

          return;
        }

        incUsedPoints(pointsToAdd);
      } else {
        //On quantity decremented
        decUsedPoints(pointsToAdd);
      }
    }

    //Checking cart subtotal against minimumPurchaseRequired
    if (currency === 'USD') {
      if (quantity < prevQuantity) {
        const price = Big(dishPrice) //Getting dish price
          .div(prevQuantity)
          .toString();

        const isMinPurReq = Big(subtotal) //Checking if subtotal after item qty reduced is less than minimumPurchaseRequired
          .sub(price)
          .lt(minimumPurchaseRequired);

        if (isMinPurReq && !!cartRewardItems.length) {
          lokoAlert(`A minimum purchase of <b> $${minimumPurchaseRequired} </b> is required to checkout with reward items`);

          return;
        }
      }
    }

    if (quantity === 0) {
      const type = currency === 'USD' ? 'CART_ACTION_REMOVE_ITEM' : 'CART_ACTION_REMOVE_REWARD_ITEM';

      dispatch({
        type,
        payload: {
          uuid: dishId
        }
      });
    } else {
      const type = currency === 'USD' ? 'CART_ACTION_SET_QUANTITY' : 'CART_ACTION_SET_REWARD_QUANTITY';

      dispatch({
        type,
        payload: {
          itemId: dishId,
          quantity
        }
      });
    }
  };

  const render = () => {
    return map(dishes, (dish, index) => {
      const {
        quantity,
        note,
        isPreDiscounted,
        priceStriked,
        discountPercent,
        price,
        rewardPoints,
        isAlcohol,
        title,
        extras,
        uuid,
        size,
        type,
        pizzaBasePrice,
        pizzaCrust,
        pizzaActiveSizeId,
        pizzaActiveCrustId,
        pizzaCheeseOrderLineItems,
        pizzaSaucesOrderLineItems,
        pizzaToppingGroupsOrderLineItems,
        pizzaToppingGroups,
        pizzaCheese,
        pizzaSauce
      } = dish;

      if (type === DishType.Regular) {
        return (
          <Grid key={index} container={true} className={classes.dishContainer}>
            <Grid xs={8} item={true}>
              <Typography variant="body1">{title}</Typography>
              <Box display="flex" alignItems="center">
                <Box marginRight={0.5}>
                  <Typography variant="body2">{size}</Typography>
                </Box>
                {isAlcohol && <img src={LiquorIcon} alt="" />}
              </Box>
              {!!extras &&
                !!extras.length &&
                map(extras, ({ title: extraGroupTitle, items }, extraGroupIndex) => {
                  const mappedItemPrice = map(items, ({ title: itemTitle }) => itemTitle);
                  return (
                    <>
                      <Typography variant="caption" component="div" key={extraGroupIndex}>
                        <span className={classes.bold}>{extraGroupTitle}:</span>
                      </Typography>
                      <Typography variant="caption" component="div" key={extraGroupIndex}>
                        {mappedItemPrice.join(', ')}
                      </Typography>
                    </>
                  );
                })}
              <br />
              {note && (
                <Typography variant="caption">
                  <span className={classes.bold}>Dish Note : </span>
                  {note}
                </Typography>
              )}
            </Grid>
            <Grid xs={4} item={true}>
              <Box textAlign="right">
                <Stepper value={quantity} onChange={(value) => onQuantityChange({ dishId: uuid, quantity: value, prevQuantity: quantity, rewardPoints, dishPrice: price, dishTitle: title })} />
              </Box>
              <Box paddingY={1} display="flex" justifyContent="flex-end" alignItems="baseline">
                {isPreDiscounted && priceStriked && (
                  <Box className={classes.priceItem}>
                    <Typography className={classes.priceStriked} variant="body2" align="right">
                      ${priceStriked}
                    </Typography>
                  </Box>
                )}
                <Box className={classes.priceItem}>
                  <Typography variant="body2" align="right">
                    {currency === 'USD' ? `$${price}` : `${rewardPoints} PTS`}
                  </Typography>
                </Box>
                {discountPercent && (
                  <Box className={classes.priceItem}>
                    <Typography className={classes.priceDiscount} variant="body2" align="right">
                      {discountPercent}%&nbsp;OFF
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        );
      }

      if (type === DishType.Pizza) {
        // Fetch active base id title.
        const pizzaSelectedBaseTItle = find(pizzaBasePrice, ({ size }) => size.id === pizzaActiveSizeId)?.size.title;

        // Fetch active crust title.
        const pizzaSelectedCrustTitle = find(pizzaCrust, ({ id }) => id === pizzaActiveCrustId)?.title;

        const options = [];

        const groupedCheese = groupBy(pizzaCheeseOrderLineItems, 'toppingId');

        const cheeseOptionItemArray = compact(
          map(pizzaCheese && pizzaCheese.items ? pizzaCheese.items : [], ({ id, title, preset }, cheeseIndex) => {
            if (groupedCheese[id]) {
              /*
               * Topping items grouped by toppingId
               * if group have multiple items - left right both available
               * If multiple items
               *      - one of them none - show the other half with selected density
               *      - preset and both none - show empty circle and red line
               *      - both of them other than none and same density - show whole circle and density once
               *      - both of them other than none and diff density - show whole circle & both density
               * if single item
               *      - show selected half & density
               * non preset items will have none desity
               *      - so if multiple items show whole circle & both density
               *      - if single item - show selected half & density
               *
               */
              if (preset) {
                if (groupedCheese[id].length > 1) {
                  const left = groupedCheese[id][0];
                  const right = groupedCheese[id][1];

                  let pizzaType = PizzaShapeType.NONE;
                  let density = '';

                  if (left.toppingDensity === PizzaToppingDensity.None && right.toppingDensity !== PizzaToppingDensity.None) {
                    pizzaType = PizzaShapeType.SECOND_HALF;
                    density = right.toppingDensity;
                  } else if (left.toppingDensity !== PizzaToppingDensity.None && right.toppingDensity === PizzaToppingDensity.None) {
                    pizzaType = PizzaShapeType.FIRST_HALF;
                    density = left.toppingDensity;
                  } else if (left.toppingDensity === PizzaToppingDensity.None && right.toppingDensity === PizzaToppingDensity.None) {
                    pizzaType = PizzaShapeType.NONE;
                    density = PizzaToppingDensity.None;
                  } else {
                    pizzaType = PizzaShapeType.FULL;
                    if (left.toppingDensity === right.toppingDensity) {
                      density = left.toppingDensity;
                    } else {
                      density = `${left.toppingDensity},${right.toppingDensity}`;
                    }
                  }

                  return (
                    <Box display="flex" justifyItems="center" alignItems="center" marginBottom={1} key={cheeseIndex}>
                      <Box flex={1}>
                        <Typography variant="body2" style={applyStyle(density === PizzaToppingDensity.None && pizzaType === PizzaShapeType.NONE, true)}>
                          {title} : {density}
                        </Typography>
                      </Box>
                      <Box component="span" marginLeft={2}>
                        <PizzaShape type={pizzaType} preset={preset} />
                      </Box>
                    </Box>
                  );
                } else {
                  const lineItem = groupedCheese[id][0];

                  const density = lineItem.toppingDensity;
                  const side = lineItem.side;

                  let pizzaType = PizzaShapeType.NONE;

                  if (side === 'Whole') {
                    pizzaType = PizzaShapeType.FULL;
                  }

                  if (side === 'Left') {
                    pizzaType = PizzaShapeType.FIRST_HALF;
                  }

                  if (side === 'Right') {
                    pizzaType = PizzaShapeType.SECOND_HALF;
                  }

                  if (lineItem.toppingDensity === PizzaToppingDensity.None) {
                    pizzaType = PizzaShapeType.NONE;
                  }

                  return (
                    <Box display="flex" justifyItems="center" alignItems="center" marginBottom={1} key={cheeseIndex}>
                      <Box flex={1}>
                        <Typography variant="body2" style={applyStyle(density === PizzaToppingDensity.None && pizzaType === PizzaShapeType.NONE, true)}>
                          {title} : {density}
                        </Typography>
                      </Box>
                      <Box component="span" marginLeft={2}>
                        <PizzaShape type={pizzaType} preset={preset} />
                      </Box>
                    </Box>
                  );
                }
              } else {
                if (groupedCheese[id].length > 1) {
                  const left = groupedCheese[id][0];
                  const right = groupedCheese[id][1];

                  let pizzaType = PizzaShapeType.FULL;
                  let density = '';

                  if (left.toppingDensity === right.toppingDensity) {
                    density = left.toppingDensity;
                  } else {
                    density = `${left.toppingDensity},${right.toppingDensity}`;
                  }
                  return (
                    <Box display="flex" justifyItems="center" alignItems="center" marginBottom={1} key={cheeseIndex}>
                      <Box flex={1}>
                        <Typography variant="body2">
                          {title} : {density}
                        </Typography>
                      </Box>
                      <Box component="span" marginLeft={2}>
                        <PizzaShape type={pizzaType} preset={preset} />
                      </Box>
                    </Box>
                  );
                } else {
                  const lineItem = groupedCheese[id][0];

                  const density = lineItem.toppingDensity;
                  const side = lineItem.side;

                  let pizzaType = PizzaShapeType.NONE;

                  if (side === 'Whole') {
                    pizzaType = PizzaShapeType.FULL;
                  }

                  if (side === 'Left') {
                    pizzaType = PizzaShapeType.FIRST_HALF;
                  }

                  if (side === 'Right') {
                    pizzaType = PizzaShapeType.SECOND_HALF;
                  }

                  if (lineItem.toppingDensity === PizzaToppingDensity.None) {
                    pizzaType = PizzaShapeType.NONE;
                  }

                  return (
                    <Box display="flex" justifyItems="center" alignItems="center" marginBottom={1} key={cheeseIndex}>
                      <Box flex={1}>
                        <Typography variant="body2" style={applyStyle(density === PizzaToppingDensity.None && pizzaType === PizzaShapeType.NONE, true)}>
                          {title} : {density}
                        </Typography>
                      </Box>
                      <Box component="span" marginLeft={2}>
                        <PizzaShape type={pizzaType} preset={preset} />
                      </Box>
                    </Box>
                  );
                }
              }
            }

            return null;
          })
        );

        const groupedSauces = groupBy(pizzaSaucesOrderLineItems, 'toppingId');

        const sauceOptionItemArray = compact(
          map(pizzaSauce && pizzaSauce.items ? pizzaSauce.items : [], ({ id, title, preset }, sauceIndex) => {
            if (groupedSauces[id]) {
              if (preset) {
                if (groupedSauces[id].length > 1) {
                  const left = groupedSauces[id][0];
                  const right = groupedSauces[id][1];

                  let pizzaType = PizzaShapeType.NONE;
                  let density = '';

                  if (left.toppingDensity === PizzaToppingDensity.None && right.toppingDensity !== PizzaToppingDensity.None) {
                    pizzaType = PizzaShapeType.SECOND_HALF;
                    density = right.toppingDensity;
                  } else if (left.toppingDensity !== PizzaToppingDensity.None && right.toppingDensity === PizzaToppingDensity.None) {
                    pizzaType = PizzaShapeType.FIRST_HALF;
                    density = left.toppingDensity;
                  } else if (left.toppingDensity === PizzaToppingDensity.None && right.toppingDensity === PizzaToppingDensity.None) {
                    pizzaType = PizzaShapeType.NONE;
                    density = PizzaToppingDensity.None;
                  } else {
                    pizzaType = PizzaShapeType.FULL;
                    if (left.toppingDensity === right.toppingDensity) {
                      density = left.toppingDensity;
                    } else {
                      density = `${left.toppingDensity},${right.toppingDensity}`;
                    }
                  }

                  return (
                    <Box display="flex" justifyItems="center" alignItems="center" marginBottom={1} key={sauceIndex}>
                      <Box flex={1}>
                        <Typography variant="body2" style={applyStyle(density === PizzaToppingDensity.None && pizzaType === PizzaShapeType.NONE, true)}>
                          {title} : {density}
                        </Typography>
                      </Box>
                      <Box component="span" marginLeft={2}>
                        <PizzaShape type={pizzaType} preset={preset} />
                      </Box>
                    </Box>
                  );
                } else {
                  const lineItem = groupedSauces[id][0];

                  const density = lineItem.toppingDensity;
                  const side = lineItem.side;

                  let pizzaType = PizzaShapeType.NONE;

                  if (side === 'Whole') {
                    pizzaType = PizzaShapeType.FULL;
                  }

                  if (side === 'Left') {
                    pizzaType = PizzaShapeType.FIRST_HALF;
                  }

                  if (side === 'Right') {
                    pizzaType = PizzaShapeType.SECOND_HALF;
                  }

                  if (lineItem.toppingDensity === PizzaToppingDensity.None) {
                    pizzaType = PizzaShapeType.NONE;
                  }

                  return (
                    <Box display="flex" justifyItems="center" alignItems="center" marginBottom={1} key={sauceIndex}>
                      <Box flex={1}>
                        <Typography variant="body2" style={applyStyle(density === PizzaToppingDensity.None && pizzaType === PizzaShapeType.NONE, true)}>
                          {title} : {density}
                        </Typography>
                      </Box>
                      <Box component="span" marginLeft={2}>
                        <PizzaShape type={pizzaType} preset={preset} />
                      </Box>
                    </Box>
                  );
                }
              } else {
                if (groupedSauces[id].length > 1) {
                  const left = groupedSauces[id][0];
                  const right = groupedSauces[id][1];

                  let pizzaType = PizzaShapeType.FULL;
                  let density = '';

                  if (left.toppingDensity === right.toppingDensity) {
                    density = left.toppingDensity;
                  } else {
                    density = `${left.toppingDensity},${right.toppingDensity}`;
                  }
                  return (
                    <Box display="flex" justifyItems="center" alignItems="center" marginBottom={1} key={sauceIndex}>
                      <Box flex={1}>
                        <Typography variant="body2">
                          {title} : {density}
                        </Typography>
                      </Box>
                      <Box component="span" marginLeft={2}>
                        <PizzaShape type={pizzaType} preset={preset} />
                      </Box>
                    </Box>
                  );
                } else {
                  const lineItem = groupedSauces[id][0];

                  const density = lineItem.toppingDensity;
                  const side = lineItem.side;

                  let pizzaType = PizzaShapeType.NONE;

                  if (side === 'Whole') {
                    pizzaType = PizzaShapeType.FULL;
                  }

                  if (side === 'Left') {
                    pizzaType = PizzaShapeType.FIRST_HALF;
                  }

                  if (side === 'Right') {
                    pizzaType = PizzaShapeType.SECOND_HALF;
                  }

                  if (lineItem.toppingDensity === PizzaToppingDensity.None) {
                    pizzaType = PizzaShapeType.NONE;
                  }

                  return (
                    <Box display="flex" justifyItems="center" alignItems="center" marginBottom={1} key={sauceIndex}>
                      <Box flex={1}>
                        <Typography variant="body2" style={applyStyle(density === PizzaToppingDensity.None && pizzaType === PizzaShapeType.NONE, true)}>
                          {title} : {density}
                        </Typography>
                      </Box>
                      <Box component="span" marginLeft={2}>
                        <PizzaShape type={pizzaType} preset={preset} />
                      </Box>
                    </Box>
                  );
                }
              }
            }

            return null;
          })
        );

        !!cheeseOptionItemArray &&
          !!cheeseOptionItemArray.length &&
          options.push(
            <Box marginY={1} key={options.length}>
              <Typography variant="subtitle2" component="div" gutterBottom={true}>
                Cheese
              </Typography>
              {cheeseOptionItemArray}
            </Box>
          );

        !!sauceOptionItemArray &&
          !!sauceOptionItemArray.length &&
          options.push(
            <Box marginY={1} key={options.length}>
              <Typography variant="subtitle2" component="div" gutterBottom={true}>
                Sauce
              </Typography>
              {sauceOptionItemArray}
            </Box>
          );

        const groupedToppingGroups = groupBy(pizzaToppingGroupsOrderLineItems, 'toppingGroupId');

        for (const toppingGroupId in groupedToppingGroups) {
          const toppingGroup = find(pizzaToppingGroups, ({ id }) => id === toppingGroupId);

          const toppingGroupTitle = toppingGroup?.title;

          const toppingGroupItems = toppingGroup?.items;

          const groupedItems = groupBy(groupedToppingGroups[toppingGroupId], 'toppingId');

          const itemsObjectArray = [];

          let itemIndex = 0;

          for (const toppingId in groupedItems) {
            const item = find(toppingGroupItems, ({ id }) => id === toppingId);

            const toppingTitle = item?.title;

            const preset = item?.preset;

            if (preset) {
              if (groupedItems[toppingId].length > 1) {
                const left = groupedItems[toppingId][0];
                const right = groupedItems[toppingId][1];

                let pizzaType = PizzaShapeType.NONE;
                let density = '';

                if (left.toppingDensity === PizzaToppingDensity.None && right.toppingDensity !== PizzaToppingDensity.None) {
                  pizzaType = PizzaShapeType.SECOND_HALF;
                  density = right.toppingDensity;
                } else if (left.toppingDensity !== PizzaToppingDensity.None && right.toppingDensity === PizzaToppingDensity.None) {
                  pizzaType = PizzaShapeType.FIRST_HALF;
                  density = left.toppingDensity;
                } else if (left.toppingDensity === PizzaToppingDensity.None && right.toppingDensity === PizzaToppingDensity.None) {
                  pizzaType = PizzaShapeType.NONE;
                  density = PizzaToppingDensity.None;
                } else {
                  pizzaType = PizzaShapeType.FULL;
                  if (left.toppingDensity === right.toppingDensity) {
                    density = left.toppingDensity;
                  } else {
                    density = `${left.toppingDensity},${right.toppingDensity}`;
                  }
                }

                itemsObjectArray.push(
                  <Box display="flex" justifyItems="center" alignItems="center" marginBottom={1} key={itemIndex++}>
                    <Box flex={1}>
                      <Typography variant="body2" style={applyStyle(density === PizzaToppingDensity.None && pizzaType === PizzaShapeType.NONE, true)}>
                        {toppingTitle} : {density}
                      </Typography>
                    </Box>
                    <Box component="span" marginLeft={2}>
                      <PizzaShape type={pizzaType} preset={preset} />
                    </Box>
                  </Box>
                );
              } else {
                const lineItem = groupedItems[toppingId][0];

                const density = lineItem.toppingDensity;
                const side = lineItem.side;

                let pizzaType = PizzaShapeType.NONE;

                if (side === 'Whole') {
                  pizzaType = PizzaShapeType.FULL;
                }

                if (side === 'Left') {
                  pizzaType = PizzaShapeType.FIRST_HALF;
                }

                if (side === 'Right') {
                  pizzaType = PizzaShapeType.SECOND_HALF;
                }

                if (lineItem.toppingDensity === PizzaToppingDensity.None) {
                  pizzaType = PizzaShapeType.NONE;
                }

                itemsObjectArray.push(
                  <Box display="flex" justifyItems="center" alignItems="center" marginBottom={1} key={itemIndex++}>
                    <Box flex={1}>
                      <Typography variant="body2" style={applyStyle(density === PizzaToppingDensity.None && pizzaType === PizzaShapeType.NONE, true)}>
                        {toppingTitle} : {density}
                      </Typography>
                    </Box>
                    <Box component="span" marginLeft={2}>
                      <PizzaShape type={pizzaType} preset={preset} />
                    </Box>
                  </Box>
                );
              }
            } else {
              if (groupedItems[toppingId].length > 1) {
                const left = groupedItems[toppingId][0];
                const right = groupedItems[toppingId][1];

                let pizzaType = PizzaShapeType.FULL;
                let density = '';

                if (left.toppingDensity === right.toppingDensity) {
                  density = left.toppingDensity;
                } else {
                  density = `${left.toppingDensity},${right.toppingDensity}`;
                }
                itemsObjectArray.push(
                  <Box display="flex" justifyItems="center" alignItems="center" marginBottom={1} key={itemIndex++}>
                    <Box flex={1}>
                      <Typography variant="body2">
                        {toppingTitle} : {density}
                      </Typography>
                    </Box>
                    <Box component="span" marginLeft={2}>
                      <PizzaShape type={pizzaType} preset={preset} />
                    </Box>
                  </Box>
                );
              } else {
                const lineItem = groupedItems[toppingId][0];

                const density = lineItem.toppingDensity;
                const side = lineItem.side;

                let pizzaType = PizzaShapeType.NONE;

                if (side === 'Whole') {
                  pizzaType = PizzaShapeType.FULL;
                }

                if (side === 'Left') {
                  pizzaType = PizzaShapeType.FIRST_HALF;
                }

                if (side === 'Right') {
                  pizzaType = PizzaShapeType.SECOND_HALF;
                }

                if (lineItem.toppingDensity === PizzaToppingDensity.None) {
                  pizzaType = PizzaShapeType.NONE;
                }

                itemsObjectArray.push(
                  <Box display="flex" justifyItems="center" alignItems="center" marginBottom={1} key={itemIndex++}>
                    <Box flex={1}>
                      <Typography variant="body2" style={applyStyle(density === PizzaToppingDensity.None && pizzaType === PizzaShapeType.NONE, true)}>
                        {toppingTitle} : {density}
                      </Typography>
                    </Box>
                    <Box component="span" marginLeft={2}>
                      <PizzaShape type={pizzaType} preset={preset} />
                    </Box>
                  </Box>
                );
              }
            }
          }
          options.push(
            <Box marginY={1} key={options.length}>
              <Typography variant="subtitle2" component="div" gutterBottom={true}>
                {toppingGroupTitle}
              </Typography>
              {itemsObjectArray}
            </Box>
          );
        }

        return (
          <Grid key={index} container={true} className={classes.dishContainer}>
            <Grid xs={8} item={true}>
              <Typography variant="body1">{title}</Typography>
              <Typography variant="body2">{pizzaSelectedBaseTItle}</Typography>
              <Typography variant="body2">{pizzaSelectedCrustTitle}</Typography>
              {options}
              <br />
              {note && (
                <Typography variant="caption">
                  <span className={classes.bold}>Dish Note : </span>
                  {note}
                </Typography>
              )}
            </Grid>
            <Grid xs={4} item={true}>
              <Box textAlign="right">
                <Stepper value={quantity} onChange={(value) => onQuantityChange({ dishId: uuid, quantity: value, prevQuantity: quantity, rewardPoints, dishPrice: price, dishTitle: title })} />
              </Box>
              <Box paddingY={1} display="flex" justifyContent="flex-end" alignItems="baseline">
                {isPreDiscounted && priceStriked && (
                  <Box className={classes.priceItem}>
                    <Typography className={classes.priceStriked} variant="body2" align="right">
                      ${priceStriked}
                    </Typography>
                  </Box>
                )}
                <Box className={classes.priceItem}>
                  <Typography variant="body2" align="right">
                    ${price}
                  </Typography>
                </Box>
                {!!discountPercent && (
                  <Box className={classes.priceItem}>
                    <Typography className={classes.priceDiscount} variant="body2" align="right">
                      {discountPercent}%&nbsp;OFF
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        );
      }
    });
  };

  return (
    <Box paddingX={1} flex={1}>
      <Box padding={2} display="flex" flexDirection="column" justifyContent="space" bgcolor={grey[50]} borderRadius={8}>
        {render()}
      </Box>
    </Box>
  );
};

export default CheckoutSummary;

export * from './types';
