import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '1200px',
    margin: '0px auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  scrollingComponent: {
    overflowY: 'scroll'
  }
}));
