import Big from 'big.js';

export enum PizzaShapeType {
  FIRST_HALF = 'FIRST_HALF',
  SECOND_HALF = 'SECOND_HALF',
  FULL = 'FULL',
  NONE = 'NONE'
}

export interface IDishCategories {
  id: string;
  title: string;
}

export interface IDiscount {
  price: string;
  percentage: string;
}

export interface IPauseDetails {
  DINING?: {
    pauseDate: string;
    status: 'pause' | 'resume';
  };
  TAKEOUT?: {
    pauseDate: string;
    status: 'pause' | 'resume';
  };
  DELIVERY?: {
    pauseDate: string;
    status: 'pause' | 'resume';
  };
}

export interface IGroceryDoordashDeliveryEstimate {
  deliveryMiles: Big;
  deliveryCharge: Big;
  doordashQuoteId: string | null;
}

export enum GroceryDropOffType {
  InHand = 'Hand it over in Person',
  LeaveAtTheDoor = 'Leave it at the Door'
}

export const defaultTimeZone = 'us/pacific';

export type DeliveryProvider = 'SELF' | 'DOORDASH_CLASSIC' | '';

export type DeliveryMode = 'realtime' | 'scheduled' | '';

export enum DropOffType {
  InHand = 'Hand it over in Person',
  LeaveAtTheDoor = 'Leave it at the Door'
}

export interface IDeliveryEstimate {
  deliveryMiles: Big;
  deliveryCharge: Big;
  deliveryDuration: string;
  boonsQuoteId: string;
}

export interface IDoordashDeliveryEstimate {
  deliveryMiles: Big;
  deliveryCharge: Big;
  doordashQuoteId: string | null;
}

export interface IBizPlan {
  planId: '';
  planName: '';
  bizPlanDetails: {
    DINING: {
      custPctVal: number;
      custPctValLower: number;
      custAbsVal: number;
      bizPctVal: number;
      bizPctValLower: number;
      bizAbsVal: number;
    };
    TAKEOUT: {
      custPctVal: number;
      custPctValLower: number;
      custAbsVal: number;
      bizPctVal: number;
      bizPctValLower: number;
      bizAbsVal: number;
    };
    DELIVERY: {
      SELF: {
        custPctVal: number;
        custPctValLower: number;
        custAbsVal: number;
        bizPctVal: number;
        bizPctValLower: number;
        bizAbsVal: number;
      };
      DOORDASH_CLASSIC: {
        custPctVal: number;
        custPctValLower: number;
        custAbsVal: number;
        bizPctVal: number;
        bizPctValLower: number;
        bizAbsVal: number;
      };
    };
  };
}

export enum CryptoUnit {
  Cc = 'CC',
  Gwei = 'GWEI',
  Qwei = 'QWEI',
  Twei = 'TWEI',
  Wei = 'WEI'
}
