import { useEffect, useState } from 'react';
import { FetchPolicy } from 'apollo-client';
import {
  getRestaurantId,
  getRestaurantsByLocation,
  getRestaurantBusinsessHours,
  getRestaurantProfile,
  getRestaurantTables,
  getRestaurantGallery,
  getRestaurantAnnouncement,
  getRestaurant,
  getRestaurantTakeOutPaymentSetting,
  getRestaurantRewardSetting
} from 'graphql/query';
import { useQueryWithLoader } from 'hooks/loader';
import { map } from 'lodash';
import { Restaurant } from 'models';
import { GetRestaurantIdQuery, GetRestaurantsNearbyInput } from 'generated/types';
import { plainToClass } from 'class-transformer';
import geoDistance from 'util/geoDistance';

type IRestaurantQueryOptions = 'ID' | 'PROFILE' | 'BUSINESS_HOURS' | 'TABLE' | 'GALLERY' | 'ANNOUNCEMENT' | 'ALL' | 'PAYMENT_SETTING' | 'REWARD_SETTING';

const getQueryMapper = (type: IRestaurantQueryOptions) => {
  const mapper = {
    ANNOUNCEMENT: getRestaurantAnnouncement,
    BUSINESS_HOURS: getRestaurantBusinsessHours,
    ID: getRestaurantId,
    GALLERY: getRestaurantGallery,
    PROFILE: getRestaurantProfile,
    TABLE: getRestaurantTables,
    ALL: getRestaurant,
    PAYMENT_SETTING: getRestaurantTakeOutPaymentSetting,
    REWARD_SETTING: getRestaurantRewardSetting
  };

  return mapper[type];
};

export const useGetRestaurantById = (id: string | number | null, type: IRestaurantQueryOptions = 'ID', fetchPolicy: FetchPolicy = 'cache-first') => {
  const query = getQueryMapper(type);

  const { loading, data } = useQueryWithLoader<GetRestaurantIdQuery>(query, {
    fetchPolicy,
    skip: !id,
    variables: {
      input: {
        id
      }
    }
  });

  if (data && data.getRestaurant) {
    const restaurant = plainToClass(Restaurant, data.getRestaurant);

    return {
      loading,
      restaurant
    };
  }

  return {
    loading,
    restaurant: null
  };
};

export const useGetRestaurantsByLocation = ({ lat, lng, radiusInMeter }: GetRestaurantsNearbyInput) => {
  const [restaurantList, setRestaurantList] = useState<Restaurant[]>([]);

  const { data, loading, error } = useQueryWithLoader(getRestaurantsByLocation, {
    variables: {
      input: {
        lat,
        lng,
        radiusInMeter
      }
    }
  });

  useEffect(() => {
    if (data && data.getRestaurantsByLocation) {
      const { edges } = data.getRestaurantsByLocation;

      let restaurants = map(edges, ({ node }) => {
        const restaurant = plainToClass(Restaurant, node);

        return restaurant;
      });

      if (lat !== 0 && lng !== 0) {
        restaurants = restaurants.sort((a: Restaurant, b: Restaurant) => {
          const aLat = a.getLatLng?.lat || undefined;
          const aLng = a.getLatLng?.lng || undefined;

          const bLat = b.getLatLng?.lat || undefined;
          const bLng = b.getLatLng?.lng || undefined;

          if (aLat && aLng && bLat && bLng) {
            const aMiles = geoDistance(lat, lng, aLat, aLng, 'M');

            const bMiles = geoDistance(lat, lng, bLat, bLng, 'M');

            if (aMiles < bMiles) {
              return -1;
            } else if (aMiles > bMiles) {
              return 1;
            } else {
              return 0;
            }
          }
          return 0;
        });
      }

      setRestaurantList(restaurants);
    } else {
      setRestaurantList([]);
    }
  }, [data, lat, lng]);

  return { restaurantList, loading, error };
};
