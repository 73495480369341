import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  toppingsContainer: {
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  expansionPanel: {
    padding: 0,
    margin: 0,
    boxShadow: 'none',
    height: 'unset !important',
    minHeight: 'unset',
    backgroundColor: 'transparent',
    '&::before': {
      height: 0
    }
  },
  expansionSummary: {
    margin: 0,
    padding: 0,
    '& .MuiExpansionPanelSummary-content': {
      margin: 0
    },
    '&.Mui-expanded': {
      minHeight: '45px'
    },
    '& .Mui-expanded': {
      margin: 0
    },
    '& .MuiIconButton-edgeEnd': {
      padding: 0,
      margin: 0
    }
  },
  expansionDetails: {
    padding: 0,
    margin: 0
  },
  expansionIcon: {
    cursor: 'pointer',
    marginRight: theme.spacing(1)
  },
  selectedHalf: {
    cursor: 'pointer',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    borderRadius: 5
  },
  notSelectedHalf: {
    cursor: 'pointer',
    color: theme.palette.grey[400],
    padding: theme.spacing(1)
  },
  selectField: {
    backgroundColor: '#fff'
  },
  priceItem: {
    padding: theme.spacing(0.5)
  },
  priceStriked: {
    color: '#7e818c',
    textDecoration: "line-through"
  },
  priceDiscount: {
    background: theme.palette.success.dark,
    color: "white",
    padding: theme.spacing(0,0.5),
    borderRadius: "2px",
    fontWeight: 500,
  },
  isHeaderCollapsed: {
    paddingBottom: theme.spacing(15)
  }
}));
