import React from 'react';
import RootRoutes from 'routes';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { FirebaseContext, Firebase } from 'fbase';
import { AuthProvider } from 'fbase/authContext';
import { ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import { LoaderStore } from 'store';
import { getTheme } from '@lokobee/lokobee-ui';
import { SnackbarProvider } from 'notistack';
import Loader from 'components/Loader';
import useClearCache from 'hooks/useClearCache';

export const history = createBrowserHistory();

const globaTheme = getTheme();

const theme = responsiveFontSizes(globaTheme);

theme.typography = {
  ...theme.typography,
  body1: {
    ...theme.typography.body1,
    fontWeight: 500,
    fontSize: 14
  },
  body2: {
    ...theme.typography.body2,
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    fontSize: 12
  }
};

const App: React.FC = () => {
  const { state, refetchCacheAndReload } = useClearCache();

  // let isDialogClosed = JSON.parse(localStorage.getItem('isDialogClosed') || 'null') || false;
  // const [openDialog, setOpenDialog] = useState<boolean>(!isDialogClosed);

  if (!state.loading && !state.isLatestVersion) {
    // You can decide how and when you want to force reload
    refetchCacheAndReload();
  }

  if (state.loading) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <FirebaseContext.Provider value={{ firebase: new Firebase() }}>
        <AuthProvider>
          <LoaderStore>
            <SnackbarProvider
              preventDuplicate={true}
              hideIconVariant={true}
              autoHideDuration={2000}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}>
              <Router history={history}>
                <Loader />
                <RootRoutes />
              </Router>
            </SnackbarProvider>
          </LoaderStore>
        </AuthProvider>
      </FirebaseContext.Provider>
    </ThemeProvider>
  );
};

export default App;
