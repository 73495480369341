import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import white_line_bg from 'assets/img/white-lines-bg.svg';

export const useStyle = makeStyles((theme) => {
  return {
    disabledCard: {
      background: grey[500],
      borderRadius: '15px',
      color: '#fff',
      padding: theme.spacing(2),
      backgroundPosition: 'right bottom',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      cursor: 'pointer',
      'background-position': '-5%',
      transition: 'all 0.3s ease'
    },
    disabledItemText: {
      background: grey[600],
      borderRadius: theme.spacing(1),
      marginRight: theme.spacing(1),
      padding: theme.spacing(0.3, 1),
      whiteSpace: 'pre',
      display: 'flex',
      alignItems: 'center',
      color: '#fff',
      transition: 'all 0.3s ease'
    },
    CouponCard: {
      background: `url('${white_line_bg}') ${theme.palette.primary.main}`,
      borderRadius: '15px',
      padding: theme.spacing(2),
      backgroundPosition: 'right bottom',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    },
    borderedText: {
      border: '1px solid black',
      borderRadius: theme.spacing(1),
      marginRight: theme.spacing(1),
      padding: theme.spacing(0.5, 1),
      whiteSpace: 'pre'
    },
    darkBorderedText: {
      background: 'black',
      borderRadius: theme.spacing(1),
      marginRight: theme.spacing(1),
      padding: theme.spacing(0.5, 1),
      whiteSpace: 'pre',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.main
    },
    dialogContent: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      '&:first-child': {
        padding: 0
      }
    },
    boldText: {
      fontWeight: 'bolder'
    },
    dialog: {
      '& .MuiPaper-root': {
        height: 'auto%',
        zIndex: 1500
      }
    }
  };
});
