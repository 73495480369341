import { gql } from 'apollo-boost';
import { storeItemFragment } from 'graphql/fragments/groceryItem.fragments';

export const getGroceryStoreByLocation = gql`
  query getGroceryStoresByLocation($input: GetGroceryStoresNearbyInput!) {
    getGroceryStoresByLocation(input: $input) {
      edges {
        node {
          id
          logo {
            id
            url
          }
          name
          address {
            lat
            lng
          }
          taxModes {
            title
            tax {
              intValue
              shift
            }
          }
          timezone
        }
      }
    }
  }
`;

export const getGroceryStore = gql`
  query getGroceryStore($input: getGroceryStoreInput!) {
    getGroceryStore(input: $input) {
      id
      phone
      description
      announcement
      logo {
        id
        url
      }
      name
      address {
        text
        city
        street
        zipcode
        lat
        lng
      }
      coupons {
        dayOfWeek
        orderType
        code
        discountType
        discountValue
        maxUse
        startDate
        endDate
        maxDiscount
        name
      }
      bizHours {
        serviceType
        serviceName
        hours {
          openDay
          openHour
          openMinute
          closeHour
          closeMinute
          closeDay
        }
      }
      taxModes {
        title
        tax {
          intValue
          shift
        }
      }
      gallery {
        md {
          id
          url
        }
      }
      timezone
      bizHours {
        serviceType
        serviceName
        hours {
          openDay
          openHour
          openMinute
          closeHour
          closeMinute
          closeDay
        }
      }
      categories {
        id
        title
        subCategories {
          id
          title
        }
        image {
          id
          preview {
            id
            url
          }
        }
      }
      coupons {
        code
        name
        subtotalThreshold
        orderType
        dayOfWeek
        discountType
        discountValue
        maxUse
        useCnt
        startDate
        endDate
        description
        maxDiscount
      }
      bizPlan {
        planId
        bizPlanDetails {
          TAKEOUT {
            custPctVal
            custPctValLower
            custAbsVal
            bizPctVal
            bizPctValLower
            bizAbsVal
          }
          DELIVERY {
            SELF {
              custPctVal
              custPctValLower
              custAbsVal
              bizPctVal
              bizPctValLower
              bizAbsVal
            }
            DOORDASH_CLASSIC {
              custPctVal
              custPctValLower
              custAbsVal
              bizPctVal
              bizPctValLower
              bizAbsVal
            }
          }
        }
        planName
      }
      takeoutPaymentSetting {
        onlinePaymentTypes
        onsitePaymentTypes
        tipPercentOptions
        tipDefaultPercent
      }
      deliveryPaymentSetting {
        onlinePaymentTypes
        onsitePaymentTypes
        tipPercentOptions
        tipDefaultPercent
      }
      deliverySetting {
        waitMinutes
        freeDeliveryAmount
        minDeliveryAmount
        fees {
          mile
          fee
        }
        deliveryRadius
      }
      takeoutWaitMinutes
      enableTakeoutOrder
      enableDeliveryOrder
      lokobeeFee
      lokobeeFeeLimit
      lokobeeFeePercent
      hasStripeAccount
      issueRewardPoint
      rewardSetting {
        minStartPoints
        maxRedeemPointsPerUsage
        minRedeemPurchase
      }
      deliveryProvider
      deliveryMode
    }
  }
`;

export const getGroceryItemsByCategoryId = gql`
  query getGroceryItemsByCategoryId($input: GetGroceryItemsByCategoryIdInput!) {
    getGroceryItemsByCategoryId(input: $input) {
      ...storeItemFragment
    }
  }
  ${storeItemFragment}
`;

export const getGroceryRewardItems = gql`
  query getGroceryRewardItems($input: GetGroceryRewardItemsInput!) {
    getGroceryRewardItems(input: $input) {
      ...storeItemFragment
    }
  }
  ${storeItemFragment}
`;

export const getGroceryStoreCart = gql`
  query getGroceryStoreCart($input: GetGroceryStoreCartInput!) {
    getGroceryStoreCart(input: $input) {
      items {
        item {
          ...storeItemFragment
        }
        quantity
      }
      rewardItems {
        item {
          ...storeItemFragment
        }
        quantity
      }
      redeemedPoints
    }
  }
  ${storeItemFragment}
`;

export const queryGrocerySearch = gql`
  query queryGrocerySearch($input: GrocerySearchInput!) {
    queryGrocerySearch(input: $input) {
      ...storeItemFragment
    }
  }
  ${storeItemFragment}
`;

export const getGroceryDiscountedItems = gql`
  query getGroceryDiscountedItems($input: GetGroceryDiscountedItemsInput!) {
    getGroceryDiscountedItems(input: $input) {
      ...storeItemFragment
    }
  }
  ${storeItemFragment}
`;
