import { useEffect } from 'react';
import { useStore } from 'store';
import { getLocalStorage, removeLocalStorage } from 'util/storage';
import moment from 'moment-timezone';

const useCheckLocalStorageForCartData = () => {
  const cartState = JSON.parse(getLocalStorage('cartState') || 'null');

  const { dispatch } = useStore();

  useEffect(() => {
    if (cartState) {
      removeLocalStorage('cartState');

      if (moment.tz && cartState.timezone) {
        moment.tz.setDefault(cartState.timezone);
      }

      dispatch({
        type: 'CART_ACTION_SET_FROM_LOCAL_STORAGE',
        payload: {
          ...cartState
        }
      });
    }
  }, [cartState, dispatch]);
};

export default useCheckLocalStorageForCartData;
