import React, { useContext, useState, useEffect } from 'react';
import { useStyles } from './style';
import DialogHeader from 'components/DialogHeader';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Divider } from '@material-ui/core';
import { AuthContext } from 'fbase/authContext';
import { useStore } from 'store';
import { useGetLokobeeUser } from 'graphql/hooks';
import AccountBoxIcon from '@material-ui/icons/AccountCircle';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Notifications from './Notifications';
import { useUserLogout } from 'hooks';
import FeedbackIcon from '@material-ui/icons/Feedback';
const MyAccount = () => {
  const classes = useStyles();

  const { dispatch } = useStore();

  const history = useHistory();

  const { currentUser, isAuthStateChanged } = useContext(AuthContext);

  const [openCommunication, setOpenCommunication] = useState(false);

  const { data: lokobeeUseData } = useGetLokobeeUser();

  const { logOut } = useUserLogout();

  /**
   * Fetching user communication details and dispatching to state
   */
  useEffect(() => {
    if (lokobeeUseData) {
      const payload = (({ smsForDeals, emailForDeals }: any) => ({ smsForDeals, emailForDeals }))(lokobeeUseData.getLokobeeUser);

      dispatch({
        type: 'USER_ACTION_SET_PROMOTION_PREFERENCE',
        payload
      });
    }
  }, [dispatch, lokobeeUseData]);

  const signOut = async () => {
    logOut();
  };

  const getUsername = () => {
    if (currentUser) {
      return currentUser.displayName ? currentUser.displayName : '';
    }

    return '';
  };

  const getEmail = () => {
    if (currentUser) {
      return currentUser.email ? currentUser.email : '';
    }

    return '';
  };

  const checkIfUserLoggedIn = (): boolean => {
    if (currentUser) {
      return true;
    }

    return false;
  };

  const isUserLoggedIn = checkIfUserLoggedIn();

  const onLoginClick = () => {
    dispatch({
      type: 'UI_ACTION_LOGIN_POPUP',
      payload: true
    });
  };

  const toggleCommunicationDialog = (isOpen: boolean) => {
    setOpenCommunication(isOpen);
  };

  const DisplayLink = ({ text, onClick, icon }: any) => {
    return (
      <>
        <Box className={classes.displayLink} width="100%" display="flex" alignItems="center" paddingY={1.5} onClick={onClick}>
          <Box flexGrow={1} display="flex" alignItems="center">
            <Box display="flex" mr={1} ml={1}>
              {icon}
            </Box>
            <Box>
              <Typography variant="body1">{text}</Typography>
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <Box bgcolor="#fff" height="100%">
        <DialogHeader text={currentUser ? 'My Account' : 'About'} onBack={() => history.goBack()} />
        {isUserLoggedIn && (
          <Box>
            <Box display="flex" alignItems="center" padding={1.5} className={classes.userDetailsContainer}>
              <Box paddingX={1}>
                <Typography variant="h5">{getUsername()}</Typography>
                <Typography variant="body2">{getEmail()}</Typography>
              </Box>
            </Box>
          </Box>
        )}
        <Box ml={1}>
          {isUserLoggedIn && <DisplayLink text="Profile" icon={<AccountBoxIcon fontSize="small" />} onClick={() => history.push('/profile')} />}
          {isUserLoggedIn && <DisplayLink text="Notification" icon={<NotificationsIcon fontSize="small" />} onClick={() => toggleCommunicationDialog(true)} />}
          <DisplayLink text="Feedback" icon={<FeedbackIcon fontSize="small" />} onClick={() => history.push('/feedback')} />
          {isUserLoggedIn && <DisplayLink text="Sign Out" icon={<VpnKeyIcon fontSize="small" />} onClick={signOut} />}
          {!isUserLoggedIn && isAuthStateChanged && <DisplayLink text="Sign In" icon={<ExitToAppIcon fontSize="small" />} onClick={onLoginClick} />}
        </Box>

        <Divider variant="middle" />

        <DisplayLink text="About Us" onClick={() => (window.location.href = 'https://biz.lokobee.com')} />
        <DisplayLink text="Privacy Policy" onClick={() => history.push('/privacypolicy')} />
        <DisplayLink text="User Agreement" onClick={() => history.push('/useragreement')} />
        <Typography variant="body1" className={classes.versionText}>
          Version : LKB.{(global as any).appVersion}
        </Typography>
      </Box>

      <Notifications isOpen={openCommunication} handleClose={() => toggleCommunicationDialog(false)} />
    </>
  );
};

export default MyAccount;
