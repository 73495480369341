import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { getRestaurant as getRestaurantQuery } from 'graphql/query';
import { useStore } from 'store';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { useLazyQueryWithLoader } from 'hooks/loader';

export default () => {
  const { restaurantId } = useParams<any>();

  const location = useLocation();

  const { dispatch } = useStore();

  const [isValid, setValid] = useState<boolean>(false);

  const [getRestaurant, { data }] = useLazyQueryWithLoader(getRestaurantQuery, {}, true);

  const [restId, setResId] = useState(null);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const urlTableParamValue = query.get('table');
    const urlPhonePramValue = query.get('phone');

    if (!isEmpty(urlTableParamValue)) {
      dispatch({
        type: 'CART_ACTION_SET_TABLE_NUMBER',
        payload: urlTableParamValue
      });
    }

    if (urlPhonePramValue && !isEmpty(urlPhonePramValue)) {
      const input = {
        orderPhoneNumberFromUrl: !urlPhonePramValue.includes('+') ? '+' + urlPhonePramValue.trim() : urlPhonePramValue.trim()
      };
      dispatch({
        type: 'USER_ACTION_SET_CONTACT_DETAILS',
        payload: input
      });
    }
  }, [dispatch, location.search]);

  useEffect(() => {
    if (restId && restId === restaurantId) {
      return;
    } else {
      getRestaurant({
        variables: {
          input: {
            id: restaurantId
          }
        }
      });
    }
  }, [getRestaurant, restId, restaurantId]);

  useEffect(() => {
    if (data && data.getRestaurant) {
      const { deliveryMethod, deliveryProviders, id, i18n, bizPlan } = data.getRestaurant;

      const timezone = i18n ? i18n.timezone || 'us/pacific' : 'us/pacific';

      if (moment.tz) {
        moment.tz.setDefault(timezone);
      }

      setResId(id);

      dispatch({
        type: 'SET_BIZ_PLAN',
        payload: bizPlan
      });

      dispatch({
        type: 'SET_RESTAURANT',
        payload: id
      });

      dispatch({
        type: 'SET_ACTIVE_CATEGORY',
        payload: 'All'
      });

      dispatch({
        type: 'CART_ACTION_TOGGLE_COUPON_AUTO_APPLY',
        payload: true
      });

      dispatch({
        type: 'CART_ACTION_SET_DELIVERY_META_DATA',
        payload: {
          deliveryProvider: deliveryProviders,
          deliveryMode: deliveryMethod
        }
      });

      setValid(true);
    }
  }, [data, dispatch]);

  useEffect(() => {
    return () => dispatch({ type: 'RESET_RESTAURANT' });
  }, [dispatch]);

  return {
    isValid: isValid,
    restaurantID: restaurantId
  };
};
