export interface IRestaurantState {
  lastActiveRestaurant: string;
  restaurant: string | number | null;
  totalPoints: number;
  redeemablePoints: number;
  usedPoints: number;
  minimumPointsRequired: number;
  minimumPurchaseRequired: number;
  maximumRedeemablePoints: number;
  pricePointRatio: number;
  issueRewardPoint: boolean;
  bizPlan: {
    planId: '';
    planName: '';
    bizPlanDetails: {
      DINING: {
        custPctVal: number;
        custPctValLower: number;
        custAbsVal: number;
        bizPctVal: number;
        bizPctValLower: number;
        bizAbsVal: number;
      };
      TAKEOUT: {
        custPctVal: number;
        custPctValLower: number;
        custAbsVal: number;
        bizPctVal: number;
        bizPctValLower: number;
        bizAbsVal: number;
      };
      DELIVERY: {
        SELF: {
          custPctVal: number;
          custPctValLower: number;
          custAbsVal: number;
          bizPctVal: number;
          bizPctValLower: number;
          bizAbsVal: number;
        };
        DOORDASH_CLASSIC: {
          custPctVal: number;
          custPctValLower: number;
          custAbsVal: number;
          bizPctVal: number;
          bizPctValLower: number;
          bizAbsVal: number;
        };
      };
    };
  };
}

export const restaurantInitialState: IRestaurantState = {
  lastActiveRestaurant: '',
  restaurant: 0,
  totalPoints: 0,
  redeemablePoints: 0,
  usedPoints: 0,
  minimumPointsRequired: 0,
  minimumPurchaseRequired: 0,
  maximumRedeemablePoints: 0,
  pricePointRatio: 0,
  issueRewardPoint: false,
  bizPlan: {
    planId: '',
    planName: '',
    bizPlanDetails: {
      DINING: {
        custPctVal: 0,
        custPctValLower: 0,
        custAbsVal: 0,
        bizPctVal: 0,
        bizPctValLower: 0,
        bizAbsVal: 0
      },
      TAKEOUT: {
        custPctVal: 0,
        custPctValLower: 0,
        custAbsVal: 0,
        bizPctVal: 0,
        bizPctValLower: 0,
        bizAbsVal: 0
      },
      DELIVERY: {
        SELF: {
          custPctVal: 0,
          custPctValLower: 0,
          custAbsVal: 0,
          bizPctVal: 0,
          bizPctValLower: 0,
          bizAbsVal: 0
        },
        DOORDASH_CLASSIC: {
          custPctVal: 0,
          custPctValLower: 0,
          custAbsVal: 0,
          bizPctVal: 0,
          bizPctValLower: 0,
          bizAbsVal: 0
        }
      }
    }
  }
};

export const restaurantActions = {
  SET_LASTACTIVERESTAURANT: (state: any, payload: any) => ({ ...state, lastActiveRestaurant: payload }),
  RESET_LASTACTIVERESTAURANT: (state: any) => ({ ...state, lastActiveRestaurant: '' }),
  SET_RESTAURANT: (state: any, payload: any) => ({ ...state, restaurant: payload }),
  RESET_RESTAURANT: (state: any) => ({ ...state, restaurant: null }),
  SET_RESTAURANT_USED_POINTS: (state: any, payload: any) => ({ ...state, usedPoints: payload }),
  SET_REWARD_SETTING: (state: any, payload: any) => {
    const { totalPoints, redeemablePoints, minimumPointsRequired, minimumPurchaseRequired, maximumRedeemablePoints, pricePointRatio, issueRewardPoint } = payload;

    return {
      ...state,
      totalPoints,
      redeemablePoints,
      minimumPointsRequired,
      minimumPurchaseRequired,
      maximumRedeemablePoints,
      pricePointRatio,
      issueRewardPoint
    };
  },
  SET_BIZ_PLAN: (state: any, payload: any) => {
    return {
      ...state,
      bizPlan: payload
    };
  }
};

export default {};
