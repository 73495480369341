import { Box, Grid, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import Big from 'big.js';
import { IAddress } from 'components/AddressModal/types';
import { Coupon } from 'generated/types';
import Tip from '../Tip';
import { QUALIFIED, NOT_QUALIFIED } from 'strings';
import { DeliveryProvider } from 'generated/custom';
import React from 'react';
import { useStyles } from './style';

interface IProps {
  taxRate: string;
  tax?: string;
  deliveryCharge: string;
  deliveryMiles: string;
  doordashCharge: string;
  serviceFee: Big | null;
  orderType: 'TAKE_OUT' | 'DELIVERY' | 'DINING' | '';
  deliveryAddress: IAddress | null;
  subtotal: string;
  discount: string | null;
  isDiscountApplied: boolean;
  couponData: Partial<Coupon> | null;
  isServiceFeeEnabled: boolean;
  setServiceFee: (payload: Big) => void;
  finalPrice: string | undefined;
  restaurantId: string;
  setTipAmount: (payload: Big) => void;
  subtotalAfterDiscount: string;
  usedPoints: number;
  isCartHavingPrediscountedItem: boolean;
  isPrediscountEnabled: boolean;
  subtotalToDiscount: string;
  deliveryProvider: DeliveryProvider;
}

const Bill: React.FC<IProps> = ({
  taxRate,
  tax = 0,
  deliveryCharge,
  doordashCharge,
  serviceFee,
  subtotal,
  deliveryAddress,
  discount,
  isDiscountApplied,
  couponData,
  orderType,
  setServiceFee,
  isServiceFeeEnabled,
  finalPrice,
  subtotalAfterDiscount,
  restaurantId,
  setTipAmount,
  usedPoints,
  isCartHavingPrediscountedItem,
  isPrediscountEnabled,
  subtotalToDiscount,
  deliveryProvider
}) => {
  const classes = useStyles();

  const getSubTotalAmount = () => {
    if (isDiscountApplied && discount) {
      const subtotalStr = Big(subtotal).toFixed(2);

      return (
        <>
          <Box component="span" style={{ textDecoration: 'line-through' }} marginRight={1}>
            ${subtotalStr}
          </Box>
          <Box component="span" fontWeight={500}>
            $
            {Big(subtotal)
              .minus(discount)
              .toFixed(2)}
          </Box>
        </>
      );
    }

    return <>${Big(subtotal).toFixed(2)}</>;
  };

  const getDeliveryCharge = () => {
    if (orderType === 'DELIVERY' && deliveryAddress) {
      if (deliveryProvider === 'DOORDASH_CLASSIC') {
        if (deliveryCharge === doordashCharge) {
          return <>${deliveryCharge}</>;
        } else {
          return (
            <>
              <Box component="span" style={{ textDecoration: 'line-through' }} marginRight={1}>
                ${doordashCharge}
              </Box>
              <Box component="span" fontWeight={500}>
                ${deliveryCharge}
              </Box>
            </>
          );
        }
      } else {
        return <>${deliveryCharge}</>;
      }
    }
  };

  return (
    <Box paddingX={2} paddingY={1}>
      <Grid container={true} className={classes.container}>
        <Grid item={true} xs={6}>
          <Typography variant="body2" component="div">
            <Box fontWeight="500">Sub Total</Box>
          </Typography>
        </Grid>
        <Grid item={true} xs={6}>
          <Typography variant="body2" align="right">
            {getSubTotalAmount()}
          </Typography>
        </Grid>
      </Grid>
      {isCartHavingPrediscountedItem && isPrediscountEnabled && (
        <Grid container={true}>
          <Grid item={true}>
            <Typography style={{ fontWeight: 'bold' }} variant="body2" color="secondary">
              {Big(subtotalToDiscount).gt(0) ? `${QUALIFIED} $${subtotalToDiscount}` : NOT_QUALIFIED}
            </Typography>
            <Typography style={{ fontWeight: 'bold' }} variant="body2" color="secondary">
              Already Discounted Items don’t Qualify
            </Typography>
          </Grid>
        </Grid>
      )}
      {!!usedPoints && (
        <Grid container={true} className={classes.container}>
          <Grid item={true} xs={6}>
            <Typography variant="body2" component="div">
              <Box fontWeight="500">PTS Redeemed</Box>
            </Typography>
          </Grid>
          <Grid item={true} xs={6}>
            <Typography variant="body2" align="right">
              {usedPoints}
            </Typography>
          </Grid>
        </Grid>
      )}

      {isDiscountApplied && discount && (
        <Box component="span" color={green[900]}>
          <Grid container={true} className={classes.container}>
            <Grid item={true} xs={6}>
              <Typography variant="body2" component="div">
                <Box fontWeight="500">Discount - ({couponData?.code})</Box>
              </Typography>
            </Grid>
            <Grid item={true} xs={6}>
              <Typography variant="body2" align="right">
                -${Big(discount)?.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      <Grid container={true} className={classes.container}>
        <Grid item={true} xs={6}>
          <Typography variant="body2" component="div">
            <Box fontWeight="500" display="flex" justifyItems="center" alignItems="center">
              Tax (
              {Big(taxRate)
                .mul(100)
                .toFixed(2)}
              %):
            </Box>
          </Typography>
        </Grid>
        <Grid item={true} xs={6}>
          <Typography variant="body2" align="right">
            ${Big(tax).toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
      {isServiceFeeEnabled && serviceFee && (
        <Grid container={true} className={classes.container}>
          <Grid item={true} xs={6}>
            <Typography variant="body2" component="div">
              <Box fontWeight="500" display="flex" justifyItems="center" alignItems="center">
                Service Fees:
              </Box>
            </Typography>
          </Grid>
          <Grid item={true} xs={6}>
            <Typography variant="body2" align="right">
              ${Big(serviceFee).toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
      )}

      {orderType === 'DELIVERY' && deliveryAddress && (
        <Grid container={true} className={classes.container}>
          <Grid item={true} xs={6}>
            <Typography variant="body2" component="div">
              <Box fontWeight="500">Delivery Charge</Box>
              {/* <Box fontWeight="500">Delivery Charge ( {deliveryMiles} Miles)</Box> */}
            </Typography>
          </Grid>

          <Grid item={true} xs={6}>
            <Typography variant="body2" align="right">
              {getDeliveryCharge()}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Box paddingTop={1}>
        <Tip restaurantId={restaurantId} setTipAmount={setTipAmount} total={subtotalAfterDiscount} />
      </Box>
      <Grid container={true} className={classes.container}>
        <Grid item={true} xs={6}>
          <Typography variant="body1" component="div">
            <Box fontWeight="500">Total</Box>
          </Typography>
        </Grid>
        <Grid item={true} xs={6}>
          <Typography variant="body1" align="right">
            ${finalPrice}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Bill;
