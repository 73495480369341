import React from 'react';
import { useStyles } from './style';
import { Box, Grid, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Collapse from '@material-ui/core/Collapse';
import PopularIcon from 'assets/img/popular_icon.svg';
import ShrinkingHeader from './ShrinkingHeader';
import { grey } from '@material-ui/core/colors';
import StarIcon from '@material-ui/icons/Star';
import LiquorIcon from 'assets/img/liquor.svg';
import ExpandingHeader from './ExpandingHeader';
interface IProps {
  dishTitle: string | null;
  dishDescription: string | null;
  handleClose: () => void;
  imgSrc: string | null;
  isHeaderExpanded: boolean;
  showImg: () => void;
  hideImg: () => void;
  isPopular: Boolean;
  isAlcohol: Boolean;
  rateScoreValue: number;
  rateCountValue: number;
}

const DishHeader: React.FC<IProps> = ({ dishTitle, dishDescription, isPopular, handleClose, hideImg, isHeaderExpanded, imgSrc, showImg, isAlcohol, rateScoreValue, rateCountValue }) => {
  const classes = useStyles();
  return (
    <>
      {!imgSrc || imgSrc === '' || imgSrc === undefined ? (
        <>
          <Collapse timeout={500} in={!isHeaderExpanded}>
            <ShrinkingHeader
              isPopular={isPopular}
              dishTitle={dishTitle}
              dishDescription={dishDescription}
              handleClose={handleClose}
              imgSrc={imgSrc}
              showImg={showImg}
              hideImg={hideImg}
              isAlcohol={isAlcohol}
              rateScoreValue={rateScoreValue}
              isHeaderExpanded={isHeaderExpanded}
              rateCountValue={rateCountValue}
            />
          </Collapse>
          <Collapse timeout={700} in={isHeaderExpanded}>
            <Box paddingY={1} border={imgSrc ? `solid 1px ${grey[100]}` : ''} borderRadius={imgSrc ? '15px 15px 0 0' : 0} boxShadow={imgSrc ? 1 : 0}>
              <Grid container>
                <Grid item xs={10} className={classes.dishContainer}>
                  <Typography variant="h6" onClick={hideImg}>
                    <Box paddingX={2} display="flex" alignItems="center" justifyItems="center">
                      {dishTitle}
                      {isPopular && (
                        <Box marginY={1} display="flex" alignItems="center" justifyItems="center" onClick={hideImg}>
                          <img src={PopularIcon} alt="Popular" />
                        </Box>
                      )}
                    </Box>
                  </Typography>
                  {isAlcohol && (
                    <Box paddingX={2}>
                      <img src={LiquorIcon} alt="" />
                    </Box>
                  )}
                  <Box paddingX={2}>
                    <Typography variant="body2" onClick={hideImg}>
                      {dishDescription}
                    </Typography>
                    {rateScoreValue > 0 && rateCountValue > 0 && (
                      <Box display="flex" alignItems="center">
                        <StarIcon color="primary" fontSize="inherit" />
                        <Typography className={classes.rateScore + '' + classes.colorBlack} variant="body2" noWrap={true}>
                          {`${rateScoreValue} (${rateCountValue})`}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box paddingX={1.5} display="flex" alignItems="center" justifyContent="flex-end">
                    <CloseIcon onClick={handleClose} color="inherit" style={{ cursor: 'pointer', display: 'flex' }} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </>
      ) : (
        <Box>
          <Collapse timeout={500} in={!isHeaderExpanded}>
            <ShrinkingHeader
              isPopular={isPopular}
              dishTitle={dishTitle}
              dishDescription={dishDescription}
              handleClose={handleClose}
              imgSrc={imgSrc}
              showImg={showImg}
              hideImg={hideImg}
              isAlcohol={isAlcohol}
              rateScoreValue={rateScoreValue}
              isHeaderExpanded={isHeaderExpanded}
              rateCountValue={rateCountValue}
            />
          </Collapse>
          <Collapse timeout={500} in={isHeaderExpanded}>
            <ExpandingHeader
              isPopular={isPopular}
              dishTitle={dishTitle}
              dishDescription={dishDescription}
              handleClose={handleClose}
              imgSrc={imgSrc}
              showImg={showImg}
              hideImg={hideImg}
              isAlcohol={isAlcohol}
              rateScoreValue={rateScoreValue}
              isHeaderExpanded={isHeaderExpanded}
              rateCountValue={rateCountValue}
            />
          </Collapse>
        </Box>
      )}
    </>
  );
};
export default DishHeader;
