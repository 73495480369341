/* eslint-disable no-loop-func */
import moment, { Moment } from 'moment';
import 'twix';
import { IBizHour, IBizHourSlot, IPickerData } from './types';
import { filter, uniqBy } from 'lodash';

export const getTimeData = (businessHours: IBizHour[], waitingTime: number | undefined | null) => {
  const _pickerData: IPickerData[] = [];

  if (businessHours.length === 0) {
    return [];
  }

  const waitingTimeToAdd = waitingTime || waitingTime === 0 ? waitingTime : 10; // Added Seperate condition to consider 0 mins also

  const startDate = moment().add(waitingTimeToAdd, 'm');

  const roundedMins = Math.ceil(startDate.minute() / 10) * 10;

  startDate.minute(roundedMins).second(0);

  let curDate: any = startDate;

  const todayDay = curDate.day() ? curDate.day() : 7;

  let NextDayTimings: Moment[] = [];

  while (_pickerData.length !== 7) {
    const availableSlots: Moment[] = [];

    availableSlots.push(...NextDayTimings);
    NextDayTimings = [];

    const curDay = curDate.day() ? curDate.day() : 7;

    const prevDay = curDate.day() !== 1 ? curDay - 1 : 7;

    businessHours.forEach((hour: IBizHour) => {
      const requiredSlot = filter(hour.hours, (slot: IBizHourSlot) => slot.openDay === curDay)[0];

      const prevSlot = filter(hour.hours, (slot: IBizHourSlot) => slot.openDay === prevDay)[0];

      if (requiredSlot) {
        const slotStart: any = curDate
          .clone()
          .hour(requiredSlot.openHour)
          .minute(requiredSlot.openMinute)
          .second(0)
          .add(waitingTimeToAdd, 'm');

        const roundetStartMins = Math.ceil(slotStart.minute() / 10) * 10;

        slotStart.minute(roundetStartMins).second(0);

        const slotEnd: any = curDate
          .clone()
          .hour(requiredSlot.closeHour)
          .minute(requiredSlot.closeMinute)
          .second(0);

        const roundetEndMins = Math.ceil(slotEnd.minute() / 10) * 10;

        slotEnd.minute(roundetEndMins).second(0);

        if (requiredSlot.openDay !== requiredSlot.closeDay) {
          slotEnd.add('1', 'day');
        }

        if (prevSlot && prevSlot.openDay !== prevSlot.closeDay && curDay === todayDay) {
          const prevSlotStart: any = curDate
            .clone()
            .hour(0)
            .minute(0)
            .second(0)
            .add(waitingTimeToAdd, 'm');

          const roundetPrevStartMins = Math.ceil(prevSlotStart.minute() / 10) * 10;

          prevSlotStart.minute(roundetPrevStartMins).second(0);

          const prevSlotEnd: any = curDate
            .clone()
            .hour(prevSlot.closeHour)
            .minute(prevSlot.closeMinute)
            .second(0);

          const roundedPrevEndMins = Math.ceil(prevSlotEnd.minute() / 10) * 10;

          prevSlotEnd.minute(roundedPrevEndMins).second(0);

          if (prevSlotEnd.isAfter(curDate) || prevSlotEnd.isSame(curDate)) {
            let prevDayIterator: any;
            if (prevSlotStart.isAfter(curDate)) {
              prevDayIterator = prevSlotStart
                .clone()
                .twix(prevSlotEnd.clone())
                .iterate(10, 'minutes');
            } else {
              prevDayIterator = curDate
                .clone()
                .twix(prevSlotEnd.clone())
                .iterate(10, 'minutes');
            }

            while (prevDayIterator.hasNext()) {
              const newTime = prevDayIterator.next();
              availableSlots.push(newTime);
            }
          }
        }

        let timeIterator: any;
        if (slotEnd.isAfter(curDate) || slotEnd.isSame(curDate)) {
          if (slotStart.isAfter(curDate)) {
            timeIterator = slotStart
              .clone()
              .twix(slotEnd.clone())
              .iterate(10, 'minutes');
          } else {
            timeIterator = curDate
              .clone()
              .twix(slotEnd.clone())
              .iterate(10, 'minutes');
          }

          while (timeIterator.hasNext()) {
            const newTime = timeIterator.next();
            if (newTime.isSame(curDate, 'day')) {
              availableSlots.push(newTime);
            } else {
              availableSlots.push(newTime);
              break;
            }
          }
          while (timeIterator.hasNext()) {
            NextDayTimings.push(timeIterator.next());
          }
        }
      } else {
        if (prevSlot && prevSlot.openDay !== prevSlot.closeDay && curDay === todayDay) {
          const prevSlotStart: any = curDate
            .clone()
            .hour(0)
            .minute(0)
            .second(0)
            .add(waitingTimeToAdd, 'm');

          const roundetPrevStartMins = Math.ceil(prevSlotStart.minute() / 10) * 10;

          prevSlotStart.minute(roundetPrevStartMins).second(0);

          const prevSlotEnd: any = curDate
            .clone()
            .hour(prevSlot.closeHour)
            .minute(prevSlot.closeMinute)
            .second(0);

          const roundedPrevEndMins = Math.ceil(prevSlotEnd.minute() / 10) * 10;

          prevSlotEnd.minute(roundedPrevEndMins).second(0);

          if (prevSlotEnd.isAfter(curDate) || prevSlotEnd.isSame(curDate)) {
            let prevDayIterator: any;
            if (prevSlotStart.isAfter(curDate)) {
              prevDayIterator = prevSlotStart
                .clone()
                .twix(prevSlotEnd.clone())
                .iterate(10, 'minutes');
            } else {
              prevDayIterator = curDate
                .clone()
                .twix(prevSlotEnd.clone())
                .iterate(10, 'minutes');
            }

            while (prevDayIterator.hasNext()) {
              const newTime = prevDayIterator.next();
              availableSlots.push(newTime);
            }
          }
        }
      }
    });
    if (availableSlots.length > 0) {
      _pickerData.push({
        day: curDate.clone(),
        availableSlots: uniqBy(availableSlots, (s: Moment) => {
          return s.valueOf();
        }).sort((a, b) => a.diff(b))
      });
    }

    curDate = curDate
      .clone()
      .add(1, 'day')
      .hour(0)
      .minute(0)
      .second(0);
  }

  return _pickerData;
};
