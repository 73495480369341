import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'fbase/authContext';
import { Box } from '@material-ui/core';
import DialogHeader from 'components/DialogHeader';
import RestaurantOrders from './RestaurantOrders';
// import GroceryOrders from './GroceryOrders';

const Orders: React.FC = () => {
  const { currentUser } = useContext(AuthContext);
  // const theme = useTheme();
  const history = useHistory();

  // const StyledTabs = withStyles({
  //   indicator: {
  //     backgroundColor: theme.palette.primary.main,
  //     height: '3px'
  //   }
  // })((props: any) => <Tabs {...props} />);
  // const [activeTab, setActiveTab] = useState('restaurants');

  const onBack = () => {
    history.goBack();
  };

  if (currentUser) {
    return (
      <Box bgcolor="#fff" height="100%">
        <DialogHeader text="My Orders" onBack={onBack} />
        {/* <StyledTabs variant="fullWidth" value={activeTab} onChange={(_e: any, index: any) => setActiveTab(index)}>
          <Tab label="Restaurants" value="restaurants" />
          <Tab label="Grocery" value="groceryStore" />
        </StyledTabs> */}
        <RestaurantOrders currentUser={currentUser} />
        {/* {activeTab === 'groceryStore' && <GroceryOrders currentUser={currentUser} />} */}
      </Box>
    );
  } else {
    return null;
  }
};

export default Orders;
