import gql from 'graphql-tag';

export const deliveryCostEstimate = gql`
  mutation deliveryCostEstimateRequest($input: DeliveryCostEstimateRequestInput!) {
    deliveryCostEstimateRequest(input: $input) {
      fee
      distance
      duration
      quote_id
    }
  }
`;

export const doordashDeliveryCostEstimate = gql`
  mutation deliveryCostEstimateRequests($input: DeliveryCostEstimateRequestInput!) {
    deliveryCostEstimateRequest(input: $input) {
      quote_id
      doordashFee
      doordashDeliveryTime
    }
  }
`;
