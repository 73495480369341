import React, { useState, useEffect } from 'react';
import { map, filter, chain, omit, isEmpty } from 'lodash';
import { Checkbox, FormControl, FormGroup, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import { useStyles } from './style';
import FormLabelText from '../FormLabelText';
import { IItems } from '../types';
import ChoiceHeading from './ChoiceHeading';

interface IProps {
  title: string;
  min: number;
  max: number;
  items: IItems[];
  isOptional: boolean;
  onChange: (arg: IItems[]) => void;
  onError: (arg: boolean) => void;
  currency: 'USD' | 'POINT';
}

const ExtraGroup: React.FC<IProps> = ({ title: extraGroupTitle, min, max, items, onChange, onError, isOptional, currency = 'USD' }) => {
  const classes = useStyles();

  const [checkedItems, setCheckedItems] = useState<Array<IItems & { checked: boolean }>>([]);

  const [hasError, setError] = useState<boolean>(false);

  const secondaryText = isOptional ? 'OPTIONAL' : 'REQUIRED';

  useEffect(() => {
    if (items && items.length) {
      const initialState = map(items, ({ title, price, disabled }, index) => {
        if (min > 0 && max > 0 && min === 1 && max === 1 && index === 0) {
          return {
            title,
            price,
            checked: true,
            index,
            disabled
          };
        }
        return {
          title,
          price,
          checked: false,
          index,
          disabled
        };
      });

      if (isEmpty(checkedItems)) {
        let error = false;

        const newState = chain(initialState)
          .map((item, index) => ({ ...item, index }))
          .filter((item) => item?.checked === true)
          .map((item) => omit(item, 'checked'))
          .value();

        if (min > 0 && max > 0 && min < max) {
          const noOfSelectedItems = filter(initialState, ({ checked: checked1 }) => checked1).length;

          error = noOfSelectedItems < min || noOfSelectedItems > max;
        }

        if (min > 0 && max > 0 && min !== 1 && max !== 1 && min === max) {
          const noOfSelectedItems = filter(initialState, ({ checked: checked1 }) => checked1).length;

          error = noOfSelectedItems < min || noOfSelectedItems > max;
        }

        onChange(newState);

        setCheckedItems(initialState);

        setError(error);

        onError(error);
      }
    }
  }, [checkedItems, items, max, min, onChange, onError]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const {
      target: { checked }
    } = event;

    let newCheckState: Array<IItems & { checked: boolean }> = [];

    let error: boolean = false;
    if (min === 0) {
      newCheckState = map(checkedItems, (aCheckbox, i) => (index === i ? { ...aCheckbox, checked } : aCheckbox));

      const noOfSelectedItems = filter(newCheckState, ({ checked: checked1 }) => checked1).length;

      error = noOfSelectedItems > max;
    }

    if (min > 0 && max > 0 && min === 1 && max === 1) {
      newCheckState = map(checkedItems, (aRadio, i) => (index === i ? { ...aRadio, checked } : { ...aRadio, checked: false }));
    }

    if (min > 0 && max > 0 && min !== 1 && max !== 1 && min === max) {
      newCheckState = map(checkedItems, (aCheckbox, i) => (index === i ? { ...aCheckbox, checked } : aCheckbox));

      const noOfSelectedItems = filter(newCheckState, ({ checked: checked1 }) => checked1).length;

      error = noOfSelectedItems < min || noOfSelectedItems > max;
    }

    if (min > 0 && max > 0 && min < max) {
      newCheckState = map(checkedItems, (aCheckbox, i) => (index === i ? { ...aCheckbox, checked } : aCheckbox));

      const noOfSelectedItems = filter(newCheckState, ({ checked: checked1 }) => checked1).length;

      error = noOfSelectedItems < min || noOfSelectedItems > max;
    }

    const selectedItemCleanArray = chain(newCheckState)
      .map((item, index1) => ({ ...item, index: index1 }))
      .filter((item) => item?.checked === true)
      .map((item) => omit(item, 'checked'))
      .value();

    onChange(selectedItemCleanArray);

    setCheckedItems(newCheckState);

    setError(error);

    onError(error);
  };
  if (min === 0) {
    const output = map(checkedItems, ({ title, checked, price, disabled }, index) => {
      return (
        <FormControlLabel
          key={title}
          classes={{
            label: classes.formControlLabel
          }}
          disabled={disabled}
          control={<Checkbox name={title} checked={checked} disabled={disabled} onChange={(e) => onChangeHandler(e, index)} color="primary" />}
          label={<FormLabelText currency={currency} title={title} price={price} />}
        />
      );
    });

    const headingText = max === items.length ? `Choose any of these items` : `Choose up to ${max} of these items`;

    return (
      <>
        <Typography variant="body1">{extraGroupTitle}</Typography>
        <ChoiceHeading hasError={hasError} primaryText={headingText} secondaryText={secondaryText} />
        <FormControl className={classes.formControl} required={true} error={hasError} component="fieldset">
          <FormGroup>{output}</FormGroup>
        </FormControl>
      </>
    );
  }

  if (min > 0 && max > 0 && min === 1 && max === 1) {
    const output = map(checkedItems, ({ title, checked, price, disabled }, index) => {
      return (
        <FormControlLabel
          classes={{
            label: classes.formControlLabel
          }}
          key={title}
          disabled={disabled}
          control={<Radio key={title} name={title} checked={checked} disabled={disabled} onChange={(e) => onChangeHandler(e, index)} color="primary" />}
          label={<FormLabelText currency={currency} title={title} price={price} />}
        />
      );
    });

    return (
      <>
        <Typography variant="body1">{extraGroupTitle}</Typography>
        <ChoiceHeading hasError={hasError} primaryText={`Choose ${max} of these items`} secondaryText={secondaryText} />
        <FormControl className={classes.formControl} required={true} component="fieldset">
          <RadioGroup>{output}</RadioGroup>
        </FormControl>
      </>
    );
  }

  if (min > 0 && max > 0 && min !== 1 && max !== 1 && min === max) {
    const output = map(checkedItems, ({ title, checked, price, disabled }, index) => {
      return (
        <FormControlLabel
          key={title}
          classes={{
            label: classes.formControlLabel
          }}
          disabled={disabled}
          control={<Checkbox name={title} checked={checked} disabled={disabled} onChange={(e) => onChangeHandler(e, index)} color="primary" />}
          label={<FormLabelText currency={currency} title={title} price={price} />}
        />
      );
    });

    const headingText = max === items.length ? `Choose any of these items` : `Choose ${max} of these items`;

    return (
      <>
        <Typography variant="body1">{extraGroupTitle}</Typography>
        <ChoiceHeading hasError={hasError} primaryText={headingText} secondaryText={secondaryText} />
        <FormControl className={classes.formControl} required={true} error={hasError} component="fieldset">
          <FormGroup>{output}</FormGroup>
        </FormControl>
      </>
    );
  }

  if (min > 0 && max > 0 && min < max) {
    const output = map(checkedItems, ({ title, checked, price, disabled }, index) => {
      return (
        <FormControlLabel
          key={title}
          classes={{
            label: classes.formControlLabel
          }}
          disabled={disabled}
          control={<Checkbox name={title} checked={checked} disabled={disabled} onChange={(e) => onChangeHandler(e, index)} color="primary" />}
          label={<FormLabelText currency={currency} title={title} price={price} />}
        />
      );
    });

    return (
      <>
        <Typography variant="body1">{extraGroupTitle}</Typography>
        <ChoiceHeading hasError={hasError} primaryText={`Choose ${min} of these items, up to a maximum of ${max} items`} secondaryText={secondaryText} />
        <FormControl className={classes.formControl} required={true} error={hasError} component="fieldset">
          <FormGroup>{output}</FormGroup>
        </FormControl>
      </>
    );
  }

  return null;
};

export default ExtraGroup;
