export interface IUiInitialState {
  ui: {
    isLoginPopOpen: boolean;
    isPhoneVerificationPopupOpen: boolean;
    isUpdatedTermsPopOpen: boolean;
    isPrivacyPopOpen: boolean;
    isAgreementPopOpen: boolean;
    isReviewPage: boolean;
    isLoginFromCart: boolean;
    onLoginRoute?: string;
    onGuestLoginRoute?: string;
    showContactFormForGuest?: boolean;
    showContactFormForLokobeeUser?: boolean;
    showWalletPopup: boolean;
    isLokobeeLoginFromWallet: boolean;
  };

  alert: {
    isOpen: boolean;
    message: string;
  };
  ongoingOrders: {
    delivery: {
      SELF: number;
      BOONS: number;
      BUYING: number;
    };
  };
}

export const uiActionInitialState: IUiInitialState = {
  ui: {
    isLoginPopOpen: false,
    isPhoneVerificationPopupOpen: false,
    isUpdatedTermsPopOpen: false,
    isPrivacyPopOpen: false,
    isAgreementPopOpen: false,
    isReviewPage: false,
    isLoginFromCart: false,
    showWalletPopup: true,
    isLokobeeLoginFromWallet: false
  },
  alert: {
    isOpen: false,
    message: ''
  },
  ongoingOrders: {
    delivery: {
      SELF: 0,
      BOONS: 0,
      BUYING: 0
    }
  }
};

export const uiActions = {
  UI_ACTION_LOGIN_POPUP: (state: IUiInitialState, payload: any) => {
    // for backward compatibility
    if (typeof payload === 'boolean') {
      return { ...state, ui: { ...state.ui, isLoginPopOpen: payload } };
    } else {
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoginPopOpen: payload.openLoginPopup,
          onLoginRoute: payload.onLoginRoute,
          onGuestLoginRoute: payload.onGuestLoginRoute,
          showContactFormForGuest: payload.showContactFormForGuest,
          showContactFormForLokobeeUser: payload.showContactFormForLokobeeUser
        }
      };
    }
  },
  UI_ACTION_PHONE_VERIFICATION_POPUP: (state: IUiInitialState, payload: any) => ({ ...state, ui: { ...state.ui, isPhoneVerificationPopupOpen: payload } }),
  UI_ACTION_UPDATED_TERMS_POPUP: (state: IUiInitialState, payload: any) => ({ ...state, ui: { ...state.ui, isUpdatedTermsPopOpen: payload } }),
  UI_ACTION_PRIVACY_POPUP: (state: IUiInitialState, payload: any) => ({ ...state, ui: { ...state.ui, isPrivacyPopOpen: payload } }),
  UI_ACTION_AGREEMENT_POPUP: (state: IUiInitialState, payload: any) => ({ ...state, ui: { ...state.ui, isAgreementPopOpen: payload } }),
  UI_ACTION_FROM_REVIEW_PAGE: (state: IUiInitialState, payload: any) => ({ ...state, ui: { ...state.ui, isReviewPage: payload } }),
  SET_ALERT: (state: IUiInitialState, payload: any) => ({ ...state, alert: payload }),
  SET_ONGOING_BOONS_DELIVERY: (state: IUiInitialState, payload: number) => ({ ...state, ongoingOrders: { ...state.ongoingOrders, delivery: { ...state.ongoingOrders.delivery, BOONS: payload } } }),
  RESET_ONGOING_ORDERS: (state: IUiInitialState) => ({
    ...state,
    ongoingOrders: {
      delivery: {
        SELF: 0,
        BOONS: 0,
        BUYING: 0
      }
    }
  }),
  UI_ACTION_WALLET_POPUP: (state: IUiInitialState, payload: any) => ({ ...state, ui: { ...state.ui, showWalletPopup: payload } }),

  UI_ACTION_LOKOBEE_WALLET_LOGIN: (state: IUiInitialState, payload: any) => ({ ...state, ui: { ...state.ui, isLokobeeLoginFromWallet: payload } })
};
