import React from 'react';
import { IItems } from '../types';
import { filter, map, isEqual } from 'lodash';
import { Button } from '@material-ui/core';
import { useStore } from 'store';
import { classToPlain, plainToClass } from 'class-transformer';
import { Dish, CartItem } from 'models';
import { DishExtraGroup } from 'generated/types';
import { useReward } from 'hooks/restaurant';
import Big from 'big.js';

interface IProps {
  disabled: boolean;
  dish: Dish;
  note: string | null;
  quantity: number;
  selectedSizeIndex: number;
  selectedExtraGroups: { [key: number]: IItems[] };
  handleClose: () => void;
  currency: 'USD' | 'POINT';
  discountPercent: string | null;
}

const AddDishButton: React.FC<IProps> = ({ disabled, dish, selectedExtraGroups, selectedSizeIndex, handleClose, quantity, note, currency, discountPercent }) => {
  const {
    state: { cartItems, cartRewardItems },
    dispatch
  } = useStore();

  const { incUsedPoints } = useReward();

  const addToCart = (cartItem: CartItem) => {
    dispatch({
      type: 'CART_ACTION_ADD',
      payload: { ...cartItem, isReward: false, rewardPoints: 0 }
    });
  };

  const addRewardToCart = (cartItem: CartItem, rewardPoints: string, quantity: number) => {
    dispatch({
      type: 'CART_ACTION_REWARD_ADD',
      payload: cartItem
    });

    const pointsToAdd = Big(rewardPoints)
      .times(quantity)
      .toString();

    //Adding dish points to used points
    incUsedPoints(pointsToAdd);
  };

  const onClickHandler = () => {
    if (dish.price) {
      const price = dish.price[selectedSizeIndex];

      const dishObject = classToPlain(dish);

      const extraGroups: DishExtraGroup[] = [];

      if (dish.extraGroups && dish.extraGroups.length) {
        for (const i in selectedExtraGroups) {
          if (selectedExtraGroups[i] && selectedExtraGroups[i].length) {
            const group = dish.extraGroups[i];

            const indexArray = map(selectedExtraGroups[i], ({ index }) => index);

            const items = filter(dish.extraGroups[i].items, (_, index) => indexArray.includes(index));

            const group1 = {
              ...group,
              items
            };

            extraGroups.push(group1);
          }
        }
      }

      const cart = plainToClass(CartItem, {
        ...dishObject,
        price: [price],
        quantity,
        extraGroups,
        note,
        isPreDiscounted: dish.isDiscounted && !!discountPercent ? true : false,
        discountPercent
      });

      if (cartItems.length === 0 && cartRewardItems.length === 0) {
        if (currency === 'POINT' && dish.isReward) {
          //Adding dish points to used points
          addRewardToCart(cart, dish.rewardPoints, quantity);
        } else {
          addToCart(cart);
        }
      } else {
        const firstItem = cartItems[0] || cartRewardItems[0];

        const firstCartItemRestaurantId = firstItem?.restaurant?.id;

        const newItemRestaurantId = cart.restaurant?.id;

        if (!isEqual(firstCartItemRestaurantId, newItemRestaurantId)) {
          dispatch({
            type: 'CART_ACTION_CLEAR_ALL'
          });

          dispatch({
            type: 'SET_RESTAURANT_USED_POINTS',
            payload: 0
          });
        }

        if (currency === 'POINT' && dish.isReward) {
          //Adding dish points to used points
          addRewardToCart(cart, dish.rewardPoints, quantity);
        } else {
          addToCart(cart);
        }
      }

      handleClose();
    }
  };

  return (
    <Button variant="contained" color="primary" fullWidth={true} disabled={disabled} onClick={onClickHandler} disableElevation={true}>
      Add to cart
    </Button>
  );
};

export default AddDishButton;
