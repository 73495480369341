import { useEffect, useState } from 'react';
import { useStore } from 'store';

export default () => {
  const [restaurantId, setRestaurantId] = useState<string | number | null>(null);

  const { state } = useStore();

  useEffect(() => {
    const { restaurant } = state;
    if (restaurant) {
      setRestaurantId(restaurant);
    } else {
      setRestaurantId(null);
    }
  }, [state]);

  return {
    restaurantId
  };
};
