import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  form: {
    display:"flex",
    flexDirection:"column",
    flex:1,
    overflow:"auto",
  },
  dialogContent: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    '&:first-child': {
      padding: 0
    }
  },
  payment: {
    '& .MuiExpansionPanelSummary-root.Mui-expanded': {
      minHeight: 0
    },
    '& .MuiExpansionPanelSummary-content.Mui-expanded': {
      margin: 0
    }
  },
  expansionDetails: {
    display: 'block'
  },
  expansionPanelSummary: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: theme.palette.grey[200],
    borderRadius: '5px',
  },
  expansionPanelDetails: {
    padding: 0
  },
  expansionPanel: {
    boxShadow: 'none'
  }
}));
