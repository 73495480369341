import React from 'react';
import { Backdrop, Box, CircularProgress, Typography } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

interface IProps {
  open: boolean;
  done?: boolean;
  loadingText?: string;
  doneText?: string;
}

const LOADIND_TEXT = 'Verifying';

const DONE_TEXT = 'Code sent';

const Loading: React.FC<IProps> = ({ open, done = false, loadingText = LOADIND_TEXT, doneText = DONE_TEXT }) => {
  return (
    <Backdrop open={open} style={{ zIndex: 300000 }}>
      <Box display="flex" bgcolor="#fff" padding={3} alignItems="center" justifyItems="center" flexDirection="row">
        <Box component="span" marginRight={1}>
          {done ? <DoneIcon /> : <CircularProgress size="1rem" />}
        </Box>
        <Typography variant="body2">{done ? doneText : loadingText}</Typography>
      </Box>
    </Backdrop>
  );
};

export default Loading;
