import React, { useState, useCallback, useRef } from 'react';
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';
import { Box, Typography, Dialog, DialogContent, useMediaQuery } from '@material-ui/core';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import { grey } from '@material-ui/core/colors';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useAlert, useLocation } from 'hooks';
import { useStyles } from './style';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Transition from 'components/Transition';
import InputField from '../Inputs/InputField';
import ClearIcon from '@material-ui/icons/Clear';
import Logger from 'util/logger';

const IconStyle: React.CSSProperties = {
  color: grey[500],
  fontSize: '1.234rem',
  marginRight: 10
};

interface IProps {
  onSelect: ({ address, lat, lng, addressComponent }: { address: string; lat: number; lng: number; addressComponent: any }) => void;
  address: string;
  children: (address: string) => React.ReactElement;
  showFullAddress?: boolean;
}

/**
 * Sets location for restaurant
 */
const Location: React.FC<IProps> = ({ onSelect, children, address, showFullAddress }) => {
  const classes = useStyles();

  const [value, setValue] = useState('');

  const { lokoAlert } = useAlert();

  const [isOpen, setOpen] = useState(false);

  const { getUserCurrentLocation, getAddressByCoordinates } = useLocation();

  const inputEl = useRef<any>(null);

  const onChange = useCallback((e: string) => {
    setValue(e);
  }, []);

  const handleSelect = async (address: string, placeId: string) => {
    setValue(address);

    if (placeId) {
      try {
        const results = await geocodeByPlaceId(placeId);

        const { addressComponent } = await getAddressByCoordinates({ results }, showFullAddress);

        onSelect({ address, lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng(), addressComponent });

        setValue('');

        setOpen(false);
      } catch (e) {
        console.log(e);

        lokoAlert('Something went wrong');

        Logger.log('Location parse error', {
          error: e,
          locationInput: {
            address,
            placeId
          }
        });
      }
    }
  };

  const onLocationSearchClick = () => {
    const onSuccessCallback = async (position: Position) => {
      const latitude = position.coords.latitude;

      const longitude = position.coords.longitude;

      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({ location: { lat: latitude, lng: longitude } }, async (results: any) => {
        try {
          const { address, addressComponent } = await getAddressByCoordinates(
            {
              results
            },
            showFullAddress
          );

          onSelect({ address, lat: latitude, lng: longitude, addressComponent });

          setOpen(false);
        } catch (e) {
          console.log(e);
        }
      });
    };

    const onErrorCallback = (error: PositionError) => {
      if (error.PERMISSION_DENIED) {
        lokoAlert('Please allow to use geolocation in site setting');
      }
    };

    getUserCurrentLocation(onSuccessCallback, onErrorCallback);
  };

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setValue('');

    setOpen(false);
  };

  const onClear = () => {
    setValue('');

    inputEl?.current?.focus();
  };

  const fullScreen = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <>
      <Box paddingLeft={1} onClick={onOpen}>
        {children && children(address)}
      </Box>
      <Dialog open={isOpen} TransitionComponent={Transition} fullScreen={fullScreen} fullWidth={true}>
        <DialogContent className={classes.dialogContent}>
          <PlacesAutocomplete
            value={value}
            onChange={onChange}
            onSelect={handleSelect}
            searchOptions={{
              types: !showFullAddress ? ['(cities)'] : undefined
            }}>
            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
              <Box maxHeight={fullScreen ? '100%' : '500px'} display="flex" flexDirection="column">
                <Box display="flex" justifyItems="center" alignItems="center" padding={1} borderBottom={`1px solid ${grey[100]}`}>
                  <ArrowBackIcon onClick={onClose} color="inherit" style={{ marginRight: '8px' }} />
                  <Box bgcolor="white" flex={1} display="flex" justifyItems="center" alignItems="center" border="none">
                    <InputField
                      {...getInputProps()}
                      className={classes.inputField}
                      placeholder="Enter area, street name ..."
                      fullWidth={true}
                      endIcon={value ? <ClearIcon onClick={onClear} /> : undefined}
                      inputRef={inputEl}
                    />
                  </Box>
                </Box>
                <Box display="flex" padding={2} onClick={onLocationSearchClick}>
                  <GpsFixedIcon style={IconStyle} />
                  <Typography variant="caption" noWrap={true}>
                    Use current location
                  </Typography>
                </Box>
                <Box flex={1} overflow="scroll">
                  <Box>
                    <Box bgcolor="white" width="100%" zIndex={2000}>
                      {suggestions.map((suggestion, index) => (
                        <Box display="flex" padding={2} borderBottom={`1px solid ${grey[100]}`} {...getSuggestionItemProps(suggestion)} key={index}>
                          <LocationOnIcon style={IconStyle} />
                          <Typography variant="caption" noWrap={true}>
                            {suggestion.description}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </PlacesAutocomplete>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Location;
