import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useStyles } from './style';
import S3Image from 'components/S3Image';
import restaurant_empty_icon from 'assets/img/restaurant-empty-icon.png';
import geoDistance from 'util/geoDistance';
import { useStore } from 'store';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

interface IProps {
  logo: string | null | undefined;
  imgSrc: string | null | undefined;
  title: string;
  subName: string;
  link: string;
  lat: number | null | undefined;
  lng: number | null | undefined;
  isCouponAvailable?: boolean;
  isRewardAvailable?: boolean;
  takeOut: boolean;
  takeOutPause: boolean;
  dining: boolean;
  diningPause: boolean;
  delivery: boolean;
  deliveryPause: boolean;
  rewardPoints?: number;
  cryptoCouponEnabled: boolean;
  cryptoCouponExists: boolean;
}

const Card: React.FC<IProps> = ({
  imgSrc,
  logo,
  title,
  subName,
  link,
  lat,
  lng,
  isCouponAvailable = false,
  isRewardAvailable = false,
  takeOut,
  dining,
  delivery,
  rewardPoints = 0,
  deliveryPause,
  diningPause,
  takeOutPause,
  cryptoCouponEnabled,
  cryptoCouponExists
}) => {
  const classes = useStyles();

  const {
    state: { user }
  } = useStore();
  const {
    coordinate: { latitude, longitude }
  } = user;

  let miles: number | null = null;

  if (lat && lng) {
    miles = geoDistance(latitude, longitude, lat, lng, 'M');
  }

  return (
    <Box className={classes.root} boxShadow={3}>
      <Link to={link}>
        <Grid container={true}>
          <Grid item={true} sm={12} xs={12}>
            <Box height={165} borderRadius={5} overflow="hidden" position="relative">
              <S3Image src={imgSrc} defaultImage={restaurant_empty_icon} />
              <Box display="flex" width="100%" justifyContent="space-between" className={classes.iconsWrapper}>
                <Box display="flex">
                  {isRewardAvailable && (
                    <Box className={classes.rewardsIconWrapper}>
                      <Typography variant="caption">Rewards {`${rewardPoints > 0 ? `- ${rewardPoints} PTS` : ''}`}</Typography>
                    </Box>
                  )}
                </Box>
                <Box className={classes.miles}>
                  <Typography variant="caption"> {miles && latitude !== 0 && longitude !== 0 ? ` ${miles.toFixed(2)} miles` : ''}</Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item={true} sm={12} xs={12}>
            <Box display="flex" alignItems="center" padding={1}>
              <Box className={classes.restaurantLogoBox} overflow="hidden">
                <S3Image src={logo} defaultImage={restaurant_empty_icon} />
              </Box>
              <Box>
                <Box>
                  {subName != null && subName !== '' ? (
                    <Typography variant="subtitle2">
                      {title} ({subName})
                    </Typography>
                  ) : (
                    <Typography variant="subtitle2">{title}</Typography>
                  )}
                </Box>

                <Box display="flex" alignItems="center" flexWrap="wrap">
                  {takeOut && (
                    <Typography variant="body2" color={takeOutPause ? 'error' : 'initial'} className={classes.restaurantDetail}>
                      {`Takeout ${takeOutPause ? '(Paused)' : ''}`}
                    </Typography>
                  )}

                  {dining && <FiberManualRecordIcon color={diningPause ? 'error' : undefined} className={classes.restaurantDetailDot} />}
                  {dining && (
                    <Typography variant="body2" color={diningPause ? 'error' : 'initial'} className={classes.restaurantDetail}>
                      {`Dining ${diningPause ? '(Paused)' : ''}`}
                    </Typography>
                  )}
                  {delivery && <FiberManualRecordIcon color={deliveryPause ? 'error' : undefined} className={classes.restaurantDetailDot} />}
                  {delivery && (
                    <Typography variant="body2" color={deliveryPause ? 'error' : 'initial'} className={classes.restaurantDetail}>
                      {`Delivery ${deliveryPause ? '(Paused)' : ''}`}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
            {((takeOut && takeOutPause) || (delivery && deliveryPause) || (dining && diningPause)) && (
              <Box paddingX={1} paddingBottom={0.5}>
                <Typography className={classes.restaurantDetail} variant="body2" color="error">
                  Temporarily paused for the day, future orders can be placed.
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Link>
    </Box>
  );
};

export default Card;
