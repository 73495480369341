import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme) => ({
  phoneInputField: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    background: 'transparent',
    border: `solid 1px ${grey[500]}`,
    borderRadius: 4,
    '& input': {
      background: 'transparent',
      border: 'none',
      //   fontFamily: "'Itim'",
      outline: 'none',
      '&::placeholder': {
        color: grey[500],
        fontWeight: '200'
      }
    },
    '& .PhoneInputCountryIcon': {
      border: 'none',
      outline: 'none',
      boxShadow: 'none'
    }
  },
  textField: {
    marginBottom: theme.spacing(2)
  },
  helperText: {
    fontWeight: 500
  },
  alignFormLayout: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },

  link: {
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  }
}));
