import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  userIcon: {
    fontSize: 70,
    color: theme.palette.grey[500]
  },
  userDetailsContainer: {
    backgroundColor: theme.palette.grey[100]
  },
  divider: {
    height: '1px'
  },
  versionContainer: {
    padding: theme.spacing(2),
  },
  versionText: {
    marginTop: theme.spacing(1),
    marginLeft:theme.spacing(2),
    
  },
  displayLink: {
    cursor: 'pointer'
  }
}));
