import * as Yup from 'yup';

//const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export default Yup.object().shape({
  message: Yup.string()
    .nullable()
    .max(500, 'Message must be atmost 500 characters.'),
  name: Yup.string()
    .nullable()
    .max(500, 'Name must be atmost 100 characters.'),
  email: Yup.string()
    .nullable()
    .email('Emai is not valid')
    .max(500, 'Email must be atmost 100 characters.'),
  phoneNumber: Yup.string()
    .nullable()
    .max(15, 'Phone number must be atmost 15 characters')
});
