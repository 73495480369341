import gql from 'graphql-tag';
import { Resolvers } from 'apollo-client';
import { ApolloCache } from 'apollo-cache';

export const typeDefs = gql`
  type CartItem {
    id: String!
    name: String!
  }

  extend type Query {
    cartItems: [CartItem]
  }
`;

type ResolverFn = (parent: any, args: any, { cache }: { cache: ApolloCache<any> }) => any;

interface IResolverMap {
  [field: string]: ResolverFn;
}

interface IAppResolvers extends Resolvers {
  // We will update this with our app's resolvers later
  Mutation: IResolverMap;
}

export const resolvers: IAppResolvers = {
  Mutation: {
    addToCart: (_, { any }, cache) => {}
  }
};
