import React, { useReducer, useContext } from 'react';
import { restaurantActions, restaurantInitialState, IRestaurantState } from './restaurantActions';
import { drawerInitialState, drawerActions, IDrawerInitialState } from './drawerActions';
import { cartActions, cartInitialState, ICartItems } from './cartActions';
import { gloabalLoaderActionInitialState, globalLoaderActions, IGlobaleLoaderActionInitialState } from './globalLoaderActions';
import { IRestaurantMenuPageState, menuPageInitialState, menuPageActions } from './restaurantMenuPageActions';
import { userActionsInitialState, userActions, IUserActionsInitialState } from './userActions';
import { uiActionInitialState, uiActions, IUiInitialState } from './uiActions';
import { IGroceryStoreState, groceryStoreActions, groceryStoreInitialState } from './groceryStoreActions';

import { IGroceryCartItems, groceryCartActions, groceryCartInitialState } from './groceryCartActions';

type IInitialState = ICartItems &
  IDrawerInitialState &
  IGlobaleLoaderActionInitialState &
  IRestaurantState &
  IRestaurantMenuPageState &
  IUserActionsInitialState &
  IUiInitialState &
  IGroceryStoreState &
  IGroceryCartItems;

const initialState: IInitialState = {
  ...restaurantInitialState,
  ...drawerInitialState,
  ...cartInitialState,
  ...gloabalLoaderActionInitialState,
  ...menuPageInitialState,
  ...userActionsInitialState,
  ...uiActionInitialState,
  ...groceryStoreInitialState,
  ...groceryCartInitialState
};

const Actions = {
  ...restaurantActions,
  ...drawerActions,
  ...cartActions,
  ...globalLoaderActions,
  ...menuPageActions,
  ...userActions,
  ...uiActions,
  ...groceryStoreActions,
  ...groceryCartActions
} as any;

interface IAction {
  type: string;
  payload?: any;
}

const reducer = (state: IInitialState, action: IAction): IInitialState => {
  const act = Actions[action.type];

  const update = act(state, action.payload);

  return { ...state, ...update };
};

const LoaderContext = React.createContext<{
  state: IInitialState;
  dispatch: React.Dispatch<IAction>;
}>({
  state: initialState,
  dispatch: () => null
});

export const LoaderStore: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <LoaderContext.Provider value={{ state, dispatch }}>{children}</LoaderContext.Provider>;
};

export const useStore = () => {
  const { state, dispatch } = useContext(LoaderContext);
  return { state, dispatch };
};

export default {};
