import React from 'react';
import { useStyles } from '../style';
import { Box, Typography } from '@material-ui/core';
import RewardsLayout from 'components/RewardsLayout';

interface IProps {
  title: string;
  redeemPoints: number;
}

const RedeemRequested = ({ title, redeemPoints }: IProps) => {
  const classes = useStyles();

  const section1 = (
    <Box display="flex" flexDirection="column" color="white" height="95%" justifyContent="space-around">
      <Box textAlign="center">
        <Typography variant="h4" className={classes.uppercase}>
          {title}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-around">
        <Box display="flex" flexDirection="column" textAlign="center">
          <Typography variant="h4">
            <span className={classes.bold}>{redeemPoints} </span>
            Points
          </Typography>
          <Typography variant="body2">Subtotal from receipt</Typography>
        </Box>
      </Box>
    </Box>
  );

  const section2 = (
    <Box display="flex" flexDirection="column" height="100%" justifyContent="space-evenly" alignItems="center">
      <Typography variant="body2">Please wait...</Typography>
      <img src="images/smiley.png" height="100px" alt="Dish" />
    </Box>
  );

  return <RewardsLayout section1={section1} section2={section2} />;
};

export default RedeemRequested;
