import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    '&:first-child': {
      padding: 0
    }
  },
  dialog: {
    '& .MuiPaper-root': {
      height: '100%'
    }
  },
  divider: {
    height: '2px',
    margin: theme.spacing(1, 0)
  }
}));
