import { CartItem } from 'models';
import { map, find } from 'lodash';
import { OrderType, PaymentType, DishType, Address } from 'generated/types';
import { DeliveryMode, DeliveryProvider } from 'generated/custom';

interface IArgs {
  cartItems: CartItem[];
  cartRewardItems: CartItem[];
  type: OrderType;
  totalAmount: string;
  tipAmount: string;
  expectTime: number;
  note: string;
  paymentType: PaymentType;
  email: string;
  orderPhoneNumber: string;
  paymentMethod?: any;
  tableName: string;
  partySize: number;
  deliveryAddress: Address | null;
  deliveryNote: string;
  couponToken: string | null;
  serviceFee: string | null;
  redeemPoints: number;
  isPrediscountEnabled: boolean;
  boonsQuoteId?: string;
  deliveryProvider?: DeliveryProvider;
  deliveryMode?: DeliveryMode;
  deliveryFee?: string;
  dropOffInstruction?: string;
  apartmentNumber?: string;
  doordashClassicQuoteId?: string | null;
  phoneOrder?: boolean;
  sessionId?: string | null;
}

export const createOrderInput = ({
  cartItems,
  cartRewardItems,
  type,
  totalAmount,
  tipAmount,
  expectTime,
  note,
  paymentType,
  email,
  orderPhoneNumber,
  partySize: partySizeArg,
  tableName: tableNameArg,
  deliveryAddress: deliveryAddressArg,
  deliveryNote: deliveryNoteArg,
  couponToken,
  serviceFee,
  redeemPoints,
  isPrediscountEnabled,
  boonsQuoteId,
  deliveryProvider,
  deliveryMode,
  deliveryFee,
  apartmentNumber,
  dropOffInstruction,
  doordashClassicQuoteId,
  phoneOrder,
  sessionId
}: IArgs) => {
  try {
    cartItems = [...cartItems, ...cartRewardItems];

    let isCartHavingPrediscountedItem = false;

    const restaurantId = cartItems[0].restaurant?.id;

    const items = map(cartItems, (item) => {
      if (item.isPreDiscounted) {
        isCartHavingPrediscountedItem = true;
      }

      if (item.type === DishType.Pizza) {
        const pizzaToppings = item.pizzaToppingGroupsOrderLineItems;

        const pizzaBasePriceId = find(item?.pizzaBasePrice, ({ size }) => size.id === item.pizzaActiveSizeId)?.id;

        const pizzaCrustId = item.pizzaActiveCrustId;

        const pizzaCheeses = item.pizzaCheeseOrderLineItems;

        const pizzaSauces = item.pizzaSaucesOrderLineItems;

        return {
          pizzaToppings,
          pizzaBasePriceId,
          pizzaCheeses,
          pizzaSauces,
          pizzaCrustId,
          dishId: item.id,
          count: item.quantity,
          note: item.note,
          isReward: item.isReward
        };
      } else {
        const dishPriceId = item && item.price && item.price[0].id;

        const dishExtras = [];

        if (item.extraGroups && item.extraGroups.length) {
          for (const extra of item.extraGroups) {
            for (const extraItem of extra.items) {
              dishExtras.push({
                dishExtraGroupId: extra.id,
                dishExtraItemId: extraItem.id,
                count: 1
              });
            }
          }
        }

        return {
          dishId: item.id,
          dishPriceId,
          dishExtras,
          count: item.quantity,
          note: item.note,
          isReward: item.isReward
        };
      }
    });

    const total = {
      strValue: totalAmount,
      currencyCode: 'usd'
    };

    const tip = {
      strValue: tipAmount,
      currencyCode: 'usd'
    };

    const lokobeeFee =
      (cartItems[0].restaurant?.lokobeeFee && {
        strValue: serviceFee,
        currencyCode: 'usd'
      }) ||
      null;

    const tableName = type === OrderType.Dining ? tableNameArg : null;

    const partySize = type === OrderType.Dining ? partySizeArg : null;

    const deliveryAddress = type === OrderType.Delivery ? deliveryAddressArg : null;

    const deliveryNote = type === OrderType.Delivery ? deliveryNoteArg : null;

    const input: any = {
      sellerBizId: restaurantId,
      type,
      items,
      total,
      tip,
      expectTime,
      note,
      paymentType,
      smsNotification: true,
      email,
      orderPhoneNumber,
      tableName,
      partySize,
      deliveryAddress,
      deliveryNote,
      couponToken,
      lokobeeFee,
      redeemPoints,
      isDiscounted: isPrediscountEnabled && isCartHavingPrediscountedItem,
      boonsQuoteId,
      doordashClassicQuoteId,
      deliveryProvider,
      apartmentNumber,
      deliveryMode,
      phoneOrder,
      sessionId
    };

    if (type === OrderType.Delivery) {
      input.dropOffInstruction = dropOffInstruction;
      input.apartmentNumber = apartmentNumber;
    }

    if (deliveryFee) {
      const deliveryFeeMoney = {
        strValue: deliveryFee,
        currencyCode: 'usd'
      };

      input.deliveryFee = deliveryFeeMoney;
    }

    // Free delivery for 5 miles
    if (deliveryProvider === 'DOORDASH_CLASSIC' && !deliveryFee) {
      input.deliveryFee = {
        strValue: '0.00',
        currencyCode: 'usd'
      };
    }

    return input;
  } catch (e) {
    console.log('ERROR::CREATE_ORDER_INPUT:', (e as any).message);
  }
};
