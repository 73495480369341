import { GroceryStore, Maybe } from 'generated/types';
import { defaultTimeZone } from 'generated/custom';
import { toTaxRateStr } from 'util/number';

class GroceryStoreModel implements GroceryStore {
  id!: string;

  phone?: Maybe<string> | undefined;

  createdAt: any;

  updatedAt: any;

  owner?: import('../generated/types').LokobeeUser | null | undefined;

  name?: string | null | undefined;
  announcement?: Maybe<string> | undefined;
  description?: string | null | undefined;
  gallery?: Array<import('../generated/types').MultiSizeImage> | null | undefined;

  categories?: import('../generated/types').GroceryStoreCategory[] | null | undefined;

  logo?: import('../generated/types').Image | null | undefined;

  address?: import('../generated/types').Address | null | undefined;

  timezone?: string | null | undefined;

  taxModes?: import('../generated/types').TaxMode[];

  status?: import('../generated/types').GroceryStoreStatus;

  enableTakeoutOrder!: boolean;

  enableDeliveryOrder!: boolean;

  bizHours?: import('../generated/types').GroceryBizHour[];

  takeoutPaymentSetting?: import('../generated/types').GroceryPaymentSetting;

  deliveryPaymentSetting?: import('../generated/types').GroceryPaymentSetting;

  deliverySetting?: import('../generated/types').GroceryDeliverySetting;

  takeoutWaitMinutes?: number;

  lokobeeFee!: boolean;
  lokobeeFeeLimit?: number;
  lokobeeFeePercent?: number;

  coupons?: Array<import('../generated/types').GroceryCoupon> | null | undefined;

  hasStripeAccount?: boolean;

  stripeAccount?: string;

  issueRewardPoint?: boolean;

  rewardSetting?: import('../generated/types').GroceryRewardSetting | null | undefined;

  bizPlan?: import('../generated/types').GroceryBizPlan | null | undefined;

  deliveryProvider?: import('../generated/types').GroceryDeliveryProviders | null | undefined;

  deliveryMode?: import('../generated/types').GroceryDeliveryModes | null | undefined;

  get getId() {
    return this.id;
  }

  get getTimezone() {
    return this.timezone ? this.timezone : defaultTimeZone;
  }

  get getLogo() {
    if (this.logo) {
      return this.logo.url;
    }

    return null;
  }

  get getLatLng() {
    if (this.address) {
      const { lat, lng } = this.address;

      return {
        lat,
        lng
      };
    }

    return null;
  }

  get getTaxModes() {
    if (this.taxModes) {
      const taxModesObj: any = {};
      this.taxModes.forEach((mode) => {
        const { intValue, shift } = mode.tax || { intValue: 0, shift: -2 };

        taxModesObj[mode.title] = toTaxRateStr(intValue, shift);
      });

      return taxModesObj;
    }

    return null;
  }

  get getGalleryImage() {
    if (this.gallery && this.gallery) {
      return this.gallery;
    }
    return null;
  }
}

export default GroceryStoreModel;
