import { Box, Button, Checkbox, CircularProgress, Dialog, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import DialogHeader from 'components/DialogHeader';
import { useStyles } from './style';
import { getIn, useFormik } from 'formik';
import { useUpdateUserMutation } from 'graphql/hooks/user.hooks';
import { getLokobeeUserQuery } from 'graphql/query';
import { useAlert } from 'hooks';
import React, { ChangeEvent, useState } from 'react';
import PhoneInput, { Country } from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useHistory } from 'react-router-dom';
import { useStore } from 'store';
import { isEqual } from 'lodash';

//TODO: user guest
interface IProps {
  totalPriceAfterTax?: string | undefined;
  onHandleClose: () => void;
}

function UpdatePhoneNumber({ totalPriceAfterTax, onHandleClose }: IProps) {
  const classes = useStyles();
  const { dispatch } = useStore();

  const [rememberMe, setRememberMe] = useState<boolean>(false);

  const [currentCountry, setCurrentCountry] = useState<string>('US');

  const handleChecked = () => {
    setRememberMe(!rememberMe);
  };

  const { updateUser, loading } = useUpdateUserMutation();

  const setUserInStore = (phoneNumber: string) => {
    dispatch({
      type: 'USER_ACTION_SET_CONTACT_DETAILS',
      payload: {
        phoneNumber
      }
    });
  };
  const history = useHistory();

  const { lokoAlert } = useAlert();

  const validate = async (values: { phonenumber: string; rephonenumber: string }) => {
    const errors: any = {};
    console.log();
    if (!values.phonenumber && !values.rephonenumber) {
      errors.phone = 'no error';
    } else if (!values.phonenumber && values.rephonenumber) {
      errors.phone = 'Please Enter Phone Number';
    } else if (values.phonenumber !== values.rephonenumber) {
      errors.phone = 'Values did not match';
    } else if (!isValidPhoneNumber(values.phonenumber)) {
      errors.phone = 'Enter valid phone number';
    }
    return errors;
  };

  const errorCheck = () => {
    if (getIn(formik.errors, 'phone') === 'no error') {
      return false;
    } else if (!getIn(formik.errors, 'phone')) {
      return false;
    } else {
      return true;
    }
  };

  const rewardDishCheck = () => {
    if (totalPriceAfterTax && parseInt(totalPriceAfterTax) === 0) {
      // This is a reward dish so not payment.
      history.push('/payment/onsite');
    } else if (totalPriceAfterTax) {
      history.push('/payment');
    } else {
      onHandleClose();
    }
  };

  const formik = useFormik({
    initialValues: {
      phonenumber: '',
      rephonenumber: ''
    },
    // enableReinitialize: true,
    validate,
    onSubmit: async (values) => {
      const { phonenumber } = values;

      if (rememberMe === true) {
        formik.setSubmitting(true);
        try {
          const response = await updateUser({
            variables: {
              input: {
                orderPhoneNumber: phonenumber
              }
            },
            update: (cache) => {
              const { getLokobeeUser: oldData }: any = cache.readQuery({
                query: getLokobeeUserQuery
              });

              cache.writeQuery({
                query: getLokobeeUserQuery,
                data: { getLokobeeUser: { ...oldData, orderPhoneNumber: phonenumber } }
              });
            }
          });
          if (!response.errors) {
            setUserInStore(phonenumber);
            formik.setSubmitting(true);
            rewardDishCheck();
          } else {
            lokoAlert('Something went wrong.');
          }
        } catch (e) {
          formik.setSubmitting(false);
          lokoAlert('Something went wrong.');
        }
      } else {
        setUserInStore(phonenumber);
        rewardDishCheck();
      }
    }
  });

  const onReviewDone = () => {
    if (formik.dirty && !isEqual(formik.initialValues, formik.values)) {
      // * calling mutation
      formik.submitForm();
    } else {
      rewardDishCheck();
    }
  };

  return (
    <Dialog open={true} fullScreen={false}>
      <DialogHeader text="Update Contact Information" onBack={onHandleClose} />
      <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <Box display="flex" alignItems="center" paddingX={6} paddingTop={2}>
              <Typography variant="caption" align="center">
                The following information will be used to communicate the status of the current order.
              </Typography>
            </Box>
            <Box paddingTop={2}>
              <Box paddingX={3} paddingTop={1}>
                <PhoneInput
                  className={classes.phoneInputField}
                  name="phonenumber"
                  placeholder="Enter Your Phone number"
                  defaultCountry={currentCountry as Country}
                  value={formik.values.phonenumber}
                  autoComplete="off"
                  onCountryChange={(country) => {
                    country && setCurrentCountry(country);
                  }}
                  onBlur={formik.handleBlur('phonenumber')}
                  onChange={(val: string) => {
                    if (val) {
                      formik.handleChange({ target: { value: val, name: 'phonenumber' } } as ChangeEvent<HTMLInputElement>);
                    } else {
                      formik.handleChange({ target: { value: '', name: 'phonenumber' } } as ChangeEvent<HTMLInputElement>);
                    }
                  }}
                />
                <PhoneInput
                  className={classes.phoneInputField}
                  name="rephonenumber"
                  placeholder="Confirm Your Phone number"
                  defaultCountry={currentCountry as Country}
                  value={formik.values.rephonenumber}
                  countrySelectProps={{ disabled: true }}
                  autoComplete="off"
                  onBlur={formik.handleBlur('rephonenumber')}
                  onPasteCapture={(e: React.ClipboardEvent<string>) => {
                    e.preventDefault();
                    return false;
                  }}
                  onChange={(val: string) => {
                    if (val) {
                      formik.handleChange({ target: { value: val, name: 'rephonenumber' } } as ChangeEvent<HTMLInputElement>);
                    } else {
                      formik.handleChange({ target: { value: '', name: 'rephonenumber' } } as ChangeEvent<HTMLInputElement>);
                    }
                  }}
                />
                {getIn(formik.errors, 'phone') !== 'no error' && (
                  <Box paddingX={1} marginBottom={1}>
                    <Typography variant="body1" color="error">
                      <Box fontWeight="500">{getIn(formik.errors, 'phone')}</Box>
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box paddingY={1} paddingX={3}>
                <FormGroup>
                  <FormControlLabel control={<Checkbox checked={rememberMe} onChange={handleChecked} />} label="Remember for all future orders" />
                </FormGroup>
              </Box>
            </Box>
          </Box>
          <Box paddingX={3} paddingBottom={3} position="relative">
            <Button variant="contained" color="primary" fullWidth disabled={errorCheck() || formik.isSubmitting} onClick={() => onReviewDone()}>
              {totalPriceAfterTax ? 'Update & Continue' : 'Save'}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Box>
        </form>
      </Box>
    </Dialog>
  );
}

export default UpdatePhoneNumber;
