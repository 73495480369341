import React, { useState } from 'react';
import { chain, includes } from 'lodash';
import DishDetails from 'components/DishDetails';
import { Grid, Box, Divider } from '@material-ui/core';
import { useStyles } from './style';
import DishDialog from 'components/DishDialog';
import { DishTag } from 'generated/types';
import Big from 'big.js';
import { useReward } from 'hooks/restaurant';

interface IProps {
  dishes: any;
  loading: Boolean;
}

const Menu: React.FC<IProps> = ({ dishes, loading }) => {
  const classes = useStyles();

  const [isDishDialogOpen, setDishDialogOpen] = useState(false);

  const [activeDish, setActiveDish] = useState<null | string>(null);

  const { remainingPoints, isRedeemAllowed } = useReward();

  const _dishGridItemOnClick = (id: string) => {
    setActiveDish(id);

    setDishDialogOpen(true);
  };

  const _handleClose = () => {
    setDishDialogOpen(false);
  };

  if (loading) {
    return null;
  }

  if (dishes && dishes.length) {
    const dataList = chain(dishes)
      .map(({ id: dishId, getTitle, images, rewardPoints, getDescription, getTags, isAlcohol, rateScore, rateCount }) => {
        const title = getTitle;

        const points = rewardPoints;

        const description = getDescription;

        const {
          preview: { url }
        } = (images && images[0]) || { preview: { url: null } };

        const popular: DishTag = DishTag.Popular;

        const isPopular = includes(getTags, popular);

        const isDishEligible = Big(remainingPoints).gte(points) && isRedeemAllowed;

        return (
          <Grid
            style={!isDishEligible ? { opacity: '0.5' } : {}}
            item={true}
            sm={6}
            md={6}
            xs={12}
            zeroMinWidth={true}
            key={dishId}
            onClick={() => {
              isDishEligible && _dishGridItemOnClick(dishId);
            }}>
            <DishDetails
              title={title}
              description={description}
              imgSrc={url}
              points={points}
              isPopular={isPopular}
              isAlcohol={isAlcohol}
              currency="POINT"
              rateScore={rateScore}
              rateCount={rateCount}
            />
            <Box paddingX="5%">
              <Divider />
            </Box>
          </Grid>
        );
      })
      .value();

    return (
      <Box>
        <Box className={classes.dishListWrapper}>
          <Grid container={true} spacing={1} alignItems="stretch">
            {dataList}
          </Grid>
        </Box>
        <DishDialog isOpen={isDishDialogOpen} handleClose={_handleClose} id={activeDish} currency="POINT" />
      </Box>
    );
  }

  return null;
};

export default Menu;
