import Big from 'big.js';
import { applyDiscount, convertPrice, convertPriceTo } from 'util/number';
import { groupBy, map, filter } from 'lodash';

export const parseOrderItems = (items: any, isOrderDiscounted: boolean | null | undefined = false) => {
  if (items && items.length) {
    return items.map((item: any) => {
      const { dishPrice } = item;

      const dishType = item.dishType || 'REGULAR';

      if (dishType === 'REGULAR') {
        let finalPrice = Big(0);

        let strikedPrice = Big(0);

        let discountPercent = null;

        const { dishExtras, isDiscounted, ...rest } = item;

        const {
          price: { intValue, shift },
          discountAmount
        } = dishPrice || { price: { intValue: 0, shift: 0 }, discountAmount: 0 };

        finalPrice = Big(convertPrice(intValue, shift));

        if (!!discountAmount && isDiscounted && isOrderDiscounted) {
          const discount = applyDiscount(finalPrice.toFixed(2), convertPriceTo(discountAmount, 'DOLLAR'));

          discountPercent = discount.percentage;
        }

        if (dishExtras && dishExtras.length) {
          let sum = Big(0);

          for (const extras of dishExtras) {
            const {
              dishExtraItem: {
                price: { intValue: _intValue, shift: _shift }
              }
            } = extras;
            const price = convertPrice(_intValue, _shift);

            sum = sum.plus(Big(price));
          }

          finalPrice = finalPrice.plus(sum);

          if (!!discountAmount && isDiscounted && isOrderDiscounted) {
            strikedPrice = finalPrice;

            finalPrice = finalPrice.minus(convertPriceTo(discountAmount, 'DOLLAR'));
          }

          const _extras = map(groupBy(dishExtras, 'dishExtraGroupId'), (value) => {
            if (value && value.length) {
              const { dishExtraGroupTitle } = value[0];

              const groupTitle = dishExtraGroupTitle || '';
              const items = map(value, ({ dishExtraItem: { title } }) => {
                const extrasTitle = title || '';
                return extrasTitle;
              });

              return {
                groupTitle,
                items
              };
            } else return value;
          });

          return {
            ...rest,
            discountPercent,
            dishPrice: finalPrice.toFixed(2),
            strikedPrice: !!discountAmount && isDiscounted && isOrderDiscounted ? strikedPrice.toFixed(2) : null,
            dishExtras: _extras,
            dishOriginalPrice: dishPrice
          };
        }
        if (!!discountAmount && isDiscounted && isOrderDiscounted) {
          strikedPrice = finalPrice;

          finalPrice = finalPrice.minus(convertPriceTo(discountAmount, 'DOLLAR'));
        }
        return {
          ...rest,
          discountPercent,
          dishPrice: finalPrice.toFixed(2),
          strikedPrice: !!discountAmount && isDiscounted && isOrderDiscounted ? strikedPrice.toFixed(2) : null,
          dishOriginalPrice: dishPrice
        };
      }

      if (dishType === 'PIZZA') {
        let finalPrice = Big(0.0);

        let strikedPrice = Big(0);

        let discountPercent = null;

        const { pizzaBasePrice, pizzaToppings, pizzaCheeses, pizzaSauces, pizzaCrust, isDiscounted } = item;

        const {
          price: { intValue, shift },
          discountAmount
        } = pizzaBasePrice || { price: { intValue: 0, shift: 0 }, discountAmount: 0 };

        finalPrice = finalPrice.plus(Big(convertPrice(intValue, shift)));

        if (!!discountAmount && isDiscounted && isOrderDiscounted) {
          const discount = applyDiscount(finalPrice.toFixed(2), convertPriceTo(discountAmount, 'DOLLAR'));

          discountPercent = discount.percentage;
        }

        if (pizzaCrust && pizzaCrust.price) {
          const {
            price: { intValue: crustPriceVal, shift: crustPriceShift }
          } = pizzaCrust || { price: { intValue: 0, shift: 0 } };

          finalPrice = finalPrice.plus(Big(convertPrice(crustPriceVal, crustPriceShift)));
        }

        const leftToppings: any = {
          exclusions: [],
          additions: []
        };
        const leftSauces: any = {
          exclusions: [],
          additions: []
        };
        const leftCheeses: any = {
          exclusions: [],
          additions: []
        };

        const rightToppings: any = {
          exclusions: [],
          additions: []
        };
        const rightSauces: any = {
          exclusions: [],
          additions: []
        };
        const rightCheeses: any = {
          exclusions: [],
          additions: []
        };

        let halfAndHalf = false;

        if (pizzaToppings && pizzaToppings.length) {
          pizzaToppings.forEach(({ side, preset, toppingDensity, toppingTitle, toppingPrice }: any) => {
            const { intValue: _intValue, shift: _shift } = toppingPrice || { intValue: 0, shift: 0 };

            let toppingPriceToBeAdded = Big(convertPrice(_intValue, _shift));

            const excludeTitle = `${toppingTitle}`;

            const toppingsText = {
              title: toppingTitle,
              density: toppingDensity,
              price: toppingPriceToBeAdded
            };

            const excludeText = {
              title: excludeTitle,
              price: toppingPriceToBeAdded
            };
            const alreadyPresentRightAdd = filter(rightToppings.additions, ({ title }) => title === toppingTitle)[0];
            const alreadyPresentLeftAdd = filter(leftToppings.additions, ({ title }) => title === toppingTitle)[0];

            if (preset) {
              if (side === 'Left') {
                halfAndHalf = true;

                if (toppingDensity === 'None') {
                  leftToppings.exclusions.push(excludeText);
                } else {
                  if (toppingDensity !== 'Regular') {
                    leftToppings.additions.push(toppingsText);
                  }
                }

                if (alreadyPresentRightAdd) {
                  if (alreadyPresentRightAdd.price.gt(toppingPriceToBeAdded)) {
                    /*
                     * Topping already present in right so price for right topping is already added
                     * If current side price is more than we have to add the remaining price
                     * which is already not added
                     */
                    toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentRightAdd.price);
                  } else {
                    toppingPriceToBeAdded = Big(0);
                  }
                }
              }
              if (side === 'Right') {
                halfAndHalf = true;

                if (toppingDensity === 'None') {
                  rightToppings.exclusions.push(excludeText);
                } else {
                  if (toppingDensity !== 'Regular') {
                    rightToppings.additions.push(toppingsText);
                  }
                }

                if (alreadyPresentLeftAdd) {
                  if (toppingPriceToBeAdded.gt(alreadyPresentLeftAdd.price)) {
                    /*
                     * Topping already present in left so price for left topping is already added
                     * If current side price is more than we have to add the remaining price
                     * which is already not added
                     */
                    toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentLeftAdd.price);
                  } else {
                    toppingPriceToBeAdded = Big(0);
                  }
                }
              }
              if (side === 'Whole' && toppingDensity === 'None') {
                leftToppings.exclusions.push(excludeText);
                rightToppings.exclusions.push(excludeText);
              }
              if (side === 'Whole' && toppingDensity !== 'Regular' && toppingDensity !== 'None') {
                leftToppings.additions.push(toppingsText);
                rightToppings.additions.push(toppingsText);
              }
            } else {
              if (side === 'Left') {
                halfAndHalf = true;
                leftToppings.additions.push(toppingsText);
                if (alreadyPresentRightAdd) {
                  if (alreadyPresentRightAdd.price.gt(toppingPriceToBeAdded)) {
                    /*
                     * Topping already present in right so price for right topping is already added
                     * If current side price is more than we have to add the remaining price
                     * which is already not added
                     */
                    toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentRightAdd.price);
                  } else {
                    toppingPriceToBeAdded = Big(0);
                  }
                }
              }
              if (side === 'Right') {
                halfAndHalf = true;
                rightToppings.additions.push(toppingsText);
                if (alreadyPresentLeftAdd) {
                  if (toppingPriceToBeAdded.gt(alreadyPresentLeftAdd.price)) {
                    /*
                     * Topping already present in left so price for left topping is already added
                     * If current side price is more than we have to add the remaining price
                     * which is already not added
                     */
                    toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentLeftAdd.price);
                  } else {
                    toppingPriceToBeAdded = Big(0);
                  }
                }
              }
              if (side === 'Whole') {
                leftToppings.additions.push(toppingsText);
                rightToppings.additions.push(toppingsText);
              }
            }
            finalPrice = finalPrice.plus(toppingPriceToBeAdded);
          });
        }

        if (pizzaSauces && pizzaSauces.length) {
          pizzaSauces.forEach(({ side, preset, toppingDensity, toppingTitle, toppingPrice }: any) => {
            const { intValue: _intValue, shift: _shift } = toppingPrice || { intValue: 0, shift: 0 };

            let toppingPriceToBeAdded = Big(convertPrice(_intValue, _shift));

            const excludeTitle = `${toppingTitle}`;

            const toppingsText = {
              title: toppingTitle,
              density: toppingDensity,
              price: toppingPriceToBeAdded
            };

            const excludeText = {
              title: excludeTitle,
              price: toppingPriceToBeAdded
            };
            const alreadyPresentRightAdd = filter(rightSauces.additions, ({ title }) => title === toppingTitle)[0];
            const alreadyPresentLeftAdd = filter(leftSauces.additions, ({ title }) => title === toppingTitle)[0];

            if (preset) {
              /*
               * If preset
               * If left
               * If not none & not regular - add to leftAdd
               * theres possibility that it is not present in right - so add to rightExc
               * If present in right than remove from rightExc
               * vice versa for right
               */

              if (side === 'Left') {
                halfAndHalf = true;

                if (toppingDensity === 'None') {
                  leftSauces.exclusions.push(excludeText);
                } else {
                  if (toppingDensity !== 'Regular') {
                    leftSauces.additions.push(toppingsText);
                  }
                }
                if (alreadyPresentRightAdd) {
                  if (alreadyPresentRightAdd.price.gt(toppingPriceToBeAdded)) {
                    /*
                     * Topping already present in right so price for right topping is already added
                     * If current side price is more than we have to add the remaining price
                     * which is already not added
                     */
                    toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentRightAdd.price);
                  } else {
                    toppingPriceToBeAdded = Big(0);
                  }
                }
              }

              if (side === 'Right') {
                halfAndHalf = true;

                if (toppingDensity === 'None') {
                  rightSauces.exclusions.push(excludeText);
                } else {
                  if (toppingDensity !== 'Regular') {
                    rightSauces.additions.push(toppingsText);
                  }
                }

                if (alreadyPresentLeftAdd) {
                  if (toppingPriceToBeAdded.gt(alreadyPresentLeftAdd.price)) {
                    /*
                     * Topping already present in left so price for left topping is already added
                     * If current side price is more than we have to add the remaining price
                     * which is already not added
                     */
                    toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentLeftAdd.price);
                  } else {
                    toppingPriceToBeAdded = Big(0);
                  }
                }
              }
              if (side === 'Whole' && toppingDensity === 'None') {
                leftSauces.exclusions.push(excludeText);
                rightSauces.exclusions.push(excludeText);
              }
              if (side === 'Whole' && toppingDensity !== 'Regular' && toppingDensity !== 'None') {
                rightSauces.additions.push(toppingsText);
                leftSauces.additions.push(toppingsText);
              }
            } else {
              if (side === 'Left') {
                halfAndHalf = true;
                leftSauces.additions.push(toppingsText);
                if (alreadyPresentRightAdd) {
                  if (alreadyPresentRightAdd.price.gt(toppingPriceToBeAdded)) {
                    /*
                     * Topping already present in right so price for right topping is already added
                     * If current side price is more than we have to add the remaining price
                     * which is already not added
                     */
                    toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentRightAdd.price);
                  } else {
                    toppingPriceToBeAdded = Big(0);
                  }
                }
              }
              if (side === 'Right') {
                halfAndHalf = true;
                rightSauces.additions.push(toppingsText);
                if (alreadyPresentLeftAdd) {
                  if (toppingPriceToBeAdded.gt(alreadyPresentLeftAdd.price)) {
                    /*
                     * Topping already present in left so price for left topping is already added
                     * If current side price is more than we have to add the remaining price
                     * which is already not added
                     */
                    toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentLeftAdd.price);
                  } else {
                    toppingPriceToBeAdded = Big(0);
                  }
                }
              }
              if (side === 'Whole') {
                leftSauces.additions.push(toppingsText);
                rightSauces.additions.push(toppingsText);
              }
            }
            finalPrice = finalPrice.plus(toppingPriceToBeAdded);
          });
        }

        if (pizzaCheeses && pizzaCheeses.length) {
          pizzaCheeses.forEach(({ side, preset, toppingDensity, toppingTitle, toppingPrice }: any) => {
            const { intValue: _intValue, shift: _shift } = toppingPrice || { intValue: 0, shift: 0 };

            let toppingPriceToBeAdded = Big(convertPrice(_intValue, _shift));

            const excludeTitle = `${toppingTitle}`;

            const toppingsText = {
              title: toppingTitle,
              density: toppingDensity,
              price: toppingPriceToBeAdded
            };

            const excludeText = {
              title: excludeTitle,
              price: toppingPriceToBeAdded
            };
            const alreadyPresentRightAdd = filter(rightCheeses.additions, ({ title }) => title === toppingTitle)[0];
            const alreadyPresentLeftAdd = filter(leftCheeses.additions, ({ title }) => title === toppingTitle)[0];

            if (preset) {
              if (side === 'Left') {
                halfAndHalf = true;

                if (toppingDensity === 'None') {
                  leftCheeses.exclusions.push(excludeText);
                } else {
                  if (toppingDensity !== 'Regular') {
                    leftCheeses.additions.push(toppingsText);
                  }
                }

                if (alreadyPresentRightAdd) {
                  if (alreadyPresentRightAdd.price.gt(toppingPriceToBeAdded)) {
                    /*
                     * Topping already present in right so price for right topping is already added
                     * If current side price is more than we have to add the remaining price
                     * which is already not added
                     */
                    toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentRightAdd.price);
                  } else {
                    toppingPriceToBeAdded = Big(0);
                  }
                }
              }
              if (side === 'Right') {
                halfAndHalf = true;

                if (toppingDensity === 'None') {
                  rightCheeses.exclusions.push(excludeText);
                } else {
                  if (toppingDensity !== 'Regular') {
                    rightCheeses.additions.push(toppingsText);
                  }
                }

                if (alreadyPresentLeftAdd) {
                  if (toppingPriceToBeAdded.gt(alreadyPresentLeftAdd.price)) {
                    /*
                     * Topping already present in left so price for left topping is already added
                     * If current side price is more than we have to add the remaining price
                     * which is already not added
                     */
                    toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentLeftAdd.price);
                  } else {
                    toppingPriceToBeAdded = Big(0);
                  }
                }
              }
              if (side === 'Whole' && toppingDensity === 'None') {
                leftCheeses.exclusions.push(excludeText);
                rightCheeses.exclusions.push(excludeText);
              }
              if (side === 'Whole' && toppingDensity !== 'Regular' && toppingDensity !== 'None') {
                rightCheeses.additions.push(toppingsText);
                leftCheeses.additions.push(toppingsText);
              }
            } else {
              if (side === 'Left') {
                halfAndHalf = true;
                leftCheeses.additions.push(toppingsText);
                if (alreadyPresentRightAdd) {
                  if (alreadyPresentRightAdd.price.gt(toppingPriceToBeAdded)) {
                    /*
                     * Topping already present in right so price for right topping is already added
                     * If current side price is more than we have to add the remaining price
                     * which is already not added
                     */
                    toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentRightAdd.price);
                  } else {
                    toppingPriceToBeAdded = Big(0);
                  }
                }
              }
              if (side === 'Right') {
                halfAndHalf = true;
                rightCheeses.additions.push(toppingsText);
                if (alreadyPresentLeftAdd) {
                  if (toppingPriceToBeAdded.gt(alreadyPresentLeftAdd.price)) {
                    /*
                     * Topping already present in left so price for left topping is already added
                     * If current side price is more than we have to add the remaining price
                     * which is already not added
                     */
                    toppingPriceToBeAdded = toppingPriceToBeAdded.minus(alreadyPresentLeftAdd.price);
                  } else {
                    toppingPriceToBeAdded = Big(0);
                  }
                }
              }
              if (side === 'Whole') {
                leftCheeses.additions.push(toppingsText);
                rightCheeses.additions.push(toppingsText);
              }
            }
            finalPrice = finalPrice.plus(toppingPriceToBeAdded);
          });
        }

        if (!!discountAmount && isDiscounted && isOrderDiscounted) {
          strikedPrice = finalPrice;

          finalPrice = finalPrice.minus(convertPriceTo(discountAmount, 'DOLLAR'));
        }

        return {
          ...item,
          dishPrice: finalPrice.toFixed(2),
          strikedPrice: !!discountAmount && isDiscounted && isOrderDiscounted ? strikedPrice.toFixed(2) : null,
          discountPercent,
          toppings: { leftToppings, rightToppings },
          sauces: { leftSauces, rightSauces },
          cheeses: { leftCheeses, rightCheeses },
          halfAndHalf,
          dishOriginalPrice: pizzaBasePrice
        };
      }

      return { ...item, dishPrice: 0, dishOriginalPrice: dishPrice };
    });
  }
  return items;
};

export const formatPriceToString = (price: any) => {
  if (!price) {
    return null;
  }

  if (price instanceof Big) {
    return price.toFixed(2).toString();
  }

  return Big(price).toFixed(2);
};
