import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(( theme ) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '50%',
      margin: 'auto'
    }
  },
  icon: {
    cursor: 'pointer'
  }
}));