import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    transform: 'translateY(50%)',
    textAlign: 'center'
  },
  loadingIcon: {
    marginTop: '-20px'
  },
  image: {
    height: '100%',
    width: '100%'
  }
}));
