import React, { useState, useEffect } from 'react';
import { Box, Button, ButtonGroup, ButtonProps, Grid, Typography } from '@material-ui/core';
import { useStyles } from './style';
import { CurrencyInputField, CustomBottomDialog } from '@lokobee/lokobee-ui';
import Big from 'big.js';
import { useGetRestaurantById } from 'graphql/hooks';
import { map } from 'lodash';
import { useStore } from 'store';

interface IProps {
  total: string;
  restaurantId: string;
  setTipAmount: (payload: Big) => void;
}

const Tip: React.FC<IProps> = ({ total, restaurantId, setTipAmount }) => {
  const classes = useStyles();

  const {
    state: { orderType, deliveryProvider }
  } = useStore();

  const { restaurant } = useGetRestaurantById(restaurantId, 'PAYMENT_SETTING');

  const [selectedTip, setselectedTip] = useState<null | number>(null);

  const [tipValue, setTipValue] = useState<string>('0');

  const [customTip, setCustomTip] = useState<string>('');

  const [openCustomTipDialog, setOpenCustomTipDialog] = useState(false);

  const btnProps: (_tip: number) => ButtonProps = (_tip: number) => {
    if (_tip === selectedTip) {
      return { className: classes.selectedBtn, variant: 'contained', color: 'primary', disableElevation: true };
    }

    return {};
  };

  useEffect(() => {
    if (selectedTip !== null) {
      setTipValue(
        Big(total)
          .times(Big(selectedTip).div(100))
          .toFixed(2)
      );

      const tipBig = Big(total).times(Big(selectedTip).div(100));

      setTipAmount(tipBig);
    }
  }, [selectedTip, setTipAmount, total]);

  const onButtonGroupChange = (selectedTip1: number) => () => {
    const tipBig = Big(total).times(Big(selectedTip1).div(100));

    const _tip = tipBig.toFixed(2);

    setTipValue(_tip);

    setTipAmount(tipBig);

    setselectedTip(selectedTip1);

    setCustomTip('');
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setCustomTip(value);
  };

  const submitCustomTip = () => {
    if (customTip) {
      const _tip = Big(customTip).toFixed(2);

      setselectedTip(null);

      setTipValue(_tip);

      setTipAmount(Big(customTip));
    }

    closeCustomTipDialog();
  };

  const closeCustomTipDialog = () => {
    setOpenCustomTipDialog(false);
  };

  useEffect(() => {
    setselectedTip(null);
  }, [orderType]);

  useEffect(() => {
    if (restaurant && customTip.trim() === '' && selectedTip === null) {
      const { takeoutPaymentSetting, diningPaymentSetting, deliveryPaymentSetting } = restaurant;

      if (orderType === 'TAKE_OUT') {
        const { tipDefaultPercent } = (!!takeoutPaymentSetting && takeoutPaymentSetting) || { tipDefaultPercent: 0 };

        setselectedTip(tipDefaultPercent ? tipDefaultPercent : 0);
      }

      if (orderType === 'DINING') {
        const { tipDefaultPercent } = (!!diningPaymentSetting && diningPaymentSetting) || { tipDefaultPercent: 0 };

        setselectedTip(tipDefaultPercent ? tipDefaultPercent : 0);
      }

      if (orderType === 'DELIVERY') {
        const { tipDefaultPercent } = (!!deliveryPaymentSetting && deliveryPaymentSetting) || { tipDefaultPercent: 0 };

        setselectedTip(tipDefaultPercent ? tipDefaultPercent : 0);
      }
    }
  }, [selectedTip, restaurant, customTip, orderType, setTipAmount, total]);

  if (restaurant && orderType) {
    const { takeoutPaymentSetting, diningPaymentSetting, deliveryPaymentSetting } = restaurant;

    let tipOptions = null;

    if (orderType === 'TAKE_OUT') {
      const { tipPercentOptions: tipPercentOptionsTakeOut } = (!!takeoutPaymentSetting && takeoutPaymentSetting) || { tipPercentOptions: [0, 10, 15, 20], tipDefaultPercent: 0 };
      tipOptions = map(tipPercentOptionsTakeOut ? tipPercentOptionsTakeOut : [0, 10, 15, 20], (option, index) => {
        return (
          <Button key={index} {...btnProps(option)} onClick={onButtonGroupChange(option)}>
            {option}%
          </Button>
        );
      });
    }

    if (orderType === 'DINING') {
      const { tipPercentOptions: tipPercentOptionsDining } = (!!diningPaymentSetting && diningPaymentSetting) || { tipPercentOptions: [0, 10, 15, 20], tipDefaultPercent: 0 };
      tipOptions = map(tipPercentOptionsDining ? tipPercentOptionsDining : [0, 10, 15, 20], (option, index) => {
        return (
          <Button key={index} {...btnProps(option)} onClick={onButtonGroupChange(option)}>
            {option}%
          </Button>
        );
      });
    }

    if (orderType === 'DELIVERY') {
      const { tipPercentOptions: tipPercentOptionsDelivery } = (!!deliveryPaymentSetting && deliveryPaymentSetting) || { tipPercentOptions: [0, 10, 15, 20], tipDefaultPercent: 0 };
      tipOptions = map(tipPercentOptionsDelivery ? tipPercentOptionsDelivery : [0, 10, 15, 20], (option, index) => {
        return (
          <Button key={index} {...btnProps(option)} onClick={onButtonGroupChange(option)}>
            {option}%
          </Button>
        );
      });
    }

    const getCustomButton = () => {
      if (orderType === 'DELIVERY' && deliveryProvider === 'DOORDASH_CLASSIC') {
        return <></>;
      }
      return (
        <Grid item={true} xs={6}>
          <Box textAlign="right">
            <Button variant="contained" color="secondary" onClick={() => setOpenCustomTipDialog(true)} size="small">
              Custom
            </Button>
          </Box>
        </Grid>
      );
    };

    const getTipText = () => {
      if (orderType === 'DELIVERY' && deliveryProvider === 'DOORDASH_CLASSIC') {
        return 'Please tip your dasher to say thank you';
      } else {
        return 'Add a tip to say thank you';
      }
    };

    return (
      <Box>
        <Grid container={true} spacing={2} className={classes.container}>
          <Grid item={true} xs={8}>
            <Typography variant="body2">{getTipText()}</Typography>
          </Grid>
          <Grid item={true} xs={4}>
            <Typography variant="body2" align="right">
              ${tipValue}
            </Typography>
          </Grid>
        </Grid>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6} className={classes.container}>
            <ButtonGroup size="small" color="default">
              {tipOptions}
            </ButtonGroup>
          </Grid>
          {getCustomButton()}
        </Grid>
        <CustomBottomDialog open={openCustomTipDialog}>
          <Box padding={2}>
            <Typography variant="h6">Enter Tip amount</Typography>
            <br />
            <CurrencyInputField value={customTip} onChange={onChange} />
            <Box margin={2} textAlign="right">
              <Button variant="outlined" className={classes.btns} onClick={closeCustomTipDialog}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" disableElevation={true} className={classes.btns} onClick={submitCustomTip}>
                Submit
              </Button>
            </Box>
          </Box>
        </CustomBottomDialog>
      </Box>
    );
  }

  return null;
};

export default Tip;
