import { makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: 'black',
    display: 'flex'
  },
  ellipse: {
    wordBreak: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    [theme.breakpoints.down('sm')]: {
      WebkitLineClamp: 3
    },
    WebkitBoxOrient: 'vertical',
    color: '#000'
  },
  notice: {
    backgroundColor: theme.palette.primary.main,
    color: '#000'
  },
  image: {
    height: '100%',
    width: '100%',
    borderRadius: theme.spacing(1)
  },
  blink: {
    animation: '$blinker 2s linear infinite'
  },
  couponBox: {
    backgroundColor: theme.palette.success.dark,
    padding: theme.spacing(1),

    color: 'white',
    margin: theme.spacing(1, 0)
  },
  '@keyframes blinker': {
    '70%': { opacity: 0 }
  },
  rewardBtn: {
    textTransform: 'capitalize',
    marginRight: theme.spacing(1),
    fontWeight: 900
  },
  locationIcon: {
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: '50%',
    padding: theme.spacing(0.5),
    color: 'white'
  },
  phoneIcon: {
    backgroundColor: green[800],
    borderRadius: '50%',
    padding: theme.spacing(0.5),
    color: 'white'
  },
  rewardsText: {
    textOverflow: 'ellipsis',
    overFlow: 'hidden',
    whiteSpace: 'nowrap',
    '& .MuiTypography-caption': {
      fontWeight: 500
    }
  }
}));
