import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import { useStore } from 'store';
import { map } from 'lodash';
import { useTablesQuery } from 'graphql/hooks/tables.hooks';

interface IProps {
  restaurantId: string;
}

const SelectTables = ({ restaurantId }: IProps) => {
  const {
    state: { tableNumber },
    dispatch
  } = useStore();

  const { tables } = useTablesQuery({
    restaurantId
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'CART_ACTION_SET_TABLE_NUMBER',
      payload: (event.target as HTMLInputElement).value
    });
  };

  const error = !tableNumber;

  return (
    <TextField variant="outlined" fullWidth={true} size="small" select={true} value={tableNumber} onChange={handleChange} error={error}>
      {map(tables, ({ tableName }, index) => {
        return (
          <MenuItem value={tableName} key={index}>
            {tableName}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default SelectTables;
