import React from 'react';
import { useStyles } from '../style';
import { Box, Typography, Button } from '@material-ui/core';
import RewardsLayout from 'components/RewardsLayout';

interface IProps {
  title: string;
  currentPoints: number;
  pendingPoints: number;
  askRedeemPoints: () => void;
}

const RewardsHome = ({ title, currentPoints, pendingPoints, askRedeemPoints }: IProps) => {
  const classes = useStyles();

  const section1 = (
    <Box display="flex" flexDirection="column" color="white" height="95%" justifyContent="space-around">
      <Box textAlign="center">
        <Typography variant="h4" className={classes.uppercase}>
          {title}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-around">
        <Box display="flex" flexDirection="column" textAlign="center">
          <Typography variant="body2">Current Balance</Typography>
          <Typography variant="h3" className={classes.bold}>
            {currentPoints}
          </Typography>
          <Typography variant="body2">points</Typography>
        </Box>
        <Box display="flex" flexDirection="column" textAlign="center">
          <Typography variant="body2">Current Balance</Typography>
          <Typography variant="h3" className={classes.bold}>
            {pendingPoints}
          </Typography>
          <Typography variant="body2">points</Typography>
        </Box>
      </Box>
    </Box>
  );

  const section2 = (
    <Box display="flex" flexDirection="column" height="100%" justifyContent="space-evenly" alignItems="center">
      <Box width="70%">
        <Typography variant="body2" align="center">
          Take the photo of the receipt to claim points towards purchase today.
        </Typography>
      </Box>
      <img src="images/receipt.png" height="200px" alt="Default" />
      <Button color="primary" variant="contained" disableElevation={true} onClick={askRedeemPoints}>
        Take Photo
      </Button>
    </Box>
  );

  return <RewardsLayout section1={section1} section2={section2} />;
};

export default RewardsHome;
