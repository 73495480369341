import React, { useState, useCallback } from 'react';
import { Box, Typography } from '@material-ui/core';
import DishDialog from 'components/DishDialog';
import S3Image from 'components/S3Image';
import { useStyles } from './style';

interface IProps {
  id: string;
  title: string;
  price: string | number;
  menuPrice?: any;
  imageSrc: string | null | undefined;
}

const PopularDishCard: React.FC<IProps> = ({ title, price, menuPrice, imageSrc, id }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const _handleClose = useCallback(() => setOpen(false), []);

  const onClick = () => {
    setOpen(true);
  };

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box flexGrow="1" className={classes.root} onClick={onClick}>
        {imageSrc && <S3Image className={classes.image} src={imageSrc} />}
        {!!menuPrice && !!menuPrice.maxDiscountPercent && (
          <Box className={classes.discount}>
            <Typography variant="subtitle2">
              {menuPrice.maxDiscountPercent !== menuPrice.discountPercent && 'Up To'}&nbsp;{menuPrice.maxDiscountPercent}% OFF
            </Typography>
          </Box>
        )}
        <Box className={classes.description}>
          <Typography variant="subtitle2">${menuPrice.price}</Typography>
        </Box>
      </Box>
      <Typography variant="body1" noWrap={true}>
        {title}
      </Typography>
      <DishDialog id={id} isOpen={open} handleClose={_handleClose} />
    </Box>
  );
};

export default PopularDishCard;
