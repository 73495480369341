import React from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './style';
import { PizzaShapeType } from 'generated/custom';

interface IProps {
  type: PizzaShapeType;
  disabled?: boolean;
  onClick?: () => void;
  preset?: boolean | undefined | null;
}

const PizzaShape: React.FC<IProps> = ({ type, onClick = () => {}, disabled = false, preset = false }) => {
  const classes = useStyles({ type, disabled, preset });

  const props = () => {
    return {
      onClick
    };
  };

  return (
    <Box className={classes.root} {...props()}>
      <Box className={classes.firstHalf} />
      <Box className={classes.secondHalf} />
    </Box>
  );
};

export default PizzaShape;
