import React, { useContext, useState, useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import { useFormik, getIn } from 'formik';
import { AuthContext } from 'fbase/authContext';
import { useSnackbar } from 'notistack';
import validationSchema from './validations';
import { useHistory } from 'react-router-dom';
import FormDialog from 'components/FormDialog';
import InputField from 'components/Inputs/InputField';
import PersonIcon from '@material-ui/icons/Person';
import { useAlert } from 'hooks';

const ChangeDisplayName: React.FC = () => {
  const { currentUser } = useContext(AuthContext);

  const [initialValue, setInitialValue] = useState('');

  const snackbar = useSnackbar();

  const { lokoAlert } = useAlert();

  const history = useHistory();

  const initialDisplayName = currentUser?.displayName;

  useEffect(() => {
    if (initialDisplayName) {
      setInitialValue(initialDisplayName);
    }
  }, [initialDisplayName]);

  const formik = useFormik({
    initialValues: {
      displayName: initialValue
    },
    validationSchema,
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);

      try {
        await currentUser?.updateProfile({
          displayName: values.displayName
        });

        snackbar.enqueueSnackbar('Updated', { variant: 'success' });

        actions.setSubmitting(false);

        history.goBack();
      } catch (e) {
        lokoAlert('Something went wrong please try again');
        actions.setSubmitting(false);
      }
    },
    enableReinitialize: true
  });

  const isButtonDisabled = !(formik.isValid && formik.dirty);

  return (
    <Box display="flex" height="100%">
      <FormDialog title="Update display name" onBack={() => history.goBack()}>
        <form onSubmit={formik.handleSubmit}>
          <Box paddingBottom={2}>
            <InputField
              name="displayName"
              value={formik.values.displayName}
              onChange={formik.handleChange}
              fullWidth={true}
              onBlur={formik.handleBlur}
              autoComplete="off"
              autoCapitalize="off"
              error={!!getIn(formik.touched, 'displayName') && !!getIn(formik.errors, 'displayName')}
              helperText={!!getIn(formik.touched, 'displayName') && !!getIn(formik.errors, 'displayName') ? getIn(formik.errors, 'displayName') : ''}
              startIcon={<PersonIcon />}
              variant="outlined"
            />
          </Box>
          <Button type="submit" size="large" variant="contained" color="primary" fullWidth={true} disabled={isButtonDisabled} disableElevation={true}>
            Save
          </Button>
        </form>
      </FormDialog>
    </Box>
  );
};

export default ChangeDisplayName;
