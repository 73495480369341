import React, { useContext, useState } from 'react';
import { Box, Typography, Button, makeStyles } from '@material-ui/core';
import { AuthContext } from 'fbase/authContext';
import { useStore } from 'store';
import ContactDetailsFormForGuest from 'components/AccountModal/ContactDetailsFormForGuest';
import ContactDetailsFormForLokobeeUser from 'components/AccountModal/ContactDetailsFormForLokobeeUser';

const ContactDetails = () => {
  const classes = makeStyles((theme: any) => ({
    container: {
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1),
      margin: theme.spacing(1, 0),
      background: theme.palette.grey[100]
    },
    light: {
      fontWeight: 400
    }
  }))();

  const {
    state: {
      user,
      // orderType,
      couponData
    }
  } = useStore();

  const isSingleUse: boolean = couponData?.isSingleUse ?? false;

  const { currentUser } = useContext(AuthContext);

  const [openContactDialog, setOpenContactDialog] = useState(false);

  const [openGuestContactDialog, setOpenGuestContactDialog] = useState(false);

  return (
    <Box className={classes.container} display="flex" flexDirection="column">
      {/* {(orderType === 'TAKE_OUT' || orderType === 'DINING') && !isSingleUse && <Typography>Click edit if you want to change your contact details for current order</Typography>} */}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          {user.email && (
            <Box padding={0.5} fontWeight="400">
              <Typography className={classes.light} variant="body1">
                <b>Email : </b>&nbsp;{user.email}
              </Typography>
            </Box>
          )}
          {user.orderPhoneNumber && (
            <Box padding={0.5} fontWeight="400">
              <Typography className={classes.light} variant="body1">
                <b>Phone : </b>&nbsp;{user.orderPhoneNumber}
              </Typography>
            </Box>
          )}
        </Box>
        <Box>
          {!isSingleUse && (
            <Button
              color="secondary"
              variant="contained"
              size="small"
              disableElevation={true}
              onClick={() => {
                if (currentUser?.isAnonymous) {
                  setOpenGuestContactDialog(true);
                } else {
                  setOpenContactDialog(true);
                }
              }}>
              Edit
            </Button>
          )}
        </Box>

        {openGuestContactDialog && <ContactDetailsFormForGuest open={openGuestContactDialog} onHandleClose={() => setOpenGuestContactDialog(false)} />}
        {openContactDialog && <ContactDetailsFormForLokobeeUser open={openContactDialog} onHandleClose={() => setOpenContactDialog(false)} />}
      </Box>
    </Box>
  );
};

export default ContactDetails;
