import React, { useEffect, useState } from 'react';
import { OrderHistoryCard } from '@lokobee/lokobee-ui';
import firebase from 'firebase';
import { Box, Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { useGetBuyerOrdersQuery } from 'graphql/hooks';
import { map } from 'lodash';
import { Order } from 'models';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import OrderRatingDialog from '../OrderRatingDialog';
import { DeliveryProviders } from 'generated/types';

interface IProps {
  currentUser: firebase.User | null;
}
const RestaurantOrders: React.FC<IProps> = ({ currentUser }) => {
  const { data, loading, hasMore, fetchMoreRecords, fetching } = useGetBuyerOrdersQuery();

  const history = useHistory();

  const [orderId, setOrderId] = useState<string | null>(null);

  const [isRatingsDialogOpen, setRatingsDialogOpen] = useState(false);

  const [tempLoading, setTempLoading] = useState(true);

  const dishOrderOnClick = (orderId: string) => {
    setOrderId(orderId);
    history.push(`/orders/${orderId}`);
  };
  const onOpenRatingsDialog = (orderId: string) => {
    setOrderId(orderId);
    setRatingsDialogOpen(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setTempLoading(false);
    }, 2000);
  }, [setTempLoading]);

  if (currentUser && data) {
    if (!loading) {
      if (data.length) {
        return (
          <Box bgcolor="#fff" height="100%">
            <Box height="92%" overflow="scroll">
              <Box padding={1} flex={1}>
                <Grid container={true}>
                  {map(data, (order: Order) => {
                    const { id, createdAt, status, type, orderNumber, expectTime, deliveryProvider } = order;
                    const { i18n } = order.seller;
                    const timezone = i18n ? i18n.timezone || 'us/pacific' : 'us/pacific';
                    const items = order.getItems;
                    const restaurantName = order.getRestaurantName;
                    const restaurantLogo = order.getRestaurantLogo;
                    const total = order.getTotal;
                    const pointsRedeemed = order?.redeemPoints ? order?.redeemPoints : 0;
                    const now = moment.tz(timezone);
                    const expectTimeMoment = moment.tz(expectTime, timezone);
                    const createdAtMoment = moment.tz(createdAt, timezone);
                    let expectTimeString = '';
                    let timeDiff = 0;
                    if (type === 'TAKEOUT' || type === 'DELIVERY') {
                      expectTimeString = `${expectTimeMoment.format('DD MMM').toUpperCase()}, ${expectTimeMoment.format('hh:mm A')}`;
                      timeDiff = moment.duration(now.diff(expectTimeMoment)).asDays();
                    } else {
                      timeDiff = moment.duration(now.diff(createdAtMoment)).asDays();
                    }

                    return (
                      <Grid key={id} item={true} xs={12} sm={6} lg={4}>
                        <Box padding={1}>
                          <OrderHistoryCard
                            createdAt={createdAtMoment.format('DD MMM YYYY, hh:mm A')}
                            items={map(items, (item) => {
                              const { dishTitle, count } = item;
                              return {
                                title: dishTitle,
                                count
                              };
                            })}
                            status={status}
                            logoUrl={restaurantLogo}
                            restaurantName={restaurantName}
                            type={type}
                            orderNumber={orderNumber}
                            total={total}
                            pointsRedeemed={pointsRedeemed}
                            expectTime={expectTimeString}
                            onOpenDishDialog={() => dishOrderOnClick(id)}
                            onOpenRatingsDialog={() => onOpenRatingsDialog(id)}
                            onOpenTracking={() => {
                              history.push(`/orders/${id}/tracking`);
                            }}
                            showRatingsBtn={order.isRated ? !order.isRated && timeDiff < 8 : true && timeDiff < 8}
                            showTrackingBtn={type === 'DELIVERY' && (deliveryProvider === DeliveryProviders.Boons || deliveryProvider === DeliveryProviders.DoordashClassic)}
                          />
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
                {hasMore && (
                  <Box paddingY={1} display="flex" justifyContent="center">
                    <Button onClick={fetchMoreRecords} variant="contained" color="secondary" size="large" disableElevation={true} disabled={fetching}>
                      {fetching ? 'Loading' : 'Load more'}
                    </Button>
                    {fetching && (
                      <Box paddingX={1}>
                        <CircularProgress color="secondary" />
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
            {orderId && (
              <OrderRatingDialog
                isOpen={isRatingsDialogOpen}
                orderId={orderId}
                buyerId={currentUser?.uid}
                handleClose={() => {
                  setRatingsDialogOpen(false);
                  setOrderId(null);
                }}
              />
            )}
          </Box>
        );
      }

      return (
        <Box display="flex" flexDirection="column" height="100%" bgcolor="#fff">
          <Box padding={1}>
            <Typography variant="body1">No orders found.</Typography>
          </Box>
        </Box>
      );
    }
  }

  return (
    <>
      <Box padding={1}>{!tempLoading && !currentUser && <Typography variant="body1">Please login to view orders</Typography>}</Box>
    </>
  );
};

export default RestaurantOrders;
