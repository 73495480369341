import React from 'react';
import { useMediaQuery, Typography, Box, Dialog, DialogContent, Switch, Divider } from '@material-ui/core';
import Transition from 'components/Transition';
import { useSnackbar } from 'notistack';
import { useStyles } from './style';
import { useAlert, useProfileRoles } from 'hooks';
import { useStore } from 'store';
import { useSetUserDealOptMutation } from 'graphql/hooks';
import DialogHeader from 'components/DialogHeader';

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
}

const Notifications: React.FC<IProps> = ({ isOpen, handleClose }) => {
  const classes = useStyles();

  const fullScreen = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));

  const snackbar = useSnackbar();

  const { isPhoneLogin, isGoogleLogin, isFacebookLogin } = useProfileRoles();

  const { lokoAlert } = useAlert();

  const {
    state: {
      user: { smsForDeals, emailForDeals }
    }
  } = useStore();

  const { setUserDealOpt } = useSetUserDealOptMutation();

  const [smsOpt, setSmsOpt] = React.useState(smsForDeals);

  const [emailOpt, setEmailOpt] = React.useState(emailForDeals);

  React.useEffect(() => {
    setSmsOpt(smsForDeals);
    setEmailOpt(emailForDeals);
  }, [smsForDeals, emailForDeals]);

  const isLoggedIn = React.useCallback(() => {
    if (isPhoneLogin || isGoogleLogin || isFacebookLogin) {
      return true;
    }

    return false;
  }, [isPhoneLogin, isGoogleLogin, isFacebookLogin]);

  /**
   * Switch on change handler, triggers a graphql mutation on each call
   * @param event
   * @param checked
   */
  const handleChange = async (event: any, checked: boolean) => {
    const type = event.target.name;

    if (type === 'sms') {
      setSmsOpt(checked);
    }

    if (type === 'email') {
      setEmailOpt(checked);
    }

    const response = await setUserDealOpt({
      variables: {
        input: {
          smsForDeals: type === 'sms' ? checked : smsOpt,
          emailForDeals: type === 'email' ? checked : emailOpt
        }
      }
    });

    if (!response) {
      lokoAlert('Something went wrong please try again');

      if (type === 'sms') {
        setSmsOpt(!checked);
      }

      if (type === 'email') {
        setEmailOpt(!checked);
      }
    } else {
      snackbar.enqueueSnackbar('Saved', { variant: 'success' });
    }
  };

  return (
    <Dialog className={classes.dialog} open={isOpen} fullScreen={fullScreen} fullWidth={true} maxWidth="xs" TransitionComponent={Transition} onClose={handleClose}>
      <DialogHeader onBack={handleClose} text="Notifications" />
      <DialogContent className={classes.dialogContent}>
        {isLoggedIn() && (
          <>
            <Box paddingX={1} paddingY={1}>
              <Typography className={classes.lightBold} variant="subtitle1">
                Control how you get your promotion notifications
              </Typography>
            </Box>
            <Box paddingX={1}>
              <Divider />
            </Box>

            <Box paddingX={1}>
              <Box display="flex" alignItems="center" paddingY={1}>
                <Box flexGrow={1} display="flex" alignItems="center">
                  <Typography variant="body1">Notifications by SMS</Typography>
                </Box>
                <Switch
                  classes={{
                    switchBase: classes.switchBase,
                    track: classes.track,
                    checked: classes.checked
                  }}
                  checked={smsOpt}
                  onChange={handleChange}
                  name="sms"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </Box>
              <Divider />
              <Box display="flex" alignItems="center" paddingY={1}>
                <Box flexGrow={1} display="flex" alignItems="center">
                  <Typography variant="body1"> Notifications by email </Typography>
                </Box>
                <Switch
                  classes={{
                    switchBase: classes.switchBase,
                    track: classes.track,
                    checked: classes.checked
                  }}
                  checked={emailOpt}
                  onChange={handleChange}
                  name="email"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </Box>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default Notifications;
