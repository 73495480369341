import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useStore } from 'store';
import { useStyles } from '../style';
import { useGetLokobeeUser } from 'graphql/hooks/user.hooks';
import AlcoholAgreement from 'components/AlcoholAgreement';
import { useContext } from 'react';
import { AuthContext } from 'fbase/authContext';
import ContactDetailsFormForGuest from 'components/AccountModal/ContactDetailsFormForGuest';
import ContactDetailsFormForLokobeeUser from 'components/AccountModal/ContactDetailsFormForLokobeeUser';
interface IProps {
  total: string | number;
  showAlcoholAgreement: boolean;
}

const PlaceOrder: React.FC<IProps> = ({ total, showAlcoholAgreement }) => {
  const classes = useStyles();

  const { dispatch, state } = useStore();

  const { currentUser } = useContext(AuthContext);

  const history = useHistory();

  const [openContactDetailsForm, setOpenContactDetailsForm] = useState(false);

  const [openGuestContactDetailsForm, setOpenGuestContactDetailsForm] = useState(false);

  const [openAlcoholAgreement, setOpenAlcoholAgreement] = useState(false);

  const { data } = useGetLokobeeUser();
  const orderPhoneNumberFromUrl = state && state.user.orderPhoneNumberFromUrl ? state.user.orderPhoneNumberFromUrl : '';

  const { orderPhoneNumber, email } = data && data.getLokobeeUser ? data.getLokobeeUser : { orderPhoneNumber: null, email: null };

  const {
    state: { user }
  } = useStore();

  const setUserInStore = useCallback(
    (phoneNumber: string, email?: string, nickName?: string) => {
      const input: { phoneNumber: string; email?: string; nickName?: string } = {
        phoneNumber: phoneNumber,
        nickName: nickName
      };

      if (email) {
        input.email = email;
      }

      dispatch({
        type: 'USER_ACTION_SET_CONTACT_DETAILS',
        payload: input
      });
    },
    [dispatch]
  );
  useEffect(() => {
    setUserInStore(orderPhoneNumber, email, user.nickName || '');

    dispatch({
      type: 'CART_ACTION_IS_PHONE_ORDER',
      payload: orderPhoneNumberFromUrl !== '' ? true : false
    });
  }, [currentUser, dispatch, email, orderPhoneNumber, orderPhoneNumberFromUrl, setUserInStore, user.nickName]);

  const onPlaceOrderClick = () => {
    if (currentUser) {
      if (showAlcoholAgreement && !currentUser.isAnonymous) {
        setOpenAlcoholAgreement(true);
      } else {
        placeOrder();
      }
    } else {
      console.log('opened login popup from place order');
      openLoginPopup();
    }
  };

  const placeOrder = () => {
    if (currentUser) {
      if (currentUser.isAnonymous) {
        setOpenGuestContactDetailsForm(true);
      } else {
        if (user.email && user.orderPhoneNumber) {
          goToNextPage();
        } else {
          setOpenContactDetailsForm(true);
        }
      }
    } else {
      openLoginPopup();
    }
  };

  const openLoginPopup = () => {
    dispatch({
      type: 'UI_ACTION_LOGIN_POPUP',
      payload: {
        openLoginPopup: true,
        onLoginRoute: '/cart/select-type',
        showContactFormForGuest: true,
        onGuestLoginRoute: '/cart/select-type',
        showContactFormForLokobeeUser: true
      }
    });
  };

  const handleContactsDialogClose = (submitted: boolean) => {
    if (submitted) {
      goToNextPage();
    } else {
      setOpenContactDetailsForm(false);
      setOpenGuestContactDetailsForm(false);
    }
  };

  const goToNextPage = () => {
    history.push('/cart/select-type');
  };

  return (
    <>
      <Box margin={2} display="flex" boxShadow={1}>
        <Box flex="1 1 0" display={'flex'} alignItems={'center'} paddingY={1} paddingX={2}>
          <Typography variant="body1" component="div">
            <Box fontWeight="500">Total: ${total}</Box>
          </Typography>
        </Box>
        <Button className={classes.paymentButton} variant="contained" disableElevation={true} color="primary" fullWidth={true} onClick={onPlaceOrderClick}>
          Continue to Checkout
        </Button>
      </Box>
      {openAlcoholAgreement && <AlcoholAgreement acceptDisclaimer={placeOrder} onClose={() => setOpenAlcoholAgreement(false)} />}
      {openGuestContactDetailsForm && <ContactDetailsFormForGuest open={openGuestContactDetailsForm} onHandleClose={handleContactsDialogClose} />}
      {openContactDetailsForm && <ContactDetailsFormForLokobeeUser open={openContactDetailsForm} onHandleClose={handleContactsDialogClose} />}
    </>
  );
};

export default PlaceOrder;
