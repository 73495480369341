import React, { useState } from 'react';
import { Box, Button, Typography, Grid } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import GoogleMap from 'components/GoogleMap';
import { Dialog } from '@lokobee/lokobee-ui';
import { formatPhoneNumber } from 'util/number';
import { useStyles } from './style';
import { Restaurant } from 'models';
import BizHours from '../BizHours';
import DialogHeader from 'components/DialogHeader';
import { IBizHour } from '../types';
import Divider from '@material-ui/core/Divider';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { grey } from '@material-ui/core/colors';
import geoDistance from 'util/geoDistance';
import { useStore } from 'store';
import InfoAndHourSwipe from 'pages/Restaurant/RestaurantHome/RestaurantInfo/InfoAndHours/InfoAndHourSwipe';

interface IProps {
  restaurant: Restaurant;
}
const InfoAndHours = ({ restaurant }: IProps) => {
  const classes = useStyles();

  const {
    state: { user }
  } = useStore();

  const [open, setOpen] = useState(false);

  const name = restaurant.getName;

  const description = restaurant.getDescription;

  const galleryImages = restaurant.getGalleryImage;

  const mile = restaurant.getLatLng || null;

  const lat = mile?.lat;

  const lng = mile?.lng;

  const delivery = restaurant.enableDeliveryOrder;

  const pickup = restaurant.enableTakeoutOrder;

  let miles;

  const {
    coordinate: { latitude, longitude }
  } = user;

  if (lat && lng) {
    miles = geoDistance(latitude, longitude, lat, lng, 'M');
  }

  const { text: address } = restaurant.getAddress || { text: '' };

  const phone = restaurant.getPhone;
  const virtualPhone = restaurant.virtualPhone;

  let formattedPhone = null;
  let formattedVirtualPhone = null;

  if (phone) {
    formattedPhone = formatPhoneNumber(phone);
  }
  if (virtualPhone) {
    formattedVirtualPhone = formatPhoneNumber(virtualPhone);
  }

  const location = restaurant.getLatLng;

  const businessHours: IBizHour[] | undefined | null = restaurant.getBizHours;

  return (
    <>
      <Button className={classes.infoHoursBtn} variant="contained" color="primary" size="small" onClick={() => setOpen(true)} disableElevation={true}>
        Info
      </Button>
      <Dialog open={open} setOpen={setOpen} dialogProps={{ maxWidth: 'md', fullWidth: true }}>
        <Dialog.DialogContent className={classes.dialogContent}>
          <DialogHeader text="Info & Hours" onBack={() => setOpen(false)} />
          <Grid container={true}>
            <Grid item={true} xs={12} md={6}>
              <Box bgcolor={grey[200]}>
                {galleryImages && (
                  <Box>
                    <InfoAndHourSwipe images={galleryImages} />
                  </Box>
                )}
              </Box>
              <Box padding={2}>
                {name && (
                  <Typography variant="h6" gutterBottom={true}>
                    {name}
                  </Typography>
                )}
                {description && (
                  <Typography variant="caption" color="textSecondary" align="justify" gutterBottom={true}>
                    {description}
                  </Typography>
                )}
                <Box display="flex" mt={0.5} justifyContent="space-between" width="100%">
                  <Box display="flex">
                    <Typography variant="caption" color="textSecondary" align="justify">
                      {miles && latitude !== 0 && longitude !== 0 ? ` (${miles.toFixed(2)} miles)` : ''}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="flex-end">
                    {pickup && (
                      <Typography variant="caption" color="textSecondary" align="justify">
                        Pickup
                      </Typography>
                    )}
                    {delivery && <FiberManualRecordIcon className={classes.restaurantDetail} />}
                    {delivery && (
                      <Typography variant="caption" color="textSecondary" align="justify">
                        Delivery
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box paddingY={1} mb={0.5}>
                  <Divider />
                </Box>

                {address && (
                  <Box display="flex" alignItems="center" justifyItems="center">
                    <Box marginRight={1}>
                      <a className={classes.link} target="_blank" rel="noopener noreferrer" href={'https://maps.google.com?q=' + location?.lat + ',' + location?.lng + ''}>
                        <LocationOnIcon fontSize="small" className={classes.locationIcon} />
                      </a>
                    </Box>

                    <Typography variant="subtitle1" component="div">
                      <Box alignItems="center" justifyItems="center" fontSize={12}>
                        {location ? (
                          <a className={classes.link} target="_blank" rel="noopener noreferrer" href={'https://maps.google.com?q=' + location?.lat + ',' + location?.lng + ''}>
                            {address}
                          </a>
                        ) : (
                          { address }
                        )}
                      </Box>
                    </Typography>
                  </Box>
                )}
                {virtualPhone ? (
                  <Box display="flex" alignItems="center" justifyItems="center">
                    <Box component="span" marginRight={1}>
                      <a href={`tel:${virtualPhone}`} className={classes.link}>
                        <PhoneIcon fontSize="small" className={classes.phoneIcon} />
                      </a>
                    </Box>
                    <Typography variant="subtitle1" component="div">
                      <Box alignItems="center" justifyItems="center" fontSize={12}>
                        <a href={`tel:${virtualPhone}`} className={classes.link}>
                          {formattedVirtualPhone ? formattedVirtualPhone : virtualPhone}
                        </a>
                      </Box>
                    </Typography>
                  </Box>
                ) : (
                  phone && (
                    <Box display="flex" alignItems="center" justifyItems="center">
                      <Box component="span" marginRight={1}>
                        <a href={`tel:${phone}`} className={classes.link}>
                          <PhoneIcon fontSize="small" className={classes.phoneIcon} />
                        </a>
                      </Box>
                      <Typography variant="subtitle1" component="div">
                        <Box alignItems="center" justifyItems="center" fontSize={12}>
                          <a href={`tel:${phone}`} className={classes.link}>
                            {formattedPhone ? formattedPhone : phone}
                          </a>
                        </Box>
                      </Typography>
                    </Box>
                  )
                )}
              </Box>
              {businessHours && <BizHours businessHours={businessHours} />}
            </Grid>
            <Grid item={true} xs={12} md={6}>
              <Box className={classes.mapContainer}>
                <GoogleMap location={location} />
              </Box>
            </Grid>
          </Grid>
        </Dialog.DialogContent>
      </Dialog>
    </>
  );
};

export default InfoAndHours;
