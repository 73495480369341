import { gql } from 'apollo-boost';
import { storeItemFragment } from 'graphql/fragments/groceryItem.fragments';

export const addToGroceryStoreCart = gql`
  mutation AddToGroceryStoreCart($input: AddToGroceryStoreCartInput!) {
    AddToGroceryStoreCart(input: $input) {
      items {
        item {
          ...storeItemFragment
        }
        quantity
      }
      rewardItems {
        item {
          ...storeItemFragment
        }
        quantity
      }
      redeemedPoints
    }
  }
  ${storeItemFragment}
`;
