import gql from 'graphql-tag';

export const setUserDealOptOutMutation = gql`
  mutation setUserDealOptOut($input: SetUserDealOptOutInput!) {
    setUserDealOptOut(input: $input)
  }
`;

export const feedbackMutation = gql`
  mutation addFeedback($input: AddUserFeedBackInput!) {
    addUserFeedBack(input: $input)
  }
`;

export const updateUserMutation = gql`
  mutation updateUserOrderPhoneAndOrderEmail($input: UpdateUserOrderPhoneAndOrderEmailInput!) {
    updateUserOrderPhoneAndOrderEmail(input: $input) {
      uid
      email
      orderEmail
      orderPhoneNumber
    }
  }
`;
