import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(1)
  },
  formControlLabel: {
    width: '100%'
  }
}));
