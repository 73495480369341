import React from 'react';
import { Box } from '@material-ui/core';
import S3Image from 'components/S3Image';
import { useStyles } from './style';

interface IProps {
  imageSrc: string | null | undefined;
}

const InfoAndHourSwipeCard: React.FC<IProps> = ({ imageSrc }) => {
  const classes = useStyles();
  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box height={200} flexGrow="1" boxShadow={5} className={classes.root} overflow="hidden">
        {imageSrc && <S3Image className={classes.image} src={imageSrc} />}
      </Box>
    </Box>
  );
};

export default InfoAndHourSwipeCard;
