import React, { useState } from 'react';
import { useStyles } from '../style';
import { Box, Typography, Button, TextField } from '@material-ui/core';
import RewardsLayout from 'components/RewardsLayout';

interface IProps {
  title: string;
  subTotal: number;
  maxRedeemablePoints: number;
  requestRedeem: (redeemPoints: number) => void;
  skipRedeem: () => void;
}

const RedeemPoints = ({ title, subTotal, maxRedeemablePoints, requestRedeem, skipRedeem }: IProps) => {
  const classes = useStyles();
  const [showRedeemField, setShowRedeemField] = useState(false);
  const [redeemPoints, setRedeemPoints] = useState<number | ''>(maxRedeemablePoints);

  const redeem = () => {
    if (redeemPoints !== '' && redeemPoints <= maxRedeemablePoints) {
      requestRedeem(redeemPoints);
    }
  };

  const section1 = (
    <Box display="flex" flexDirection="column" color="white" height="95%" justifyContent="space-around">
      <Box textAlign="center">
        <Typography variant="h4" className={classes.uppercase}>
          {title}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-around">
        <Box display="flex" flexDirection="column" textAlign="center">
          <Typography variant="body2">Subtotal from receipt</Typography>
          <Typography variant="h1" className={classes.bold}>
            ${subTotal}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
  const section2 = (
    <Box display="flex" flexDirection="column" height="100%" justifyContent="space-evenly" alignItems="center">
      <Typography variant="h6" align="center" className={classes.bold}>
        Good News !
      </Typography>
      <Typography variant="body2" align="center">
        you are eligible for redeeming up to {maxRedeemablePoints} Points.
      </Typography>
      <Box width="70%">
        <Typography variant="body2" align="center">
          Redeeming Points will reduce the receipt Subtotal for your purchase today.
        </Typography>
      </Box>
      <Typography variant="body2" align="center">
        Do you want to Redeem your points?
      </Typography>

      {showRedeemField && (
        <>
          <TextField
            inputProps={{
              max: 40
            }}
            type="number"
            variant="outlined"
            label="Enter points"
            value={redeemPoints}
            error={redeemPoints > maxRedeemablePoints}
            helperText={redeemPoints > maxRedeemablePoints ? 'Max Redeemable Points : ' + maxRedeemablePoints : ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => (e.target.value !== '' ? setRedeemPoints(parseInt(e.target.value)) : setRedeemPoints(''))}
          />

          <Box className={classes.btnsContainer}>
            <Button color="default" variant="outlined" disableElevation={true} onClick={skipRedeem}>
              Skip It
            </Button>
            <Button color="primary" variant="contained" disableElevation={true} onClick={redeem}>
              Redeem
            </Button>
          </Box>
        </>
      )}

      {!showRedeemField && (
        <Box className={classes.btnsContainer}>
          <Button color="default" variant="outlined" disableElevation={true} onClick={skipRedeem}>
            No
          </Button>
          <Button color="primary" variant="contained" disableElevation={true} onClick={() => setShowRedeemField(true)}>
            Yes
          </Button>
        </Box>
      )}
    </Box>
  );

  return <RewardsLayout section1={section1} section2={section2} />;
};

export default RedeemPoints;
